import lista from "../data/cidades.json";

export const cidadeNomePorID = (id) => {
  let cidade;
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].id === id) {
      cidade = lista[i].city;
      break;
    }
  }
  return cidade;
};

export const cidadeIDPorNome = (nome) => {
  let cidade;
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].city === nome) {
      cidade = lista[i].id;
      break;
    }
  }
  return cidade;
};
