import React, { useState } from "react";
import { ReactComponent as Minus } from "../img/icons/minus.svg";
import { ReactComponent as Plus } from "../img/icons/plus.svg";
import { selectStyle } from "../utils/selectStyle";
import Select from "react-select";

const InputSelect = (props) => {
  const [selectValue, setSelectValue] = useState();

  return (
    <>
      <div
        className={`input-float select-float
      ${props.error ? "validate-error" : ""}
      ${props.iconLeft || props.iconRight ? "input-icon" : ""}
      ${props.iconLeft ? "left" : ""}
      ${props.iconRight ? "right" : ""}
      ${props.value && !props.preValue != "" ? "active" : ""}
      ${props.isDisabled ? "disabled" : ""}
      ${props.preValue ? "active" : ""}
      ${
        selectValue?.value && !props.isDisabled && !props.stateValueNull
          ? "active"
          : ""
      }`}
      >
        <Select
          className="mt-2"
          styles={selectStyle()}
          placeholder={
            props.required && !props.maskRequired
              ? props.placeholder + "*"
              : props.placeholder
          }
          components={{
            IndicatorSeparator: () => null,
            ClearIndicator: () => null,
          }}
          isDisabled={props.isDisabled}
          noOptionsMessage={() => null}
          options={props.options}
          value={
            props.isDisabled
              ? null
              : props.stateValueNull
              ? null
              : selectValue
              ? selectValue
              : props.value
              ? props.value
              : selectValue
          }
          onChange={(e) => {
            setSelectValue(e);
            props.onChange(e);
          }}
        />

        <label htmlFor={props.id}>
          {props.preValue ? props.preValue : props.placeholder}
          {props.required && !props.maskRequired ? (
            <span className="required">*</span>
          ) : (
            ""
          )}
        </label>
        {props.iconLeft && (
          <div
            onClick={props.iconLeft.onClick}
            className={`i left ${props.iconLeft.class} ${
              props.iconLeft.onClick && "c-pointer"
            }`}
          >
            {props.iconLeft.svg}
          </div>
        )}
        {props.iconRight && (
          <div
            onClick={props.iconRight.onClick}
            className={`i right ${props.iconRight.class} ${
              props.iconRight.onClick && "c-pointer"
            }`}
          >
            {props.iconRight.svg}
          </div>
        )}
      </div>
      {props.error !== "" && (
        <span className="error__message">{props.error}</span>
      )}
    </>
  );
};

export default InputSelect;
