import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/pt-br";
import api from "../../../api";
import { UserContext } from "../../../context/UserContext";
import Layout from "../../../components/estrutura/principal/Layout";
import VagaFull from "../../../components/VagaFull";
import { cidadeNomePorID } from "../../../utils/encontrarCidade";
import { estadoUFPorID } from "../../../utils/encontrarEstado";
import { ReactComponent as Share } from "../../../img/icons/share-alt.svg";
import { ReactComponent as Check } from "../../../img/icons/check-circle.svg";
import { ReactComponent as Proximo } from "../../../img/icons/angle-right.svg";
import { ReactComponent as Anterior } from "../../../img/icons/angle-left.svg";
import { ReactComponent as Lista } from "../../../img/icons/arrow-left.svg";
import qs from "query-string";

const IndexPage = ({ vagaID }) => {
  const { user, updateContext } = useContext(UserContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const query = qs.parse(search);
  const [pageData, setPageData] = useState({});
  const [currIndex, setCurrIndex] = useState(null);

  const linkState = {
    vagaID: id,
    paginaAnterior: state?.paginaAnterior,
    listaIDS: state?.listaIDS,
    pagina: state?.pagina,
  };

  useEffect(() => {
    api
      .get(`/vagas/pesquisar/`, {
        params: {
          anunciosID: id,
        },
      })
      .then(({ data }) => {
        setPageData(data.lstAnuncios[0]);
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
      });
  }, [pathname]);

  return (
    <Layout
      menuBusca
      seoData={{
        title: `${
          pageData?.tituloVaga
        } · Oportunidade de vaga em ${cidadeNomePorID(
          pageData?.cidadeID
        )}, ${estadoUFPorID(pageData?.estadoID)}`,
        description: `${`Vaga para ${pageData?.tituloVaga} em ${cidadeNomePorID(
          pageData?.cidadeID
        )}. ${pageData?.descricao?.replace(/(<([^>]+)>)/gi, "")}`.substring(
          0,
          186
        )}...`,
      }}
    >
      <VagaFull
        regimeContrato={pageData?.regimeContrato}
        contratante={pageData?.contratante}
        state={linkState}
        tituloVaga={pageData?.tituloVaga}
        bairro={pageData?.bairro}
        endereco={pageData?.endereco}
        numero={pageData?.numero}
        cidadeID={pageData?.cidadeID}
        estadoID={pageData?.estadoID}
        tempoExperiencia={pageData?.tempoExperiencia}
        escolaridade={pageData?.escolaridade}
        descricao={pageData?.descricao}
        status={pageData?.status}
        tempo={pageData?.tempo}
        diferenciais={pageData?.diferenciais}
        salarioAcombinar={pageData?.salarioAcombinar}
        faixaSalarialAte={pageData?.faixaSalarialAte}
        faixaSalarialDe={pageData?.faixaSalarialDe}
        valeAlimentacao={pageData?.valeAlimentacao}
        valeRefeicao={pageData?.valeRefeicao}
        valeTransporte={pageData?.valeTransporte}
        proprio={pageData?.proprio}
        cep={pageData?.cep}
        dataAtualizacao={moment(pageData?.dataAtualizacao).fromNow()}
        id={id}
      />
    </Layout>
  );
};

export default IndexPage;
