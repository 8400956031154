import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import Layout from "../../../components/estrutura/principal/Layout";
import TextInput from "../../../components/InputText";
import { UserContext } from "../../../context/UserContext";
import api from "../../../api";
import Loading from "../../../components/Loading";
import { ReactComponent as Padlock } from "../../../img/icons/padlock.svg";
import { ReactComponent as Envelope } from "../../../img/icons/envelope.svg";
import { ReactComponent as Eye } from "../../../img/icons/eye.svg";
import { ReactComponent as EyeSlash } from "../../../img/icons/eye-slash.svg";
import { ReactComponent as User } from "../../../img/icons/user-circle.svg";

import { ReactComponent as Logo } from "../../../img/logo.svg";

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const navigate = useNavigate();
  const [userPassword, setUserPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [forgotPass, setForgotPass] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showingPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user.userInfo) {
      if (user?.userInfo?.tipoUsuarioID === 0) {
        navigate("/candidato/candidaturas");
      } else {
        navigate("/anunciante/vagas");
      }
    }
  }, [user?.userInfo]);

  const enviarForm = (e) => {
    e.preventDefault();
    var data = new FormData();
    data.append("email", userEmail);
    data.append("senha", userPassword);
    api({
      method: "post",
      url: "/Usuario/logar",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(async (res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.tokenID);
          /*    api.defaults.headers["Authorization"] = `${res.data.tokenID}` */
          let result;
          let arr;
          if (res.data.userInfo.tipoUsuarioID === 0) {
            result = await api.get(`/Candidaturas?token=${res.data.tokenID}`);
            arr = { candidaturas: result.data.lstCandidaturas };
          } else {
            result = await api.get(
              `/Anuncios/pesquisarTudo?token=${res.data.tokenID}`
            );
            arr = {
              anuncios: result.data.lstAnuncios,
              candidatos: [],
            };
          }
          updateContext({ ...res.data, ...arr });
        }
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: "Encontramos um erro",
              message: err.response.data.mensagem.value.message,
              status: "Error",
            },
          });
        }
      })
      .finally((end) => {
        setLoading(false);
      });
  };

  if (user?.valido) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Layout noHeader noFooter>
        <div className="sign">
          <div className="container">
            <div className="sign__logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="sign__form">
              <form className="row" onSubmit={loading ? undefined : enviarForm}>
                <User />
                <h4>Acessar plataforma</h4>
                <p>Entre para acompanhar as suas vagas</p>
                <div className="col-12">
                  <TextInput
                    id="email"
                    type={"email"}
                    placeholder="E-mail"
                    onChange={(event) => setUserEmail(event.target.value)}
                    value={userEmail}
                    iconLeft={{ svg: <Envelope /> }}
                    className="w-100 mt-2"
                    required
                  />
                </div>
                <div className="col-12">
                  <TextInput
                    id="password"
                    type={showingPassword ? "text" : "password"}
                    placeholder="Senha"
                    onChange={(event) => setUserPassword(event.target.value)}
                    value={userPassword}
                    iconLeft={{ svg: <Padlock /> }}
                    iconRight={{
                      svg: showingPassword ? <EyeSlash /> : <Eye />,
                      onClick: () => setShowPassword(!showingPassword),
                    }}
                    className="w-100 mt-2"
                    required
                  />
                </div>
                {/*     <div className="col-12 mb-3 p6">
                  <small onClick={() => setForgotPass(!forgotPass)}>
                    Esqueci minha senha
                  </small>
                </div> */}
                <div className="col-12 mt-3">
                  <button className="btn-vagas btn-vagas__primary w-100">
                    {loading ? (
                      <Loading
                        className={"white"}
                        height={"25px"}
                        width={"25px"}
                      />
                    ) : (
                      "Entrar"
                    )}
                  </button>
                </div>
              </form>
              <p>
                Ainda não possui uma conta?{" "}
                <Link to="/cadastrar">Clique aqui</Link>
              </p>
            </div>
            <div className="sign__footer">
              © {new Date().getFullYear()} VMED Oportunidades
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
