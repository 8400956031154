import lista from "../data/brazilCitiesDataPopResult.json";

function DamerauLevenshtein(prices, damerau) {
  if (damerau !== false) {
    damerau = true;
  }
  if (!prices) {
    prices = {};
  }
  let insert, remove, substitute, transpose;

  switch (typeof prices.insert) {
    case "function":
      insert = prices.insert;
      break;
    case "number":
      insert = function (c) {
        return prices.insert;
      };
      break;
    default:
      insert = function (c) {
        return 1;
      };
      break;
  }

  switch (typeof prices.remove) {
    case "function":
      remove = prices.remove;
      break;
    case "number":
      remove = function (c) {
        return prices.remove;
      };
      break;
    default:
      remove = function (c) {
        return 1;
      };
      break;
  }

  switch (typeof prices.substitute) {
    case "function":
      substitute = prices.substitute;
      break;
    case "number":
      substitute = function (from, to) {
        return prices.substitute;
      };
      break;
    default:
      substitute = function (from, to) {
        return 1;
      };
      break;
  }

  switch (typeof prices.transpose) {
    case "function":
      transpose = prices.transpose;
      break;
    case "number":
      transpose = function (backward, forward) {
        return prices.transpose;
      };
      break;
    default:
      transpose = function (backward, forward) {
        return 1;
      };
      break;
  }

  function distance(down, across) {
    let ds = [];
    if (down === across) {
      return 0;
    } else {
      down = down.split("");
      down.unshift(null);
      across = across.split("");
      across.unshift(null);
      down.forEach(function (d, i) {
        if (!ds[i]) {
          ds[i] = [];
        }
        across.forEach(function (a, j) {
          if (i === 0 && j === 0) {
            ds[i][j] = 0;
          } else if (i === 0) {
            ds[i][j] = ds[i][j - 1] + insert(a);
          } else if (j === 0) {
            ds[i][j] = ds[i - 1][j] + remove(d);
          } else {
            ds[i][j] = Math.min(
              ds[i - 1][j] + remove(d),
              ds[i][j - 1] + insert(a),
              ds[i - 1][j - 1] + (d === a ? 0 : substitute(d, a))
            );
            if (
              damerau &&
              i > 1 &&
              j > 1 &&
              down[i - 1] === a &&
              d === across[j - 1]
            ) {
              ds[i][j] = Math.min(
                ds[i][j],
                ds[i - 2][j - 2] + (d === a ? 0 : transpose(d, down[i - 1]))
              );
            }
          }
        });
      });
      return ds[down.length - 1][across.length - 1];
    }
  }
  return distance;
}
//Returns a distance function to call.
let dl = DamerauLevenshtein();

export const encontrarCidadesVizinhas = (latAtual, longAtual, km) => {
  const raioGraus = (0.9 * km) / 100;
  let cidades = lista.filter(
    ({ latitude, longitude }) =>
      latAtual + raioGraus > latitude &&
      latAtual - raioGraus < latitude &&
      longAtual + raioGraus > longitude &&
      longAtual - raioGraus < longitude
  );
  return cidades.map(({ name, state_code }) => `${name}, ${state_code}`);
};

export const encontrarCidadesVizinhasPorNome = (cidade) => {
  var arr = [];
  let cidades = lista.map(({ name }) => `${name}`);
  var cidadeBusca = cidade.split(",")[0];
  for (var i = 0; i < cidades.length; i++) {
    if (cidadeBusca.toLowerCase() === cidades[i].toLowerCase()) {
      arr.push({ cidade: cidades[i], diff: 0 });
    } else {
      var points = dl(cidadeBusca, cidades[i]);
      arr.push({ cidade: cidades[i], diff: points });
    }
  }
  var result = arr.sort((a, b) => a.diff - b.diff);
  return result[0];
};
