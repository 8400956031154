import React, { useState, useEffect, useContext } from "react";
import { Navigate, Route, useLocation, Outlet } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import api from "../../api";

const PrecisaAutenticacao = (props) => {
  const { user } = useContext(UserContext);
  let location = useLocation();

  if (user?.valido) {
    return <Outlet />;
  }
  if (!user?.valido) {
    return <Navigate state={{ location }} to="/cadastrar" replace={true} />;
  }
};

export default PrecisaAutenticacao;
