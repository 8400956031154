import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../../components/estrutura/principal/Layout";
import Basico from "./Passo1";
import Detalhes from "./Passo2";
import Requisitos from "./Passo3";
import { UserContext } from "../../../context/UserContext";
import api from "../../../api";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ReactComponent as Steps } from "../../../img/icons/steps-border.svg";
import { ReactComponent as Logo } from "../../../img/logo.svg";
import { ReactComponent as Check } from "../../../img/icons/check.svg";
import { ReactComponent as ArrowRight } from "../../../img/icons/arrow-right.svg";
import { cidadeIDPorNome } from "../../../utils/encontrarCidade";

const StepsComp = ({ vagaCompleta, id, proximoPasso, passoAnterior }) => {
  switch (id) {
    case 1:
      return (
        <Basico
          contratante={vagaCompleta.contratante}
          tituloVaga={vagaCompleta.tituloVaga}
          ddd={vagaCompleta.ddd}
          telefone={vagaCompleta.telefone}
          cep={vagaCompleta.cep}
          endereco={vagaCompleta.endereco}
          numero={vagaCompleta.numero}
          bairro={vagaCompleta.bairro}
          estadoID={vagaCompleta.estadoID}
          cidadeID={vagaCompleta.cidadeID}
          telemedicina={vagaCompleta.telemedicina}
          proximoPasso={proximoPasso}
        />
      );
    case 2:
      return (
        <Detalhes
          tempo={vagaCompleta.tempo}
          regimeContrato={vagaCompleta.regimeContrato}
          valeAlimentacao={vagaCompleta.valeAlimentacao}
          valeRefeicao={vagaCompleta.valeRefeicao}
          valeTransporte={vagaCompleta.valeTransporte}
          beneficios={vagaCompleta.beneficios}
          descricao={vagaCompleta.descricao}
          faixaSalarialDe={vagaCompleta.faixaSalarialDe}
          faixaSalarialAte={vagaCompleta.faixaSalarialAte}
          salarioAcombinar={vagaCompleta.salarioAcombinar}
          proximoPasso={proximoPasso}
          passoAnterior={passoAnterior}
        />
      );
    case 3:
      return (
        <Requisitos
          tempoExperiencia={vagaCompleta.tempoExperiencia}
          escolaridade={vagaCompleta.escolaridade}
          diferenciais={vagaCompleta.diferenciais}
          proximoPasso={proximoPasso}
          passoAnterior={passoAnterior}
        />
      );
    default:
      return "";
  }
};

const passos = [
  {
    label: "Básico",
    dica: {
      titulo: "Vamos anunciar a sua vaga",
      texto:
        "Adicione informações básicas sobre a vaga, isso irá ajudar na sua localização.",
    },
  },
  {
    label: "Detalhes",
    dica: {
      titulo: "Nos conte quais são os detalhes dessa vaga",
      texto: "São esses detalhes que mais atraem novos candidatos!",
    },
  },
  {
    label: "Requisitos",
    dica: {
      titulo: "Adicione quais são os pré-requisitos para essa vaga.",
      texto:
        "Você pode também adicionar diferenciais para preencher essa vaga.",
    },
  },
];

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentClass, setCurrentClass] = useState("proximoComponente");
  const [vagaCompleta, setVagaCompleta] = useState({});
  const [validarForm, setValidarForm] = useState(false);

  const voltarPasso = (e) => {
    setCurrentClass("anteriorComponente");
    setCurrentStep(currentStep - 1);
  };

  const onValidacao = (obj, key) => {
    var newVaga = Object.assign({}, vagaCompleta);
    setVagaCompleta({ ...newVaga, ...obj });
    setCurrentClass("proximoComponente");
    if (currentStep < passos.length) {
      setCurrentStep(currentStep + 1);
    } else {
      setValidarForm(true);
    }
  };

  useEffect(() => {
    if (validarForm) {
      api
        .post(`/Anuncios/criar?token=${user.tokenID}`, {
          ...vagaCompleta,
          usuarioID: user.userInfo.id,
          ativo: true,
          estadoID: vagaCompleta.estadoID.value,
          cidadeID: cidadeIDPorNome(vagaCompleta.cidadeID.label),
          faixaSalarialDe: vagaCompleta.salarioAcombinar
            ? 0
            : parseInt(vagaCompleta.faixaSalarialDe.replace("R$ ", "")),
          faixaSalarialAte: vagaCompleta.salarioAcombinar
            ? 0
            : parseInt(vagaCompleta.faixaSalarialAte.replace("R$ ", "")),
          dataAtualizacao: new Date().toISOString().replace("Z", ""),
          dataPublicacao: new Date().toISOString().replace("Z", ""),
        })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.data);
            updateContext({
              toast: {
                title: "Vaga criada com sucesso!",
                message:
                  "Você pode agora acompanhar o seu desempenho no painel.",
                status: "Success",
              },
              anuncios: user?.anuncios
                ? [...user.anuncios, res.data.anuncio]
                : [res.data.anuncio],
            });
            navigate("/anunciante/vagas", { replace: true });
          }
        })
        .catch((err) => {
          console.log(err);
          updateContext({
            toast: {
              title: "Encontramos um erro",
              message: `${
                err.message ? err.message : "Tente novamente em minutos."
              }`,
              status: "Error",
            },
          });
          setValidarForm(false);
        });
    }
  }, [validarForm]);

  return (
    <>
      <Layout noFooter noHeader>
        <div className="flow">
          <div className="flow__left">
            <div className="flow__left__conteudo">
              <Link to="/">
                <Logo />
              </Link>
              <div className="flow__left__conteudo__dica titulo">
                <h3>{passos[currentStep - 1].dica.titulo}</h3>
                <p>{passos[currentStep - 1].dica.texto}</p>
              </div>
            </div>
          </div>
          <div className="flow__right">
            <span className="flow__right__linha">
              <span
                style={{
                  height: `${(currentStep * 100) / passos.length}%`,
                }}
              ></span>
            </span>
            <div className="flow__right__conteudo">
              <div className="flow__right__conteudo__passos">
                <ul>
                  {passos.map(({ label }, index) => (
                    <li
                      key={`headerStep_${index}`}
                      className={`${
                        index + 1 === currentStep
                          ? "active"
                          : index + 1 < currentStep
                          ? "completed"
                          : ""
                      }`}
                    >
                      {index + 1 < currentStep ? <Check /> : ` ${index + 1} · `}
                      {label}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={currentClass}>
                <SwitchTransition mode={"out-in"}>
                  <CSSTransition
                    key={currentStep}
                    addEndListener={(node, done) => {
                      node.addEventListener("transitionend", done, false);
                    }}
                    classNames={"fade"}
                  >
                    <div>
                      <StepsComp
                        id={currentStep}
                        vagaCompleta={vagaCompleta}
                        passoAnterior={(e) => voltarPasso(e)}
                        proximoPasso={(obj, key) => onValidacao(obj, key)}
                      />
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
