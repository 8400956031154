import React, { useState, useEffect, useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import BuscarVaga from "../../../components/BuscarVaga";
import { UserContext } from "../../../context/UserContext";
import { isMobile, MobileView } from "react-device-detect";
import { ReactComponent as Logo } from "../../../img/logo.svg";
import { ReactComponent as LogoMin } from "../../../img/logo-min.svg";
import { ReactComponent as Sair } from "../../../img/icons/signout.svg";
import { ReactComponent as MenuIcon } from "../../../img/icons/bars.svg";
import { ReactComponent as Entrar } from "../../../img/icons/user-circle.svg";
import { ReactComponent as Cadastrar } from "../../../img/icons/user-plus.svg";
import { ReactComponent as Curriculo } from "../../../img/icons/clipboard-notes.svg";
import { ReactComponent as Anunciar } from "../../../img/icons/briefcase-alt.svg";
import { ReactComponent as Lista } from "../../../img/icons/list-ul.svg";
import { ReactComponent as Painel } from "../../../img/icons/apps.svg";
import { ReactComponent as FlechaEsquerda } from "../../../img/icons/angle-left.svg";
import { ReactComponent as FlechaDireita } from "../../../img/icons/angle-right.svg";

const Menu = ({ menuBusca }) => {
  const { user } = useContext(UserContext);
  const { pathname } = useLocation();
  const [expandMenu, setExpandMenu] = useState(true);
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/vagas/";
  };

  useEffect(() => {
    if (hamburgerMenu) {
      setHamburgerMenu(false);
    }
  }, [pathname]);

  return (
    <>
      <header
        id="dashboard"
        className={
          isMobile
            ? hamburgerMenu
              ? "hamburgerActive"
              : ""
            : expandMenu
            ? "active"
            : ""
        }
      >
        <nav className="nav ">
          <div className="nav__logo">
            <NavLink to="/">{expandMenu ? <Logo /> : <LogoMin />}</NavLink>
          </div>
          <div>
            <div className="nav__menu">
              {user.userInfo.tipoUsuarioID === 0 ? (
                <>
                  {/*    <NavLink to="/candidato/">
                    <Painel />
                    <span>Painel</span>
                  </NavLink> */}
                  <NavLink to="/candidato/curriculo">
                    <Curriculo />
                    <span>Currículo</span>
                  </NavLink>
                  <NavLink to="/candidato/candidaturas">
                    <Lista />
                    <span>Candidaturas</span>
                  </NavLink>
                  {/*   <NavLink to="/candidato/perfil">
                    <Entrar />
                    <span>Perfil</span>
                  </NavLink> */}
                </>
              ) : (
                <>
                  {/*  <NavLink to="/anunciante/">
                    <Painel />
                    <span>Painel</span>
                  </NavLink> */}
                  <NavLink to="/anunciante/vagas">
                    <Anunciar />
                    <span>Vagas</span>
                  </NavLink>
                  <NavLink to="/anunciante/candidatos">
                    <Lista />
                    <span>Candidatos</span>
                  </NavLink>
                  {/*  <NavLink to="/anunciante/perfil">
                    <Entrar />
                    <span>Perfil</span>
                  </NavLink> */}
                </>
              )}
            </div>
          </div>

          <div className="nav__sair">
            <a onClick={() => logout()}>
              <Sair />
              <span>Sair</span>
            </a>
          </div>
        </nav>

        {!isMobile && (
          <button onClick={() => setExpandMenu(!expandMenu)}>
            {expandMenu ? <FlechaEsquerda /> : <FlechaDireita />}
          </button>
        )}
      </header>
      <MobileView renderWithFragment>
        <nav>
          <div className="logo">
            <NavLink to="/">
              <Logo />
            </NavLink>
          </div>
          <button onClick={() => setHamburgerMenu(!hamburgerMenu)}>
            <MenuIcon />
          </button>
        </nav>
        {hamburgerMenu ? (
          <div
            onClick={() => setHamburgerMenu(!hamburgerMenu)}
            className={`${!hamburgerMenu ? "fadeout" : ""} backdrop`}
          ></div>
        ) : (
          ""
        )}
        <div className="nav__block"></div>
      </MobileView>
    </>
  );
};

export default Menu;
