import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../../components/estrutura/SEO";
import { UserContext } from "../../../../context/UserContext";
import Basico from "../../../Principal/CriarCurriculo/Passo1";
import Escolaridade from "../../../Principal/CriarCurriculo/Passo2";
import Experiencia from "../../../Principal/CriarCurriculo/Passo3";
import Loading from "../../../../components/Loading";
import api from "../../../../api";
import { ReactComponent as Curriculo } from "../../../../img/icons/clipboard-notes.svg";

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const [curriculoCompleto, setCurriculoCompleto] = useState({});
  const [basicoEditado, setBasicoEditado] = useState({});
  const [escolaridadeEditado, setEscolaridadeEditado] = useState([]);
  const [experienciaEditado, setExperienciaEditado] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api
      .get(`/curriculo/pesquisar`, {
        params: {
          token: user.tokenID,
          curriculoID: user.userInfo.id,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          setCurriculoCompleto(res.data.curriculo);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          /* updateContext({
            toast: {
              title: err.response.data.mensagem,
              status: "Error",
            },
          }); */
        }
        setLoading(false);
      });
  }, []);

  const salvarCurriculo = () => {
    var novoCurriculo = {
      ...basicoEditado,
      usuarioID: user.userInfo.id,
      curriculoID: user.userInfo.id,
      lstEscolaridades: escolaridadeEditado,
      lstExperiencias: experienciaEditado,
    };
    api
      .put(`/curriculo/alterar?token=${user.tokenID}`, {
        ...novoCurriculo,
        especialidades: novoCurriculo.especialidades.map(({ value }) => value),
      })
      .then((res) => {
        if (res.status === 200) {
          setCurriculoCompleto(novoCurriculo);
          updateContext({
            toast: {
              title: "Sucesso",
              message: "Seu currículo foi atualizado!",
              status: "Success",
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          updateContext({
            toast: {
              title: err.response.data.mensagens[0].txt,
              status: "Error",
            },
          });
        }
      });
  };

  return (
    <>
      <SEO
        seo={{
          title: `Currículo · Área do Candidato`,
          description: ``,
        }}
      />
      <div className="painel">
        <div className="painel__titulo">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-between ">
              <div>
                <h3>Edite seu currículo</h3>
                <div className="lead">
                  Faça suas modificações e salve em cada etapa!
                </div>
              </div>
              <div>
                <button
                  onClick={salvarCurriculo}
                  className="btn-vagas btn-vagas__primary"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        {loading ? (
          <Loading />
        ) : (
          <div className="row justify-content-center">
            {curriculoCompleto && Object.keys(curriculoCompleto).length > 0 ? (
              <>
                <div className="col-lg-8">
                  <div className="vagaCard">
                    <div className="pb-2">
                      <Basico
                        enviarPai={(obj) => setBasicoEditado(obj)}
                        nome={curriculoCompleto.nome}
                        sobrenome={curriculoCompleto.sobrenome}
                        ddd={curriculoCompleto.ddd}
                        telefone={curriculoCompleto.telefone}
                        estadoID={curriculoCompleto.estadoID}
                        cidadeID={curriculoCompleto.cidadeID}
                        telemedicina={curriculoCompleto.telemedicina}
                        especialidades={curriculoCompleto.especialidades}
                      />
                    </div>
                    <div className="pb-2">
                      <Escolaridade
                        enviarPai={(arr) => setEscolaridadeEditado(arr)}
                        lstEscolaridades={curriculoCompleto.lstEscolaridades}
                      />
                    </div>
                    <div className="pb-2">
                      <Experiencia
                        enviarPai={(arr) => setExperienciaEditado(arr)}
                        lstExperiencias={curriculoCompleto.lstExperiencias}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="painel__vazio">
                <Curriculo />
                <p>Não conseguimos encontrar o seu currículo, já criou um?</p>
                <Link className="p3" to="/criar-curriculo/">
                  Clique aqui para criar o seu currículo
                </Link>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default IndexPage;
