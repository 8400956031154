import React, { createContext, useState, useEffect, useContext } from "react";

import { BrowserRouter } from "react-router-dom";
import Paths from "./routes/routes.js";
import UserProvider from "./context/UserContext";
import ScrollToTop from "./utils/scrollToTop";
import FlagMessage from "./components/FlagMessage";

const App = () => {
  return (
    <>
      <UserProvider>
        <BrowserRouter>
          <FlagMessage />
          <ScrollToTop />
          <Paths />
        </BrowserRouter>
      </UserProvider>
    </>
  );
};

export default App;
