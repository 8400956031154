import React, { useState, useContext, useEffect } from "react";
import InputText from "../../../../components/InputText";
import InputSelect from "../../../../components/InputSelect";
import InputCheck from "../../../../components/InputCheck";
import { UserContext } from "../../../../context/UserContext";
import { validarCurriculoArr } from "../../../../utils/validarForm";
import { ReactComponent as Delete } from "../../../../img/icons/times.svg";
import { ReactComponent as ArrowRight } from "../../../../img/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../img/icons/arrow-left.svg";
import InputMask from "react-input-mask";
import moment from "moment";
import "moment/locale/pt-br";
import api from "../../../../api";

const stateInicial = {
  grauAcademico: "",
  areaEstudo: "",
  instituicao: "",
  local: "",
  periodoDe: "",
  periodoAte: "",
  periodoAtual: false,
};

const Passo2 = ({
  enviarPai,
  lstEscolaridades,
  passoAnterior,
  proximoPasso,
}) => {
  const { user, updateContext } = useContext(UserContext);
  const [curriculoBasico, setCurriculoBasico] = useState(stateInicial);
  const [formErrors, setFormError] = useState({});
  const [escolaridadesSalvas, setEscolaridadesSalvas] = useState(
    lstEscolaridades || []
  );

  useEffect(() => {
    if (enviarPai) {
      enviarPai(escolaridadesSalvas, "lstEscolaridades");
    }
  }, [escolaridadesSalvas]);

  const salvarEscolaridade = () => {
    var obj = validarCurriculoArr({
      ...curriculoBasico,
    });
    if (!obj.status) {
      setFormError(obj.state);
    } else {
      if (
        moment(curriculoBasico.periodoDe, "DD/MM/YYYY").isValid() === false ||
        moment(curriculoBasico.periodoAte, "DD/MM/YYYY").isValid() === false
      ) {
        setFormError({
          periodoDe: "Período inválido",
          periodoAte: "Período inválido",
        });
      } else {
        if (
          moment
            .duration(
              moment(curriculoBasico.periodoAte, "DD/MM/YYYY").diff(
                moment(curriculoBasico.periodoDe, "DD/MM/YYYY")
              )
            )
            .asDays() < 1
        ) {
          setFormError({
            periodoDe: "Período inválido",
            periodoAte: "Período inválido",
          });
        } else {
          let final = {
            ...obj.state,
            periodoDe: moment(curriculoBasico.periodoDe, "DD/MM/YYYY")
              .add(1, "day")
              .startOf("day")
              .toISOString()
              .replace(/T.*/gi, "T00:00:00.000"),
            periodoAte: moment(curriculoBasico.periodoAte, "DD/MM/YYYY")
              .add(1, "day")
              .startOf("day")
              .toISOString()
              .replace(/T.*/gi, "T00:00:00.000"),
          };
          setEscolaridadesSalvas([...escolaridadesSalvas, final]);
          setFormError({});
          setCurriculoBasico(stateInicial);
        }
      }
    }
  };

  const handleUserInput = (id, value) => {
    if (id === "atualmente") {
      setFormError({
        ...formErrors,
        periodoAte: { ...formErrors.periodoAte, mes: null, ano: null },
      });
    } else {
      setCurriculoBasico({ ...curriculoBasico, [id]: value });
    }
  };

  const validarCampos = () => {
    proximoPasso(escolaridadesSalvas, "lstEscolaridades");
  };

  return (
    <>
      <h6 className="mb-0 font-600 color-secondary">Estudo</h6>
      <div className="row">
        <div className="col-12">
          <InputSelect
            id="grauAcademico"
            placeholder="Selecione o grau acadêmico"
            onChange={(event) => {
              handleUserInput("grauAcademico", event.label);
            }}
            options={[
              { label: "Ensino Médio (2° Grau)", value: 1 },
              { label: "Curso Técnico", value: 2 },
              { label: "Ensino Superior", value: 3 },
              { label: "Pós-graduação - Especialização/MBA", value: 4 },
              { label: "Pós-graduação - Mestrado", value: 5 },
              { label: "Pós-graduação - Doutorado", value: 6 },
            ]}
            stateValueNull={curriculoBasico.grauAcademico === "" ? true : false}
            error={formErrors.grauAcademico}
          />
        </div>
        <div className="col-12">
          <InputText
            id="areaEstudo"
            placeholder="Área de estudo. Ex.: Cardiologia, Dermatologia..."
            type="text"
            onChange={(event) =>
              handleUserInput("areaEstudo", event.target.value)
            }
            value={curriculoBasico.areaEstudo}
            error={formErrors.areaEstudo}
            className="w-100 mt-2"
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <h6 className="mb-0 font-600 color-secondary">Instituição</h6>
        <div className="col-md-6">
          <InputText
            id="instituicao"
            placeholder="Nome da instituição de ensino"
            type="text"
            onChange={(event) =>
              handleUserInput("instituicao", event.target.value)
            }
            value={curriculoBasico.instituicao}
            error={formErrors.instituicao}
            className="w-100 mt-2"
          />
        </div>
        <div className="col-md-6">
          <InputText
            id="local"
            placeholder="Local da instituição. Ex.: São Paulo, SP."
            type="text"
            onChange={(event) => handleUserInput("local", event.target.value)}
            value={curriculoBasico.local}
            error={formErrors.local}
            className="w-100 mt-2"
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <h6 className="mb-0 font-600 color-secondary">Período</h6>
        <div className="col-md-6">
          <InputMask
            mask="99/99/9999"
            maskPlaceholder={null}
            onChange={(event) =>
              handleUserInput("periodoDe", event.target.value)
            }
            value={curriculoBasico.periodoDe}
          >
            <InputText
              id="periodoDe"
              placeholder="Data de início"
              type="text"
              className="w-100 mt-2"
              error={formErrors?.periodoDe}
            />
          </InputMask>
        </div>

        <div className="col-md-6">
          <div className="row  gx-1">
            <div className="col-12">
              <InputMask
                mask="99/99/9999"
                maskPlaceholder={null}
                onChange={(event) =>
                  handleUserInput("periodoAte", event.target.value)
                }
                value={curriculoBasico.periodoAte}
              >
                <InputText
                  id="periodoAte"
                  placeholder="Data de término"
                  type="text"
                  className="w-100 mt-2"
                  error={formErrors?.periodoAte}
                />
              </InputMask>
            </div>
            <div className="col-12">
              <InputCheck
                id="periodoAteAtualmente"
                placeholder="Estudo aqui atualmente"
                defaultChecked={curriculoBasico.periodoAtual}
                checked={curriculoBasico.periodoAtual}
                onChange={() =>
                  handleUserInput("periodoAtual", !curriculoBasico.periodoAtual)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        <button
          onClick={salvarEscolaridade}
          className="btn-vagas btn-vagas__secondary w-100"
        >
          Salvar nova escolaridade
        </button>
      </div>
      <div className="col-12 mt-3">
        {escolaridadesSalvas.length > 0 && (
          <ul className="box__lista">
            {escolaridadesSalvas.map(
              ({ grauAcademico, local, areaEstudo }, index) => (
                <li key={`${grauAcademico}_${index}`}>
                  <div>
                    {grauAcademico} em {local} - {areaEstudo}
                  </div>
                  <span
                    onClick={() =>
                      setEscolaridadesSalvas(
                        escolaridadesSalvas.filter((el, i) => index !== i)
                      )
                    }
                  >
                    <Delete />
                  </span>
                </li>
              )
            )}
          </ul>
        )}
      </div>
      <hr />

      {proximoPasso || passoAnterior ? (
        <div className="row">
          <div className="col-12">
            <div className="flow__buttons">
              <button
                onClick={passoAnterior}
                className="btn-vagas btn-vagas__ghost__secondary"
              >
                <ArrowLeft className="me-1" /> Voltar
              </button>
              <button
                onClick={validarCampos}
                className={`btn-vagas ${
                  escolaridadesSalvas.length === 0
                    ? "btn-vagas__ghost__primary"
                    : "btn-vagas__primary"
                }`}
              >
                {escolaridadesSalvas.length === 0
                  ? "Pular etapa"
                  : "Próximo passo"}{" "}
                <ArrowRight className="ms-1" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Passo2;
