import React from "react";
const Loading = (props) => {
  return (
    <div className="loading">
      <div
        className={`loading__spinner ${props.className}`}
        style={{
          width: props.width ? props.width : "50px",
          height: props.height ? props.height : "50px",
        }}
      ></div>
    </div>
  );
};

export default Loading;
