import React, { useState, useContext, useEffect } from "react";
import InputText from "../../../../components/InputText";
import InputSelect from "../../../../components/InputSelect";
import InputCheck from "../../../../components/InputCheck";
import { UserContext } from "../../../../context/UserContext";
import { validarCurriculoArr } from "../../../../utils/validarForm";
import { ReactComponent as Delete } from "../../../../img/icons/times.svg";
import { ReactComponent as ArrowRight } from "../../../../img/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../img/icons/arrow-left.svg";
import InputMask from "react-input-mask";
import moment from "moment";
import "moment/locale/pt-br";

const stateInicial = {
  cargo: "",
  empresa: "",
  local: "",
  periodoDe: "",
  periodoAte: "",
  periodoAtual: false,
};

const Passo3 = ({
  enviarPai,
  lstExperiencias,
  passoAnterior,
  proximoPasso,
}) => {
  const { user } = useContext(UserContext);
  const [curriculoBasico, setCurriculoBasico] = useState(stateInicial);
  const [formErrors, setFormError] = useState({});
  const [experienciasSalvas, setExperienciasSalvas] = useState(
    lstExperiencias || []
  );

  useEffect(() => {
    if (enviarPai) {
      enviarPai(experienciasSalvas, "lstExperiencias");
    }
  }, [experienciasSalvas]);

  const salvarExperiencia = () => {
    var obj = validarCurriculoArr({
      ...curriculoBasico,
    });
    if (!obj.status) {
      setFormError(obj.state);
    } else {
      if (
        moment(curriculoBasico.periodoDe, "DD/MM/YYYY").isValid() === false ||
        moment(curriculoBasico.periodoAte, "DD/MM/YYYY").isValid() === false
      ) {
        setFormError({
          periodoDe: "Período inválido",
          periodoAte: "Período inválido",
        });
      } else {
        let final = {
          ...obj.state,
          periodoDe: moment(curriculoBasico.periodoDe, "DD/MM/YYYY")
            .add(1, "day")
            .startOf("day")
            .toISOString()
            .replace(/T.*/gi, "T00:00:00.000"),
          periodoAte: moment(curriculoBasico.periodoAte, "DD/MM/YYYY")
            .add(1, "day")
            .startOf("day")
            .toISOString()
            .replace(/T.*/gi, "T00:00:00.000"),
        };
        setExperienciasSalvas([...experienciasSalvas, final]);
        setFormError({});
        setCurriculoBasico(stateInicial);
      }
    }
  };

  const handleUserInput = (id, value) => {
    if (id === "atualmente") {
      setFormError({
        ...formErrors,
        periodoAte: { ...formErrors.periodoAte, mes: null, ano: null },
      });
    } else {
      setCurriculoBasico({ ...curriculoBasico, [id]: value });
    }
  };

  const validarCampos = () => {
    //mandar para servidor para validar?
    proximoPasso(experienciasSalvas, "lstExperiencias");
  };

  return (
    <>
      <h6 className="mb-0 font-600 color-secondary">Cargo</h6>
      <div className="row">
        <div className="col-12">
          <InputText
            id="cargo"
            placeholder="Qual posição você ocupou na empresa?"
            type="text"
            onChange={(event) => handleUserInput("cargo", event.target.value)}
            value={curriculoBasico.cargo}
            error={formErrors.cargo}
            className="w-100 mt-2"
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <h6 className="mb-0 font-600 color-secondary">Empresa</h6>

        <div className="col-md-6">
          <InputText
            id="empresa"
            placeholder="Nome da empresa"
            type="text"
            onChange={(event) => handleUserInput("empresa", event.target.value)}
            value={curriculoBasico.empresa}
            error={formErrors.empresa}
            className="w-100 mt-2"
          />
        </div>
        <div className="col-md-6">
          <InputText
            id="local"
            placeholder="Local de trabalho. Ex.: São Paulo, SP."
            type="text"
            onChange={(event) => handleUserInput("local", event.target.value)}
            value={curriculoBasico.local}
            error={formErrors.local}
            className="w-100 mt-2"
          />
        </div>
      </div>
      <div className="row justify-content-center mt-3">
        <h6 className="mb-0 font-600 color-secondary">Período</h6>
        <div className="col-md-6">
          <InputMask
            mask="99/99/9999"
            maskPlaceholder={null}
            onChange={(event) =>
              handleUserInput("periodoDe", event.target.value)
            }
            value={curriculoBasico.periodoDe}
          >
            <InputText
              id="periodoDeEmprego"
              placeholder="Data de início"
              type="text"
              className="w-100 mt-2"
              error={formErrors?.periodoDe}
            />
          </InputMask>
        </div>

        <div className="col-md-6">
          <div className="row  gx-1">
            <div className="col-12">
              {/*  {moment(curriculoBasico.periodoDe, "DD/MM/YYYY").format()} */}
              <InputMask
                mask="99/99/9999"
                maskPlaceholder={null}
                onChange={(event) =>
                  handleUserInput("periodoAte", event.target.value)
                }
                value={curriculoBasico.periodoAte}
              >
                <InputText
                  id="periodoAteEmprego"
                  placeholder="Data de término"
                  type="text"
                  className="w-100 mt-2"
                  error={formErrors?.periodoAte}
                />
              </InputMask>
            </div>
            <div className="col-12">
              <InputCheck
                id="periodoAteAtualmenteEmprego"
                placeholder="Esse é meu emprego atual"
                defaultChecked={curriculoBasico.periodoAtual}
                checked={curriculoBasico.periodoAtual}
                onChange={() =>
                  handleUserInput("periodoAtual", !curriculoBasico.periodoAtual)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3">
        <button
          onClick={salvarExperiencia}
          className="btn-vagas btn-vagas__secondary w-100"
        >
          Salvar nova experiência
        </button>
      </div>
      <div className="col-12 mt-3">
        {experienciasSalvas.length > 0 && (
          <ul className="box__lista">
            {experienciasSalvas.map(({ cargo, local, empresa }, index) => (
              <li key={`${cargo}_${index}`}>
                <div>
                  {cargo} em {local} - {empresa}
                </div>
                <span
                  onClick={() =>
                    setExperienciasSalvas(
                      experienciasSalvas.filter((el, i) => index !== i)
                    )
                  }
                >
                  <Delete />
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
      <hr />
      {proximoPasso || passoAnterior ? (
        <div className="row">
          <div className="col-12">
            <div className="flow__buttons">
              <button
                onClick={passoAnterior}
                className="btn-vagas btn-vagas__ghost__secondary"
              >
                <ArrowLeft className="me-1" /> Voltar
              </button>
              <button
                onClick={validarCampos}
                className={`btn-vagas ${
                  experienciasSalvas.length === 0
                    ? "btn-vagas__ghost__primary"
                    : "btn-vagas__primary"
                }`}
              >
                {experienciasSalvas.length === 0
                  ? "Pular e finalizar"
                  : "Finalizar currículo"}{" "}
                <ArrowRight className="ms-1" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Passo3;
