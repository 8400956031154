import React, { useState, useEffect } from "react";
import InputText from "../../../../components/InputText";
import InputTextArea from "../../../../components/InputTextArea";
import InputSelect from "../../../../components/InputSelect";
import InputCheck from "../../../../components/InputCheck";
import { validarState } from "../../../../utils/validarForm";
import { toBRL } from "../../../../utils/toBRL";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as Delete } from "../../../../img/icons/times.svg";
import { ReactComponent as ArrowRight } from "../../../../img/icons/arrow-right.svg";
import InputMask from "react-input-mask";
import { ReactComponent as ArrowLeft } from "../../../../img/icons/arrow-left.svg";

const Passo2 = ({
  tempo,
  regimeContrato,
  beneficios,
  faixaSalarialDe,
  faixaSalarialAte,
  salarioAcombinar,
  descricao,
  proximoPasso,
  passoAnterior,
  enviarPai,
}) => {
  const [anuncioBasico, setAnuncioBasico] = useState({
    tempo: tempo || "",
    regimeContrato: regimeContrato || "",
    /* beneficios: beneficios || [], */
    valeAlimentacao: true,
    valeRefeicao: true,
    valeTransporte: true,
    faixaSalarialDe: faixaSalarialDe || "",
    faixaSalarialAte: faixaSalarialAte || "",
    descricao: descricao || "",
    salarioAcombinar: salarioAcombinar || false,
  });
  const [formErrors, setFormError] = useState({});

  const validarCampos = () => {
    var campos = Object.keys(anuncioBasico);
    if (anuncioBasico.salarioAcombinar) {
      campos = campos.filter(
        (item) => item !== `faixaSalarialAte` && item !== `faixaSalarialDe`
      );
    }
    var camposValidar = Object.keys(anuncioBasico)
      .filter((key) => campos.includes(key))
      .reduce((obj, key) => {
        obj[key] = anuncioBasico[key];
        return obj;
      }, {});
    var obj = validarState(JSON.parse(JSON.stringify(camposValidar)));
    if (!obj.status) {
      setFormError(obj.state);
    } else {
      if (
        !anuncioBasico.salarioAcombinar &&
        parseInt(anuncioBasico.faixaSalarialAte.replace("R$", "")) <=
          parseInt(anuncioBasico.faixaSalarialDe.replace("R$", ""))
      ) {
        setFormError({
          ...formErrors,
          faixaSalarialDe: "Valor não aceito",
          faixaSalarialAte: "Valor não aceito",
        });
      } else {
        proximoPasso(anuncioBasico);
      }
    }
  };

  useEffect(() => {
    if (enviarPai) {
      enviarPai(anuncioBasico);
    }
  }, [anuncioBasico]);

  return (
    <>
      <h6 className="mb-0 font-600 color-secondary">Tipo de vaga</h6>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <InputSelect
            id="tempo"
            placeholder="Tempo integral ou meio período?"
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                tempo: event.label,
              });
            }}
            value={tempo ? { label: tempo, value: tempo } : ""}
            options={[
              { label: "Tempo Integral", value: "Tempo Integral" },
              { label: "Meio Período - Manhã", value: "Meio Período - Manhã" },
              { label: "Meio Período - Tarde", value: "Meio Período - Tarde" },
              { label: "Meio Período - Noite", value: "Meio Período - Noite" },
            ]}
            error={formErrors?.tempo}
            required
          />
        </div>

        <div className="col-md-6">
          <InputSelect
            id="regimeContrato"
            placeholder="Qual regime de contratação?"
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                regimeContrato: event.label,
              });
            }}
            value={
              regimeContrato
                ? { label: regimeContrato, value: regimeContrato }
                : ""
            }
            options={[
              { label: "CLT (Efetivo)", value: "CLT (Efetivo)" },
              {
                label: "Prestador de Serviços (PJ)",
                value: "Prestador de Serviços (PJ)",
              },
              { label: "Temporário", value: "Temporário" },
              { label: "Autônomo", value: "Autônomo" },
            ]}
            error={formErrors?.regimeContrato}
            required
          />
        </div>
        <div className="col-12 mt-2">
          {/*    <div className="input-float select-float">
            <CreatableSelect
              id="beneficios"
              placeholder={"Selecione os benefícios oferecidos"}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              isMulti
              value={anuncioBasico.beneficios ? anuncioBasico.beneficios : null}
              formatCreateLabel={(text) => `Adicionar ${text}`}
              onChange={(newValue) =>
                setAnuncioBasico({
                  ...anuncioBasico,
                  beneficios: newValue,
                })
              }
              options={[
                {
                  label: "Assistência médica",
                  value: "Assistência médica",
                },
                {
                  label: "Participação nos lucros",
                  value: "Participação nos lucros",
                },
                {
                  label: "Vale-alimentação",
                  value: "Vale-alimentação",
                },
                {
                  label: "Vale-refeição",
                  value: "Vale-refeição",
                },
                {
                  label: "Bonificação por desempenho",
                  value: "Bonificação por desempenho",
                },
                {
                  label: "Vale-transporte",
                  value: "Vale-transporte",
                },
                {
                  label: "Assistência odontológica",
                  value: "Assistência odontológica",
                },
                {
                  label: "Auxílio educação",
                  value: "Auxílio educação",
                },
                {
                  label: "Seguro de vida",
                  value: "Seguro de vida",
                },
                {
                  label: "Previdência privada",
                  value: "Previdência privada",
                },
                {
                  label: "Cesta básica",
                  value: "Cesta básica",
                },
                {
                  label: "Convênios/ descontos comerciais",
                  value: "Convênios/ descontos comerciais",
                },
                {
                  label: "Estacionamento",
                  value: "Estacionamento",
                },
                {
                  label: "Veículo da empresa",
                  value: "Veículo da empresa",
                },
                {
                  label: "Auxílio creche",
                  value: "Auxílio creche",
                },
                {
                  label: "Auxílio moradia",
                  value: "Auxílio moradia",
                },
                {
                  label: "Celular fornecido pela empresa",
                  value: "Celular fornecido pela empresa",
                },
                {
                  label: "Empréstimos consignados",
                  value: "Empréstimos consignados",
                },
              ]}
              error={formErrors?.beneficios}
            />
          </div> */}
        </div>
      </div>
      <hr />
      <h6 className="mb-0 font-600 color-secondary">Descrição da vaga</h6>
      <div className="row justify-content-center">
        <div className="col-12">
          <InputTextArea
            id="descricao"
            placeholder="Descreva a vaga para o candidato"
            type="text"
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                descricao: event.target.value,
              });
            }}
            rows="8"
            value={anuncioBasico.descricao}
            className="w-100 mt-2"
            required
            error={formErrors?.descricao}
          />
        </div>
      </div>
      <hr />
      <h6 className="mb-0 font-600 color-secondary">Faixa salarial</h6>
      <div className="row gx-2 align-items-center">
        <div className="col-md-4">
          <InputMask
            mask="R$ 99999"
            maskPlaceholder={null}
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                faixaSalarialDe: event.target.value,
              });
            }}
            disabled={anuncioBasico.salarioAcombinar ? true : false}
            value={
              faixaSalarialDe
                ? `${faixaSalarialDe}`
                : anuncioBasico.faixaSalarialDe
            }
          >
            <InputText
              id="faixaSalarialDe"
              placeholder="A partir de R$"
              autocomplete="off"
              type="text"
              className="w-100 mt-2"
              error={formErrors?.faixaSalarialDe}
              required={anuncioBasico.salarioAcombinar ? false : true}
            />
          </InputMask>
        </div>
        <div className="col-md-4">
          <InputMask
            mask="R$ 99999"
            maskPlaceholder={null}
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                faixaSalarialAte: event.target.value,
              });
            }}
            disabled={anuncioBasico.salarioAcombinar ? true : false}
            value={
              faixaSalarialAte
                ? `${faixaSalarialAte}`
                : anuncioBasico.faixaSalarialAte
            }
          >
            <InputText
              id="faixaSalarialAte"
              placeholder="Até R$"
              autocomplete="off"
              type="text"
              className="w-100 mt-2"
              error={formErrors?.faixaSalarialAte}
              required={anuncioBasico.salarioAcombinar ? false : true}
            />
          </InputMask>
        </div>
        <div className="col-md-4">
          <InputCheck
            id="salarioAcombinar"
            placeholder="A combinar"
            defaultChecked={anuncioBasico.salarioAcombinar}
            checked={anuncioBasico.salarioAcombinar}
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                salarioAcombinar: !anuncioBasico.salarioAcombinar,
              });
            }}
          />
        </div>
      </div>
      <hr />

      {proximoPasso || passoAnterior ? (
        <div className="row">
          <div className="col-12">
            <div className="flow__buttons">
              <button
                onClick={passoAnterior}
                className="btn-vagas btn-vagas__ghost__secondary"
              >
                <ArrowLeft className="me-1" /> Voltar
              </button>
              <button
                onClick={validarCampos}
                className="btn-vagas btn-vagas__primary"
              >
                Próximo passo <ArrowRight className="ms-1" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Passo2;
