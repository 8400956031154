import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { ReactComponent as Search } from "../../img/icons/search-alt.svg";
import { ReactComponent as ArrowRight } from "../../img/icons/arrow-right.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { makeUrlFriendly } from "../../utils/makeUrlFriendly";

import api from "../../api";
import { isMobile } from "react-device-detect";
import qs from "query-string";

import BuscarVagaLocal from "./Local";
import BuscarVagaEspecialidade from "./Especialidade";

const BuscarVaga = ({ card, onMenu }) => {
  const { user, updateContext } = useContext(UserContext);

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    local: "",
    posicao: "",
  });

  const buscarVaga = () => {
    navigate(
      `/vagas/${
        inputs.local?.label ? makeUrlFriendly(inputs.local.label) : ""
      }${
        inputs.posicao?.label
          ? `/?TituloVaga=${inputs.posicao.label}`
          : user.posicao
          ? `/?TituloVaga=${user.posicao}`
          : ""
      }`,
      { state: { local: inputs.local, posicao: inputs.posicao } }
    );
  };

  return (
    <>
      <div className={`buscar ${card ? "card" : ""}`}>
        <span>
          <Search />
        </span>
        <div className="buscar__inputs">
          <div className="buscar__inputs--grid">
            <BuscarVagaEspecialidade
              enviarPai={(input) => setInputs({ ...inputs, posicao: input })}
            />
            <span>{onMenu ? "em" : ""}</span>
            <BuscarVagaLocal
              enviarPai={(input) => setInputs({ ...inputs, local: input })}
            />
            <div className="buscar__inputs__button">
              <button
                className="btn-vagas btn-vagas__primary"
                onClick={buscarVaga}
                type="button"
              >
                Buscar <ArrowRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuscarVaga;
