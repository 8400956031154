import React, { useState, useEffect } from "react";

const DataPublicacao = ({ onSelect, value }) => {
  const [opcaoEscolhida, setOpcaoEscolhida] = useState([
    { label: "Hoje", value: 1, active: false },
    { label: "Últimos dois dias", value: 2, active: false },
    { label: "Últimos três dias", value: 3, active: false },
    { label: "Última semana", value: 4, active: false },
    { label: "Últimos 15 dias", value: 5, active: false },
    { label: "Último mês", value: 6, active: false },
  ]);

  const selecionarValor = (el) => {
    var newOpcaoEscolhida = [...opcaoEscolhida].map((item) => {
      if (item.value === el.value) return { ...item, active: true };
      return {
        ...item,
        active: false,
      };
    });
    onSelect("dataPublicacao", el.value);
    setOpcaoEscolhida(newOpcaoEscolhida);
  };

  return (
    <>
      <div className="pt-2 pb-4">
        <div className="d-flex flex-wrap justify-content-center">
          {opcaoEscolhida.map((el) => (
            <div
              key={`chip_${el.value}`}
              className={`chip ${el.active ? "active" : ""}`}
              onClick={() => selecionarValor(el)}
            >
              {el.label}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DataPublicacao;
