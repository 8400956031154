import React, { useState, useContext, useEffect } from "react";
import lista from "../../../data/brazilCitiesDataPopResult.json";
import Select from "react-select";
import { buscarStyle, buscarStyleMobile } from "../../../utils/buscarStyle";
import { sortByInput } from "../../../utils/sortByInput";
import { UserContext } from "../../../context/UserContext";

const BuscarVagaLocal = ({ mobile, enviarPai }) => {
  const { user, updateContext, buscaLocation } = useContext(UserContext);
  const [localOptions, setLocalOptions] = useState([]);
  const [inputLocal, setInputLocal] = useState("");
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    if (Object.keys(buscaLocation).length > 0 && !toggled && !inputLocal) {
      setInputLocal(buscaLocation);
      setToggled(true);
    }
  }, [buscaLocation]);

  useEffect(() => {
    if (enviarPai) {
      enviarPai(inputLocal);
    }
    updateContext(inputLocal, true);
  }, [inputLocal]);

  const onChangeHandler = async (val) => {
    if (val) {
      var result = [];
      for (let i = 0; i < lista.length; i++) {
        if (
          lista[i].name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(
              val
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            )
        ) {
          result.push(lista[i]);
        }
      }
      const data = sortByInput(val, result, "name")
        .sort(function (a, b) {
          return b.priority - a.priority;
        })
        .slice(0, 7)
        .map((el) => ({
          value: el.id,
          label: `${el.name}, ${el.state_code}`,
        }));
      setLocalOptions(data);
    }
  };

  return (
    <>
      <Select
        isClearable={false}
        isSearchable
        value={inputLocal}
        onInputChange={(inputValue, { prevInputValue, action }) => {
          switch (action) {
            case "input-change":
              onChangeHandler(inputValue);
              setInputLocal({ label: inputValue, value: inputValue });
              return inputValue;
            default:
              return "";
          }
        }}
        styles={mobile ? buscarStyleMobile() : buscarStyle()}
        placeholder={"Qual cidade?"}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          ClearIndicator: () => null,
        }}
        hideSelectedOptions={true}
        noOptionsMessage={() => null}
        options={localOptions}
        onChange={(inputValue, { prevInputValue, action }) => {
          setInputLocal(inputValue);
        }}
      />
    </>
  );
};

export default BuscarVagaLocal;
