import { useCallback, useEffect, useState, useRef } from "react";
import { toBRL } from "../utils/toBRL";

const Slider = ({ defaultMin, defaultMax, min, max, onChange }) => {
  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const minValRef = useRef(null);
  const maxValRef = useRef(null);
  const range = useRef(null);
  const [change, setChange] = useState(false);
  const [changeMin, setChangeMin] = useState(false);
  const [changeMax, setChangeMax] = useState(false);

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  );

  useEffect(() => {
    if (defaultMin && defaultMax) {
      setMaxVal(defaultMax);
      setMinVal(defaultMin);
    }
  }, []);

  // Set width of the range to decrease from the left side
  useEffect(() => {
    if (maxValRef.current) {
      const minPercent = getPercent(
        defaultMin && !change ? defaultMin : minVal
      );
      const maxPercent = getPercent(+maxValRef.current.value); // Precede with '+' to convert the value from type string to type number

      if (range.current) {
        range.current.style.left = `${minPercent}%`;
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [minVal, getPercent]);

  // Set width of the range to decrease from the right side
  useEffect(() => {
    if (minValRef.current) {
      const minPercent = getPercent(+minValRef.current.value);
      const maxPercent = getPercent(
        defaultMax && !change ? defaultMax : maxVal
      );

      if (range.current) {
        range.current.style.width = `${maxPercent - minPercent}%`;
      }
    }
  }, [maxVal, getPercent]);

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal });
  }, [minVal, maxVal, onChange]);

  return (
    <div style={{ position: `relative` }}>
      <input
        type="range"
        min={min}
        max={max}
        step="1000"
        value={defaultMin && !change ? defaultMin : minVal}
        ref={minValRef}
        onChange={(event) => {
          const value = Math.min(
            +event.target.value,
            defaultMax && !change ? defaultMax - 1 : maxVal - 1
          );
          setMinVal(value);
          setChange(true);
          event.target.value = value.toString();
        }}
        className={`thumb thumb--zindex-3 ${
          minVal > max - 100 ? "thumb--zindex-5" : ""
        }`}
      />
      <input
        type="range"
        min={min}
        max={max}
        step="1000"
        value={defaultMax && !change ? defaultMax : maxVal}
        ref={maxValRef}
        onChange={(event) => {
          const value = Math.max(
            +event.target.value,
            defaultMin && !change ? defaultMin + 1 : minVal + 1
          );
          setMaxVal(value);
          setChange(true);
          event.target.value = value.toString();
        }}
        className="thumb thumb--zindex-4"
      />

      <div className="slider">
        <div className="slider__track"></div>
        <div ref={range} className="slider__range"></div>
        <div className="slider__left-value">
          {toBRL(defaultMin && !change ? defaultMin : minVal)}
        </div>
        <div className="slider__right-value">
          {toBRL(defaultMax && !change ? defaultMax : maxVal)}
        </div>
      </div>
    </div>
  );
};

export default Slider;
