import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Menu from "./Menu";
import Footer from "./Footer";
import api from "../../../api";

const Layout = ({ children, seoData }) => {
  return (
    <>
      <Menu />
      <div className="component">
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Layout;
