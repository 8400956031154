import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Layout from "../../../components/estrutura/principal/Layout";
import Basico from "./Passo1";
import Escolaridade from "./Passo2";
import Experiencia from "./Passo3";
import { UserContext } from "../../../context/UserContext";
import api from "../../../api";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { ReactComponent as Logo } from "../../../img/logo.svg";
import { ReactComponent as Check } from "../../../img/icons/check.svg";
import qs from "query-string";

const StepsComp = ({ curriculoCompleto, id, proximoPasso, passoAnterior }) => {
  switch (id) {
    case 1:
      return (
        <Basico
          nome={curriculoCompleto.nome}
          sobrenome={curriculoCompleto.sobrenome}
          ddd={curriculoCompleto.ddd}
          telefone={curriculoCompleto.telefone}
          estadoID={curriculoCompleto.estadoID}
          cidadeID={curriculoCompleto.cidadeID}
          telemedicina={curriculoCompleto.telemedicina}
          especialidades={curriculoCompleto.especialidades}
          proximoPasso={proximoPasso}
        />
      );
    case 2:
      return (
        <Escolaridade
          lstEscolaridades={curriculoCompleto.lstEscolaridades}
          proximoPasso={proximoPasso}
          passoAnterior={passoAnterior}
        />
      );
    case 3:
      return (
        <Experiencia
          lstExperiencias={curriculoCompleto.lstExperiencias}
          proximoPasso={proximoPasso}
          passoAnterior={passoAnterior}
        />
      );
    default:
      return "";
  }
};

const passos = [
  {
    label: "Básico",
    dica: {
      titulo: "Vamos criar o seu currículo",
      texto:
        "Adicione informações básicas sobre você, isso irá ajudar na localização de novas vagas",
    },
  },
  {
    label: "Escolaridade",
    dica: {
      titulo: "Adicione seus estudos",
      texto:
        "Nos diga suas formações acadêmicas, seus cursos e especializações!",
    },
  },
  {
    label: "Experiência",
    dica: {
      titulo: "Adicione suas experiências profissionais",
      texto: "Nos conte quais e onde foram os locais no qual você trabalhou.",
    },
  },
];

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [currentClass, setCurrentClass] = useState("proximoComponente");
  const [curriculoCompleto, setCurriculoCompleto] = useState({});
  const [validarForm, setValidarForm] = useState(false);

  const voltarPasso = (e) => {
    setCurrentClass("anteriorComponente");
    setCurrentStep(currentStep - 1);
  };

  useEffect(() => {
    api
      .get(`/curriculo/pesquisar`, {
        params: {
          token: user.tokenID,
          curriculoID: user.userInfo.id,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          if (res.data.curriculo.id > 0) {
            navigate("/candidato/curriculo", { replace: true });
          }
        }
      });
  }, []);

  const onValidacao = (obj, key) => {
    var newCurriculo = Object.assign({}, curriculoCompleto);
    if (key) {
      setCurriculoCompleto({
        ...newCurriculo,
        [key]: [...obj],
      });
    } else {
      setCurriculoCompleto({ ...newCurriculo, ...obj });
    }
    setCurrentClass("proximoComponente");
    if (currentStep < passos.length) {
      setCurrentStep(currentStep + 1);
    } else {
      setValidarForm(true);
    }
  };

  useEffect(() => {
    if (validarForm) {
      api
        .put(`/curriculo/alterar?token=${user.tokenID}`, {
          ...curriculoCompleto,
          especialidades: curriculoCompleto.especialidades.map(
            ({ value }) => value
          ),
          curriculoID: user.userInfo.id,
          usuarioID: user.userInfo.id,
        })
        .then((res) => {
          if (res.status === 200) {
            updateContext({
              toast: {
                title: "Currículo criado!",
                message: "Você pode agora começar a se candidatar.",
                link: "/vagas/",
                linkMessage: "Buscar oportunidades",
                status: "Success",
              },
            });
            var redirect = qs.parse(search)?.redirect;
            if (redirect) {
              navigate(redirect, { replace: true });
            } else {
              navigate("/candidato/candidaturas", { replace: true });
            }
          }
        })
        .catch((err) => {
          console.log(err.response);
          updateContext({
            toast: {
              title: "Encontramos um erro",
              message: `${
                err.response.data.mensagens.length > 0
                  ? err.response.data.mensagens[0].txt
                  : "Tente novamente em minutos."
              }`,
              status: "Error",
            },
          });
          setValidarForm(false);
        });
    }
  }, [validarForm]);

  return (
    <>
      <Layout noFooter noHeader>
        <div className="flow">
          <div className="flow__left">
            <div className="flow__left__conteudo">
              <Link to="/">
                <Logo />
              </Link>
              <div className="flow__left__conteudo__dica titulo">
                <h3>{passos[currentStep - 1].dica.titulo}</h3>
                <p>{passos[currentStep - 1].dica.texto}</p>
              </div>
            </div>
          </div>
          <div className="flow__right">
            <span className="flow__right__linha">
              <span
                style={{
                  height: `${(currentStep * 100) / passos.length}%`,
                }}
              ></span>
            </span>
            <div className="flow__right__conteudo">
              <div className="flow__right__conteudo__passos">
                <ul>
                  {passos.map(({ label }, index) => (
                    <li
                      key={`headerStep_${index}`}
                      className={`${
                        index + 1 === currentStep
                          ? "active"
                          : index + 1 < currentStep
                          ? "completed"
                          : ""
                      }`}
                    >
                      {index + 1 < currentStep ? <Check /> : ` ${index + 1} · `}
                      {label}
                    </li>
                  ))}
                </ul>
              </div>
              <div className={currentClass}>
                <SwitchTransition mode={"out-in"}>
                  <CSSTransition
                    key={currentStep}
                    addEndListener={(node, done) => {
                      node.addEventListener("transitionend", done, false);
                    }}
                    classNames={"fade"}
                  >
                    <div>
                      <StepsComp
                        id={currentStep}
                        curriculoCompleto={curriculoCompleto}
                        passoAnterior={(e) => voltarPasso(e)}
                        proximoPasso={(obj, key) => onValidacao(obj, key)}
                      />
                    </div>
                  </CSSTransition>
                </SwitchTransition>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
