import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
import { UserContext } from "../context/UserContext";
import { ReactComponent as CheckCircle } from "../img/icons/check-fill.svg";
import { Transition } from "react-transition-group";

const ToastIcon = ({ status }) => {
  switch (status) {
    case "Success":
      return <CheckCircle />;
    case "Info":
      return <CheckCircle />;
    case "Warning":
      return <CheckCircle />;
    case "Error":
      return <CheckCircle />;
    default:
      return "";
  }
};

const FlagMessage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const [show, setShow] = useState(false);
  const [toastState, setToastState] = useState({});

  useEffect(() => {
    if (user.toast) {
      setShow(true);
      setToastState(user.toast);
    }
  }, [user.toast]);

  return (
    <>
      <ToastContainer
        className={`p-3 toast--${toastState.status}`}
        position={"bottom-left"}
      >
        <Toast
          onClose={() => {
            setShow(false);
            const timer = setTimeout(() => {
              setToastState({});
              updateContext({ toast: false });
            }, 300);
            return () => clearTimeout(timer);
          }}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Header closeButton={false}>
            <ToastIcon status={toastState.status} />{" "}
            <span>{toastState.title}</span>
          </Toast.Header>
          <Toast.Body>
            <span>{toastState.message}</span>
            {toastState.link && toastState.linkMessage ? (
              <button>
                <Link to={toastState.link}>{toastState.linkMessage}</Link>
              </button>
            ) : (
              ""
            )}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default FlagMessage;
