const estados = [
  {
    completo: "Acre",
    uf: "AC",
  },
  {
    completo: "Alagoas",
    uf: "AL",
  },
  {
    completo: "Amapá",
    uf: "AP",
  },
  {
    completo: "Amazonas",
    uf: "AM",
  },
  {
    completo: "Bahia",
    uf: "BA",
  },
  {
    completo: "Ceará",
    uf: "CE",
  },
  {
    completo: "Distrito Federal",
    uf: "DF",
  },
  {
    completo: "Espírito Santo",
    uf: "ES",
  },
  {
    completo: "Goiás",
    uf: "GO",
  },
  {
    completo: "Maranhão",
    uf: "MA",
  },
  {
    completo: "Mato Grosso",
    uf: "MT",
  },
  {
    completo: "Mato Grosso do Sul",
    uf: "MS",
  },
  {
    completo: "Minas Gerais",
    uf: "MG",
  },
  {
    completo: "Pará",
    uf: "PA",
  },
  {
    completo: "Paraíba",
    uf: "PB",
  },
  {
    completo: "Paraná",
    uf: "PR",
  },
  {
    completo: "Pernambuco",
    uf: "PE",
  },
  {
    completo: "Piauí",
    uf: "PI",
  },
  {
    completo: "Rio de Janeiro",
    uf: "RJ",
  },
  {
    completo: "Rio Grande do Norte",
    uf: "RN",
  },
  {
    completo: "Rio Grande do Sul",
    uf: "RS",
  },
  {
    completo: "Rondônia",
    uf: "RO",
  },
  {
    completo: "Roraima",
    uf: "RR",
  },
  {
    completo: "Santa Catarina",
    uf: "SC",
  },
  {
    completo: "São Paulo",
    uf: "SP",
  },
  {
    completo: "Sergipe",
    uf: "SE",
  },
  {
    completo: "Tocantins",
    uf: "TO",
  },
];

export const converterEstadoParaUF = (estado) => {
  var result = estados.find(({ completo }) => completo === estado).uf;
  return result;
};
