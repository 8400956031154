import React, { useState, useEffect, useContext } from "react";
import { Navigate, Route, useLocation, Outlet } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

const SomenteEmpresa = (props) => {
  const { user } = useContext(UserContext);
  if (user?.userInfo?.tipoUsuarioID !== 0) {
    return <Outlet />;
  } else {
    return <Navigate to="/candidato" />;
  }
};

export default SomenteEmpresa;
