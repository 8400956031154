import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, Navigate, useNavigate } from "react-router-dom";
import Layout from "../../../components/estrutura/principal/Layout";
import TextInput from "../../../components/InputText";
import { UserContext } from "../../../context/UserContext";
import api from "../../../api";
import Loading from "../../../components/Loading";
import qs from "query-string";
import { ReactComponent as Padlock } from "../../../img/icons/padlock.svg";
import { ReactComponent as Envelope } from "../../../img/icons/envelope.svg";
import { ReactComponent as Eye } from "../../../img/icons/eye.svg";
import { ReactComponent as EyeSlash } from "../../../img/icons/eye-slash.svg";
import { ReactComponent as User } from "../../../img/icons/user-circle.svg";

import { ReactComponent as Logo } from "../../../img/logo.svg";

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const query = qs.parse(search);
  const [showingPassword, setShowPassword] = useState(false);
  const [userCandidato, setUserCandidato] = useState(true);
  const [userPassword, setUserPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user.userInfo) {
      var redirect = qs.parse(state?.location?.search)?.redirect;
      var proprio = qs.parse(state?.location?.search)?.proprio;
      if (redirect) {
        if (proprio === "false") {
          window.open(redirect, "_blank");
        } else {
          navigate(`/criar-curriculo?redirect=${redirect}`);
        }
      } else {
        if (user?.userInfo?.tipoUsuarioID === 0) {
          navigate("/criar-curriculo");
        } else {
          navigate("/anunciar-vaga");
        }
      }
    }
  }, [user?.userInfo]);

  const enviarForm = (e) => {
    setLoading(true);
    e.preventDefault();
    api
      .post(`/Usuario/criar`, {
        nome: "Usuário",
        email: userEmail,
        senha: userPassword,
        tipoUsuarioID: userCandidato ? 0 : 1,
      })
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem("token", res.data.tokenID);
          /*  api.defaults.headers["Authorization"] = `${res.data.token}`; */
          updateContext(res.data);
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
          updateContext({
            toast: {
              title: "Encontramos um erro",
              message: err.response.data.message,
              status: "Error",
            },
          });
        }
      })
      .finally((end) => {
        setLoading(false);
      });
  };

  if (user?.valido) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Layout noHeader noFooter>
        <div className="sign">
          <div className="container">
            <div className="sign__logo">
              <Link to="/">
                <Logo />
              </Link>
            </div>
            <div className="sign__form">
              <form className="row" onSubmit={loading ? undefined : enviarForm}>
                <User />
                <h4>Crie sua conta para prosseguir</h4>
                <p>Preencha para candidatar-se ou anunciar uma vaga.</p>
                <div className="col-12">
                  <TextInput
                    id="email"
                    type={"email"}
                    placeholder="E-mail"
                    onChange={(event) => setUserEmail(event.target.value)}
                    value={userEmail}
                    iconLeft={{ svg: <Envelope /> }}
                    className="w-100  mt-2"
                    required
                  />
                </div>
                <div className="col-12">
                  <TextInput
                    id="password"
                    type={showingPassword ? "text" : "password"}
                    placeholder="Senha"
                    onChange={(event) => setUserPassword(event.target.value)}
                    value={userPassword}
                    iconLeft={{ svg: <Padlock /> }}
                    iconRight={{
                      svg: showingPassword ? <EyeSlash /> : <Eye />,
                      onClick: () => setShowPassword(!showingPassword),
                    }}
                    className="w-100  mt-2"
                    required
                  />
                </div>
                <div className="col-12">
                  <hr />
                </div>
                <div className="col-12">
                  <label>
                    <b className="color-secondary">Você é</b>
                    <span className="required">*</span>
                    <br />
                    Informe se você irá anunciar vagas como empresa ou
                    candidatar-se.
                  </label>
                  <div className="input__radio mt-2">
                    <input
                      type="radio"
                      name={`tipoUsuario`}
                      id={"candidato"}
                      value={"candidato"}
                      onChange={(event) => setUserCandidato(true)}
                      checked={userCandidato ? true : false}
                      required
                    />
                    <label
                      className={`${userCandidato ? "active" : ""}`}
                      htmlFor={"candidato"}
                    >
                      <div className="input__radio__dot left">
                        <span></span>
                      </div>
                      Sou um candidato
                    </label>
                  </div>
                  <div className="input__radio mt-2">
                    <input
                      type="radio"
                      name={`tipoUsuario`}
                      id={"empresa"}
                      value={"empresa"}
                      onChange={(event) => setUserCandidato(false)}
                      checked={userCandidato ? false : true}
                      required
                    />

                    <label
                      className={`${!userCandidato ? "active" : ""}`}
                      htmlFor={"empresa"}
                    >
                      <div className="input__radio__dot left">
                        <span></span>
                      </div>
                      Sou uma empresa
                    </label>
                  </div>
                </div>
                <div className="col-12 mt-3">
                  <button className="btn-vagas btn-vagas__primary w-100">
                    {loading ? (
                      <Loading
                        className={"white"}
                        height={"25px"}
                        width={"25px"}
                      />
                    ) : (
                      "Criar conta"
                    )}
                  </button>
                </div>
              </form>
              <p>
                Já possui uma conta? <Link to="/entrar">Clique aqui</Link>
              </p>
            </div>
            <div className="sign__footer">
              © {new Date().getFullYear()} VMED Oportunidades
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;
