import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../../components/estrutura/SEO";
import Loading from "../../../../components/Loading";
import { makeUrlFriendly } from "../../../../utils/makeUrlFriendly";
import moment from "moment";
import { UserContext } from "../../../../context/UserContext";
import api from "../../../../api";
import { ReactComponent as Medalha } from "../../../../img/icons/notes.svg";
import { ReactComponent as Local } from "../../../../img/icons/location-point.svg";

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const [candidaturas, setCandidaturas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activatedTable, setActivatedTable] = useState(null);

  useEffect(() => {
    //consultar na api multiplos ids de vagas

    api
      .get(
        `/vagas/${user?.candidaturas
          .map(({ id }) => parseFloat(id))
          .toString()}`
      )
      .then((res) => {
        if (res.status === 200) {
          setCandidaturas(res.data.vagas);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
        setLoading(false);
      });
  }, []);

  return (
    <>
      <SEO
        seo={{
          title: `Painel · Área do Candidato`,
          description: ``,
        }}
      />
      <div className="painel">
        <div className="painel__titulo">
          <div className="container">
            <h3>Olá, candidato!</h3>
            <div className="lead">
              Pronto para conhecer novas oportunidades?
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mt-5">
            {" "}
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="titulo mb-2">
                  <h5>Suas candidaturas</h5>
                </div>
              </div>
              <div className="col-md-6 text-center text-md-end">
                {candidaturas.length > 0 && (
                  <Link
                    className="p3 color-secondary"
                    to="/candidato/candidaturas"
                  >
                    Ver todas as candidaturas ({candidaturas.length})
                  </Link>
                )}
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <>
                {candidaturas.length > 0 ? (
                  <>
                    <table className="vagas">
                      <thead>
                        <tr>
                          <th scope="col" id="titulo">
                            Título da Vaga
                          </th>
                          <th scope="col" id="local">
                            Local
                          </th>
                          <th scope="col" id="data">
                            Data
                          </th>
                          <th scope="col" id="status">
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {candidaturas
                          .slice(0, 4)
                          .map(
                            (
                              { id, contratante, tituloVaga, local, status },
                              index
                            ) => (
                              <React.Fragment key={id}>
                                <tr
                                  onClick={() =>
                                    setActivatedTable(
                                      activatedTable === id ? null : id
                                    )
                                  }
                                  className={`${
                                    activatedTable === id ? "active" : ""
                                  }`}
                                >
                                  <td headers="titulo">
                                    <b>{tituloVaga}</b>
                                    <span>{contratante}</span>
                                  </td>
                                  <td headers="local">{local.cidUF}</td>
                                  <td headers="data">
                                    {user.candidaturas
                                      .filter((el) => el.id == id)
                                      .map((item) =>
                                        moment(item.data).format("DD MMM YYYY")
                                      )}
                                  </td>
                                  <td headers="status">
                                    {user?.candidaturas
                                      ?.filter((item) => item.id === id)
                                      .map(({ status }) =>
                                        status === "analise" ? (
                                          <div className="status status--progress ">
                                            <span>EM ANÁLISE</span>{" "}
                                            {/* Anunciante ainda não aceitou */}
                                          </div>
                                        ) : status === "aceito" ? (
                                          <div className="status status--success ">
                                            <span>Aceito</span>{" "}
                                            {/* Anunciante aceitou candidato e irá analisar e possivelmente entrar em contato */}
                                          </div>
                                        ) : status === "rejeitado" ? (
                                          <div className="status status--moved ">
                                            <span>Rejeitado</span>{" "}
                                            {/* Anunciante rejeitou candidato */}
                                          </div>
                                        ) : status === "fechado" ? (
                                          <div className="status status--remove ">
                                            <span>Fechado</span>{" "}
                                            {/* Vaga fechada ou excluída */}
                                          </div>
                                        ) : (
                                          ""
                                        )
                                      )}
                                  </td>
                                </tr>
                                <tr
                                  className={`${
                                    activatedTable === id ? "active" : ""
                                  } expansivel`}
                                >
                                  <td colSpan="4">
                                    <div></div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            )
                          )}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <div className="row">
                    <div className="painel__vazio">
                      <h5>Não encontramos candidaturas</h5>
                      <p>Você já se candidatou a uma vaga?</p>
                      <Link
                        className="btn btn-vagas btn-vagas__primary small"
                        to="/vagas/"
                      >
                        Achar oportunidades
                      </Link>
                    </div>
                  </div>
                )}
              </>
            )}
            <div className="row">
              <div className="col-md-6 mt-4">
                <div className="painel__perfil__box segundario">
                  <div className="col-3 me-2">
                    <div className="painel__perfil__box__foto"></div>
                  </div>
                  <div className="col-9">
                    <div className="painel__perfil__box__info">
                      <div>
                        <h5>Rodrigo Angeli</h5>
                        <p>Cirurgião</p>
                      </div>
                      <div>
                        <div className="p3 d-flex align-items-center mt-4 mt-md-0">
                          <Local />
                          Porto Alegre, RS
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="painel__perfil__box">
                  <div className="painel__perfil__box__info text-center">
                    <h5>Preenchimento do Perfil</h5>
                    <div className="p3">
                      Completando seu perfil aumentam as chances de você ser
                      visto pelos empregadores e ser contratado!
                    </div>
                    <span>
                      <span></span>
                    </span>

                    <div className="p3">
                      <b className=" color-secondary">
                        Seu perfil está em {13}%
                      </b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-4">
                <div className="painel__curriculo">ds</div>
              </div>
              <div className="col-12 mt-4">
                <div className="painel__curriculo">ds</div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="painel__dicas pt-5 px-3">
              <div className="titulo mb-2">
                <h5>Dicas para você</h5>
              </div>
              <div className="painel__dicas__box mb-3">
                <div className="d-flex">
                  <Medalha />
                  <div className="p2 ms-3">Preencha perfil</div>
                </div>
                <p>
                  Sem qualquer custo, da criação do seu currículo até a
                  contratação.
                </p>
              </div>
              <div className="painel__dicas__box mb-3">
                <div className="d-flex">
                  <Medalha />
                  <div className="p2 ms-3">Preencha perfil</div>
                </div>
                <p>
                  Sem qualquer custo, da criação do seu currículo até a
                  contratação.
                </p>
              </div>
              <div className="painel__dicas__box mb-3">
                <div className="d-flex">
                  <Medalha />
                  <div className="p2 ms-3">Preencha perfil</div>
                </div>
                <p>
                  Sem qualquer custo, da criação do seu currículo até a
                  contratação.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
