import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import api from "../api";
import { UserContext } from "../context/UserContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { ReactComponent as LocationPin } from "../img/icons/location-point.svg";
import { ReactComponent as Experiencia } from "../img/icons/star.svg";
import { ReactComponent as Escolaridade } from "../img/icons/graduation-cap.svg";
import { ReactComponent as Contrato } from "../img/icons/file-contract.svg";
import { ReactComponent as FaixaSalarial } from "../img/icons/money-bill.svg";
import { ReactComponent as Tempo } from "../img/icons/hourglass.svg";

import { ReactComponent as Share } from "../img/icons/share-alt.svg";
import { ReactComponent as Check } from "../img/icons/check-circle.svg";
import { ReactComponent as Proximo } from "../img/icons/angle-right.svg";
import { ReactComponent as Anterior } from "../img/icons/angle-left.svg";

import { ReactComponent as Lista } from "../img/icons/arrow-left.svg";
import { cidadeNomePorID } from "../utils/encontrarCidade";
import { estadoUFPorID, estadoNomePorID } from "../utils/encontrarEstado";
import { makeUrlFriendly } from "../utils/makeUrlFriendly";
import Loading from "../components/Loading";

const Topico = ({ full, titulo, valor, icone, col }) => {
  return (
    <div className={`${full ? "col-12" : `col-lg-${col ? col : "4"}`} mb-2`}>
      <div className="vagas__corpo__info__topico">
        {icone}
        <div>
          <div className="vagas__corpo__info__topico__titulo">{titulo}</div>
          {valor}
        </div>
      </div>
    </div>
  );
};
const VagaFull = ({
  contratante,
  tituloVaga,
  local,
  regimeContrato,
  descricao,
  dataAtualizacao,
  tempoExperiencia,
  escolaridade,
  diferenciais,
  tempo,
  salarioAcombinar,
  faixaSalarialAte,
  faixaSalarialDe,
  valeAlimentacao,
  valeRefeicao,
  valeTransporte,
  cidadeID,
  estadoID,
  bairro,
  proprio,
  numero,
  endereco,
  id,
  state,
  status,
  noSideBar,
  cep,
}) => {
  const { user, updateContext } = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [isCandidato, setIsCandidato] = useState(false);
  const [currIndex, setCurrIndex] = useState(null);

  useEffect(() => {
    if (state?.listaIDS?.length > 0) {
      setCurrIndex(state?.listaIDS.map(({ id }) => id).indexOf(parseFloat(id)));
    }
    if (user?.candidaturas?.some((item) => item.vaga_id === parseFloat(id))) {
      setIsCandidato(true);
    } else {
      setIsCandidato(false);
    }
  }, [id]);

  useEffect(() => {
    window.addEventListener("scroll", stickSidebar);

    return () => {
      window.removeEventListener("scroll", stickSidebar);
    };
  }, []);

  const stickSidebar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 250 ? setShowButtons(true) : setShowButtons(false);
    }
  };

  const encontrarNav = (dir) => {
    var resultado =
      state?.listaIDS[dir === "next" ? currIndex + 1 : currIndex - 1];
    return resultado ? `${resultado.id}/${resultado.url}` : null;
  };

  const candidatarse = () => {
    if (user.valido) {
      if (proprio) {
        setLoading(true);
        api
          .get(`/curriculo/pesquisar`, {
            params: {
              token: user?.tokenID,
              curriculoID: user?.userInfo?.id,
            },
          })
          .then((res) => {
            if (res.status === 200) {
              var data = new FormData();
              data.append("token", user.tokenID);
              api({
                method: "post",
                url: `/Candidaturas/registrar?anuncioID=${id}`,
                data: data,
                headers: { "Content-Type": "multipart/form-data" },
              })
                .then((res) => {
                  if (res.status === 200) {
                    setLoading(false);
                    setIsCandidato(true);
                    console.log(res.data);
                    updateContext({
                      toast: {
                        title: "Parabéns!",
                        message: "Você se candidatou a essa vaga.",
                        link: "/candidato/candidaturas",
                        linkMessage: "Acompanhe no seu painel",
                        status: "Success",
                      },
                      candidaturas: [
                        ...user.candidaturas,
                        res.data.candidatura,
                      ],
                    });
                  }
                })
                .catch((err) => {
                  if (err.response) {
                    setLoading(false);
                    updateContext({
                      toast: {
                        title: err.response.data,
                        status: "Error",
                      },
                    });
                  }
                });
            }
          })
          .catch((err) => {
            setLoading(false);
            if (err.response) {
              if (err.response?.data?.curriculo?.id === 0) {
                navigate(
                  `/criar-curriculo?redirect=/oportunidade/${id}/${makeUrlFriendly(
                    tituloVaga +
                      "-" +
                      cidadeNomePorID(cidadeID) +
                      "-" +
                      estadoNomePorID(estadoID)
                  )}`
                );
              }
            }
          });
      } else {
        window.open(
          `https://www.catho.com.br/vagas/${makeUrlFriendly(
            tituloVaga
          )}/${makeUrlFriendly(
            `${cidadeNomePorID(cidadeID)} - ${estadoUFPorID(estadoID)}`
          )}/`,
          "_blank"
        );
      }
    } else {
      if (proprio) {
        navigate(
          `/criar-curriculo?redirect=/oportunidade/${id}/${makeUrlFriendly(
            tituloVaga +
              "-" +
              cidadeNomePorID(cidadeID) +
              "-" +
              estadoNomePorID(estadoID)
          )}`
        );
      } else {
        navigate(
          `/criar-curriculo?proprio=false&redirect=https://www.catho.com.br/vagas/${makeUrlFriendly(
            tituloVaga
          )}/${makeUrlFriendly(
            `${cidadeNomePorID(cidadeID)} - ${estadoUFPorID(estadoID)}`
          )}/`
        );
      }
    }
  };

  return (
    <div className="vagas singular">
      {!noSideBar && (
        <div className="vagas__left">
          <div className="vagas__left__conteudo">
            <div className={`vagas__sidebar`}>
              <b>{tituloVaga}</b>
              <p>{contratante}</p>
              <p className="mt-1">{local?.cidUF}</p>
              {showButtons && status !== "Fechada" && (
                <>
                  {" "}
                  {!isCandidato ? (
                    <button
                      onClick={loading ? undefined : candidatarse}
                      className="btn-vagas btn-vagas__primary w-100 mt-2"
                    >
                      {loading ? (
                        <Loading
                          className={"white"}
                          height={"25px"}
                          width={"25px"}
                        />
                      ) : (
                        "Candidatar-se"
                      )}
                    </button>
                  ) : (
                    <b className="me-3 color-primary d-flex align-items-center justify-content-center mt-2">
                      <Check className="me-2" /> Candidatado
                    </b>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={`${!noSideBar ? "vagas__right" : ""}`}>
        <div className={`${!noSideBar ? "vagas__right__conteudo" : ""}`}>
          {!noSideBar && state && (
            <div className="vagas__header">
              <div className="vagas__header__nav">
                <div>
                  {state?.paginaAnterior && (
                    <Link to={state?.paginaAnterior}>
                      <Lista /> Lista
                    </Link>
                  )}
                </div>
                <div className="vagas__header__nav--dir">
                  {currIndex &&
                  state?.listaIDS?.length > 0 &&
                  encontrarNav("prev") ? (
                    <Link
                      state={state}
                      to={`/oportunidade/${encontrarNav("prev")}`}
                    >
                      <Anterior className="me-1" />
                      Anterior
                    </Link>
                  ) : state?.pagina?.pre_page ? (
                    <Link
                      state={state}
                      to={`${state?.paginaAnterior}?Page=${state?.pagina?.pre_page}`}
                    >
                      <Anterior className="me-1" />
                      Página anterior
                    </Link>
                  ) : (
                    ""
                  )}
                  <span>
                    <span></span>
                  </span>
                  {state?.listaIDS?.length > 0 && encontrarNav("next") ? (
                    <Link
                      state={state}
                      to={`/oportunidade/${encontrarNav("next")}`}
                    >
                      Próximo <Proximo className="ms-1" />
                    </Link>
                  ) : state?.pagina?.next_page ? (
                    <Link
                      state={state}
                      to={`${state?.paginaAnterior}?Page=${state?.pagina?.next_page}`}
                    >
                      Próxima página <Proximo className="ms-1" />
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          )}
          <hr />
          <div className="vagas__corpo">
            <h1>{tituloVaga}</h1>
            <div className="p3">Anunciado por</div>
            <h6>{contratante}</h6>
            <div className="vagas__corpo__botoes">
              {!isCandidato ? (
                <button
                  onClick={candidatarse}
                  className="btn-vagas btn-vagas__primary me-3"
                >
                  Candidatar-se
                </button>
              ) : (
                <b className="me-3 color-primary d-flex align-items-center">
                  <Check /> Candidatado
                </b>
              )}
              {navigator?.share ? (
                <button
                  onClick={() =>
                    navigator.share({
                      title: tituloVaga,
                      url: `https://vagas.verbomed.com.br${window.location.pathname}`,
                    })
                  }
                  className="btn-vagas btn-vagas__text__primary medium"
                >
                  <Share />
                  Compartilhar
                </button>
              ) : (
                ""
              )}
            </div>
            <div className="vagas__corpo__info pb-5">
              <div className="titulo mb-2">
                <h5>Descrição da Vaga</h5>
              </div>
              <ul dangerouslySetInnerHTML={{ __html: descricao }}></ul>
              {tempoExperiencia || escolaridade ? (
                <>
                  <hr />
                  <div className="titulo mb-2">
                    <h5>Requisitos</h5>
                  </div>
                  <div className="row gx-2">
                    {tempoExperiencia ? (
                      <Topico
                        titulo={"Experiência"}
                        valor={tempoExperiencia}
                        icone={<Experiencia />}
                      />
                    ) : (
                      ""
                    )}
                    {escolaridade ? (
                      <Topico
                        titulo={"Escolaridade"}
                        valor={escolaridade}
                        icone={<Escolaridade />}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              {diferenciais ? (
                <>
                  {" "}
                  <hr />
                  <div className="titulo mb-2">
                    <h5>Diferenciais</h5>
                  </div>
                  <ul dangerouslySetInnerHTML={{ __html: diferenciais }}></ul>
                </>
              ) : (
                ""
              )}
              {tempo ||
              regimeContrato ||
              salarioAcombinar ||
              faixaSalarialDe ||
              faixaSalarialAte ? (
                <>
                  <hr />

                  <div className="titulo mb-2">
                    <h5>Detalhes da Vaga</h5>
                  </div>
                  <div className="row gx-2">
                    {tempo ? (
                      <Topico
                        col={"6"}
                        titulo={"Tempo"}
                        valor={tempo}
                        icone={<Tempo />}
                      />
                    ) : (
                      ""
                    )}
                    {regimeContrato ? (
                      <Topico
                        titulo={"Contrato"}
                        valor={regimeContrato}
                        icone={<Contrato />}
                        col={"6"}
                      />
                    ) : (
                      ""
                    )}
                    {salarioAcombinar || faixaSalarialAte || faixaSalarialDe ? (
                      <Topico
                        titulo={"Faixa Salarial"}
                        col={"12"}
                        valor={
                          salarioAcombinar
                            ? `À combinar`
                            : `De ${(faixaSalarialDe + 0.0).toLocaleString(
                                "pt-br",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )} até ${(faixaSalarialAte + 0.0).toLocaleString(
                                "pt-br",
                                {
                                  style: "currency",
                                  currency: "BRL",
                                }
                              )}`
                        }
                        icone={<FaixaSalarial />}
                      />
                    ) : (
                      ""
                    )}
                    {proprio ? (
                      valeAlimentacao || valeRefeicao || valeTransporte ? (
                        <Topico
                          full
                          titulo={"Benefícios Oferecidos"}
                          valor={
                            <ul>
                              {valeAlimentacao && <li>Vale Alimentação</li>}
                              {valeRefeicao && <li>Vale Refeição</li>}
                              {valeTransporte && <li>Vale Transporte</li>}
                            </ul>
                          }
                          icone={<Share />}
                        />
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}

              {cep || estadoID || cidadeID || bairro || numero ? (
                <>
                  <hr />
                  <div className="titulo mb-2">
                    <h5>Onde você trabalhará</h5>
                  </div>
                  <p className="mb-2">
                    <p>
                      {endereco}, {numero}
                    </p>
                    <b>
                      {bairro}, {cidadeNomePorID(cidadeID)} -{" "}
                      {estadoUFPorID(estadoID)}
                    </b>
                  </p>
                  <iframe
                    src={`https://www.google.com.br/maps?q=${cep},%20Brasil&output=embed
`}
                  ></iframe>{" "}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <hr />
          {!noSideBar && state && (
            <div className="vagas__header__nav">
              <div>
                {state?.paginaAnterior && (
                  <Link to={state?.paginaAnterior}>
                    <Lista /> Voltar aos resultados
                  </Link>
                )}
              </div>
              <div className="vagas__header__nav--dir">
                {currIndex &&
                state?.listaIDS?.length > 0 &&
                encontrarNav("prev") ? (
                  <Link
                    state={state}
                    to={`/oportunidade/${encontrarNav("prev")}`}
                  >
                    <Anterior className="me-1" />
                    Anterior
                  </Link>
                ) : state?.pagina?.pre_page ? (
                  <Link
                    state={state}
                    to={`${state?.paginaAnterior}?page=${state?.pagina?.pre_page}`}
                  >
                    <Anterior className="me-1" />
                    Página anterior
                  </Link>
                ) : (
                  ""
                )}
                <span>
                  <span></span>
                </span>
                {state?.listaIDS?.length > 0 && encontrarNav("next") ? (
                  <Link
                    state={state}
                    to={`/oportunidade/${encontrarNav("next")}`}
                  >
                    Próximo <Proximo className="ms-1" />
                  </Link>
                ) : state?.pagina?.next_page ? (
                  <Link
                    state={state}
                    to={`${state?.paginaAnterior}?page=${state?.pagina?.next_page}`}
                  >
                    Próxima página <Proximo className="ms-1" />
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VagaFull;
