import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const defaultSEO = {
  title: "Vagas na área de saúde em todo o Brasil · VMED Oportunidades",
  nome: "VMED Oportunidades",
  description:
    "Vagas na área de saúde em todo o Brasil. Encontre vagas perto de você. Contratando? Anuncie gratuitamente a sua vaga para os melhores candidatos do mercado!",
};

const Seo = ({ seo }) => {
  const location = useLocation();

  useEffect(() => {
    window.gtag("config", "G-KD0R229H7J", {
      page_title:
        seo && seo.title
          ? `${seo.title} · ${defaultSEO.nome}`
          : defaultSEO.title,
      page_path: `${location.pathname}${location.search}`,
    });
  }, [location]);
  const pageTitle =
    seo && seo.title ? `${seo.title} · ${defaultSEO.nome}` : defaultSEO.title;

  const pageDescription =
    seo && seo.description ? seo.description : defaultSEO.description;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>

        <meta name="description" content={pageDescription} />
        <meta name="language" content="pt-BR" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="pt_BR" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:site_name" content="" />
        <meta property="og:image" content="" />
        <meta property="og:image:secure_url" content="" />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:image" content="" />
      </Helmet>
    </>
  );
};

export default Seo;
