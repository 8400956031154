import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import api from "../../../api";
import Layout from "../../../components/estrutura/principal/Layout";
import BuscarVaga from "../../../components/BuscarVaga/";
import BuscarVagaMobile from "../../../components/BuscarVaga/Mobile";
import { UserContext } from "../../../context/UserContext";
import BgDesk from "../../../img/home-background-desk.jpg";
import BgMobile from "../../../img/home-background-mobile.jpg";
import BgCadastreCurriculo from "../../../img/home-cadastre-seu-curriculo.jpg";
import BgInicieRecrutamento from "../../../img/home-inicie-recrutamento.jpg";
import BgChamadaFinal from "../../../img/home-chamada-final.jpg";
import VagaCard from "../../../components/VagaCard";
import moment from "moment";
import "moment/locale/pt-br";
import {
  cidadeNomePorID,
  cidadeIDPorNome,
} from "../../../utils/encontrarCidade";
import { estadoNomePorID } from "../../../utils/encontrarEstado";
import { PHCardsHome } from "../../../components/placeholder/PlaceholderLista";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { makeUrlFriendly } from "../../../utils/makeUrlFriendly";
import { ReactComponent as Vagas } from "../../../img/icons/briefcase-alt.svg";
import { ReactComponent as Perfil } from "../../../img/icons/user-circle.svg";
import { ReactComponent as Curriculo } from "../../../img/icons/notes.svg";
import { ReactComponent as Medalha } from "../../../img/icons/award.svg";
import { ReactComponent as Buscar } from "../../../img/icons/search-alt.svg";
import { ReactComponent as Flecha } from "../../../img/icons/arrow-right.svg";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

const vagasPorArea = [
  "Neurocirurgião",
  "Cirurgião Geral",
  "Dermatologista",
  "Ortopedista",
  "Anestesiologista",
  "Cardiologista",
  "Cirurgião Plástico",
  "Infectologista",
  "Clínica Médica",
];

const vagasPorEstado = [
  "São Paulo, SP",
  "Curitiba, PR",
  "Florianópolis, SC",
  "Brasília, DF",
  "Porto Alegre, RS",
  "Belo Horizonte, MG",
  "Vitória, ES",
  "Rio de Janeiro, RJ",
  "Campo Grande, MS",
];

const IndexPage = (props) => {
  const { height, width } = useWindowDimensions();
  const { updateContext, user } = useContext(UserContext);
  const [modal, setModal] = useState(false);

  const [recomendacoes, setRecomendacoes] = useState([]);

  useEffect(() => {
    api
      .get(`/vagas/pesquisarFiltros?RowsPage=4&Page=1`)
      .then(({ data }) => {
        setRecomendacoes(data.paginacao.data);
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
      })
      .finally(() => {});
  }, []);

  return (
    <>
      <Layout modalAberto={modal} menuBusca="home">
        <div
          className="home__banner"
          style={{ backgroundImage: `url(${isMobile ? BgMobile : BgDesk})` }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1>Vagas na área de saúde em todo o Brasil</h1>
                <h5>Encontre a oportunidade ideal para você</h5>
              </div>
              <div className="col-lg-8 mt-3">
                <BrowserView>
                  <BuscarVaga card className="card" />
                </BrowserView>
                <MobileView>
                  <Link
                    to="/vagas/"
                    className="btn-vagas btn-vagas__primary w-100"
                  >
                    Encontre oportunidades
                  </Link>
                </MobileView>
                <p>
                  Você tem um negócio?{" "}
                  <Link to="/anunciar-vaga">Anuncie uma vaga</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="pt-5">
          <div className="container">
            <div className="titulo">
              <h4>Vagas mais recentes para você</h4>
              <p>Confira as últimas oportunidades que foram criadas!</p>
            </div>
            <div className="row">
              {recomendacoes?.length > 0 ? (
                recomendacoes
                  .slice(0, 4)
                  .map(
                    ({
                      id,
                      contratante,
                      tituloVaga,
                      estadoID,
                      cidadeID,
                      dataAtualizacao,
                      regimeContrato,
                      descricao,
                    }) => (
                      <VagaCard
                        key={id}
                        className={"col-md-4 col-lg-3"}
                        regimeContrato={regimeContrato}
                        contratante={contratante}
                        tituloVaga={tituloVaga}
                        estadoID={estadoID}
                        cidadeID={cidadeID}
                        regimeContrato={regimeContrato}
                        descricao={descricao}
                        dataAtualizacao={moment(dataAtualizacao).fromNow()}
                        link={`/oportunidade/${id}/${makeUrlFriendly(
                          tituloVaga +
                            "-" +
                            cidadeNomePorID(cidadeID) +
                            "-" +
                            estadoNomePorID(estadoID)
                        )}/`}
                      />
                    )
                  )
              ) : (
                <PHCardsHome windowWidth={width} />
              )}
            </div>
            <Link
              className="btn-vagas btn-vagas__text__primary small d-inline-flex p-0"
              to={`/vagas/`}
            >
              Ver todas as vagas <Flecha />
            </Link>
          </div>
        </section>
        <section className="small">
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-2">
                <div className="titulo mb-1">
                  <h4>Vagas por especialidades</h4>
                </div>
                <ul className="home__tags">
                  {vagasPorArea.map((el) => (
                    <li key={el}>
                      <Link to={`/vagas/?TituloVaga=${el}`}>
                        <Buscar /> {el}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-md-6 mb-2">
                <div className="titulo mb-1">
                  <h4>Vagas por cidades</h4>
                </div>
                <ul className="home__tags">
                  {vagasPorEstado.map((el) => (
                    <li key={el}>
                      <Link
                        state={{
                          local: {
                            label: el,
                            value: cidadeIDPorNome(el.split(",")[0]),
                          },
                        }}
                        to={`/vagas/${makeUrlFriendly(el)}/`}
                      >
                        <Buscar /> {el}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-6 mb-1">
                <div
                  className="home__chamada"
                  style={{ backgroundImage: `url(${BgCadastreCurriculo})` }}
                >
                  <h4>Cadastre seu currículo gratuitamente</h4>
                  <p>
                    De uma maneira rápida e fácil te ajudamos a encontrar as{" "}
                    <b>melhores vagas</b> do mercado!
                  </p>
                  <Link
                    className="btn-vagas btn-vagas__white"
                    to="/criar-curriculo"
                  >
                    Cadastrar currículo
                  </Link>
                </div>
              </div>
              <div className="col-md-6 mb-1">
                <div
                  className="home__chamada"
                  style={{ backgroundImage: `url(${BgInicieRecrutamento})` }}
                >
                  <h4>Inicie seu recrutamento imediatamente!</h4>
                  <p>
                    Quer contratar? Cadastre a sua vaga e as preencha
                    rapidamente.
                  </p>
                  <Link
                    className="btn-vagas btn-vagas__white"
                    to="/anunciar-vaga"
                  >
                    Cadastrar vaga
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="home__diferenciais">
          <div className="container">
            <div className="row justify-content-around">
              <div className="col-md-4">
                <div className="titulo">
                  <h3>
                    A prática cotidiana prova que o entendimento das metas{" "}
                  </h3>
                  <p>
                    No entanto, não podemos esquecer que a consolidação das
                    estruturas estende o alcance e a importância das novas
                    proposições.
                  </p>
                </div>
              </div>
              <div className="offset-md-1 col-md-7 ">
                <div className="row">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <div className="home__diferenciais__box">
                      <Medalha />
                      <div className="p2">Totalmente gratuito</div>
                      <p>
                        Sem qualquer custo, da criação do seu currículo até a
                        contratação.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 mb-md-0 mt-md-4">
                    <div className="home__diferenciais__box">
                      <Perfil />
                      <div className="p2">Seu perfil próprio</div>
                      <p>
                        Tenha um próprio perfil para se divulgar, claro, se você
                        permitir.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 mb-md-0">
                    <div className="home__diferenciais__box">
                      <Curriculo />
                      <div className="p2">Currículo gratuito</div>
                      <p>
                        Recrutadores gastam em média 30 segundos olhando
                        currículos, crie conosco o seu melhor.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 mt-md-4">
                    <div className="home__diferenciais__box">
                      <Vagas />
                      <div className="p2">As vagas encontram você</div>
                      <p>
                        Preencha seu perfil uma vez para que os recrutadores o
                        encontrem rapidamente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="comoFunciona">
          <div className="container">
            <div className="titulo text-center">
              <h3>Como funciona</h3>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-3 mb-2">
                <div className="home__comoFunciona">
                  <div className="over mb-1">etapa 1</div>
                  <h5>Encontre vagas para você</h5>
                  <p>
                    As melhores empresas que estão bem pertinho de você, faça a
                    busca e confira!
                  </p>
                </div>
              </div>

              <div className="col-md-3 mb-2">
                <div className="home__comoFunciona">
                  <div className="over mb-1">etapa 2</div>
                  <h5>Crie ou edite seu currículo</h5>
                  <p>
                    Cocê só precisará criar o seu currículo para o recrutador
                    entrar em contato com você.
                  </p>
                </div>
              </div>

              <div className="col-md-3 mb-2">
                <div className="home__comoFunciona">
                  <div className="over mb-1">etapa 3</div>
                  <h5>Candidate-se e acompanhe</h5>
                  <p>
                    Basta apenas esperar o feedback do recrutador para você
                    conseguir a vaga ideal.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="home__chamadaFinal"
          style={{ backgroundImage: `url(${BgChamadaFinal})` }}
        >
          <div className="container">
            <div className="row text-left">
              <div className="col-md-5">
                <h3>Ainda não encontrou a vaga perfeita para você?</h3>
                <p>
                  Clique abaixo e encontre as melhores oportunidades na área da
                  saúde bem próximas de você.
                </p>
                <Link to="/vagas/" className="btn-vagas btn-vagas__primary">
                  Encontrar vagas
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default IndexPage;
