import React, { useState, useEffect } from "react";

const TipoContrato = ({ onSelect, value }) => {
  const [opcaoEscolhida, setOpcaoEscolhida] = useState([
    { label: "CLT (Efetivo)", value: "CLT (Efetivo)", active: false },
    {
      label: "Prestador de Serviços (PJ)",
      value: "Prestador de Serviços (PJ)",
      active: false,
    },
    { label: "Temporário", value: "Temporário", active: false },
    { label: "Autônomo", value: "Autônomo", active: false },
  ]);

  const selecionarValor = (el) => {
    var newOpcaoEscolhida = [...opcaoEscolhida].map((item) => {
      if (item.value === el.value) return { ...item, active: true };
      return {
        ...item,
        active: false,
      };
    });
    onSelect("RegimeContrato", el.value);
    setOpcaoEscolhida(newOpcaoEscolhida);
  };

  return (
    <>
      <div className="pt-2 pb-4">
        <div className="d-flex flex-wrap justify-content-center">
          {opcaoEscolhida.map((el) => (
            <div
              key={`chip_${el.value}`}
              className={`chip ${el.active ? "active" : ""}`}
              onClick={() => selecionarValor(el)}
            >
              {el.label}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TipoContrato;
