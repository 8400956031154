import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { ReactComponent as Search } from "../../../img/icons/search-alt.svg";
import { ReactComponent as ArrowRight } from "../../../img/icons/arrow-right.svg";
import { ReactComponent as Fechar } from "../../../img/icons/times.svg";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { makeUrlFriendly } from "../../../utils/makeUrlFriendly";

import api from "../../../api";
import { isMobile } from "react-device-detect";
import qs from "query-string";

import BuscarVagaLocal from "../Local";
import BuscarVagaEspecialidade from "../Especialidade";

const BuscarVagaMobile = ({ fecharModal, card, onMenu }) => {
  const { updateContext } = useContext(UserContext);

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    local: "",
    posicao: "",
  });

  const buscarVaga = () => {
    navigate(
      `/vagas/${
        inputs.local?.label ? makeUrlFriendly(inputs.local.label) : ""
      }${inputs.posicao?.label ? `/?TituloVaga=${inputs.posicao.label}` : ""}`,
      { state: { local: inputs.local, posicao: inputs.posicao } }
    );
  };

  return (
    <>
      <div className="buscar__mobile__nav">
        <div className="mb-3">
          <BuscarVagaEspecialidade
            mobile
            enviarPai={(input) => setInputs({ ...inputs, posicao: input })}
          />
        </div>
        <div className="mb-3">
          <BuscarVagaLocal
            mobile
            enviarPai={(input) => setInputs({ ...inputs, local: input })}
          />
        </div>
        <button
          className="btn-vagas btn-vagas__primary w-100"
          onClick={() => {
            buscarVaga();
            fecharModal();
          }}
          type="button"
        >
          Buscar <ArrowRight />
        </button>
      </div>
    </>
  );
};

export default BuscarVagaMobile;
