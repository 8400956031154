import React, { useState, useEffect } from "react";
import InputTextArea from "../../../../components/InputTextArea";
import InputSelect from "../../../../components/InputSelect";
import InputCheck from "../../../../components/InputCheck";
import { validarState } from "../../../../utils/validarForm";
import CreatableSelect from "react-select/creatable";
import { ReactComponent as Delete } from "../../../../img/icons/times.svg";
import { ReactComponent as ArrowRight } from "../../../../img/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../../../img/icons/arrow-left.svg";
import listaEspecialidades from "../../../../data/especialidades.json";

const Passo3 = ({
  tempoExperiencia,
  escolaridade,
  diferenciais,
  proximoPasso,
  passoAnterior,
  enviarPai,
}) => {
  const [anuncioBasico, setAnuncioBasico] = useState({
    tempoExperiencia: tempoExperiencia || "",
    escolaridade: escolaridade || "",
    diferenciais: diferenciais || "",
  });
  const [formErrors, setFormError] = useState({});

  const validarCampos = () => {
    var obj = validarState(JSON.parse(JSON.stringify(anuncioBasico)));
    if (!obj.status) {
      setFormError(obj.state);
    } else {
      proximoPasso(anuncioBasico);
    }
  };

  useEffect(() => {
    if (enviarPai) {
      enviarPai(anuncioBasico);
    }
  }, [anuncioBasico]);

  return (
    <>
      <h6 className="mb-0 font-600 color-secondary">Requisitos</h6>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <InputSelect
            id="tempoExperiencia"
            placeholder="Mínimo de experiência"
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                tempoExperiencia: event.label,
              });
            }}
            value={
              tempoExperiencia
                ? { value: tempoExperiencia, label: tempoExperiencia }
                : null
            }
            options={[
              { label: "Sem preferência", value: "Sem preferência" },
              { label: "Menos de 1 ano", value: "Menos de 1 ano" },
              { label: "Mais de 1 ano", value: "Mais de 1 ano" },
              { label: "Mais de 2 ano", value: "Mais de 2 ano" },
              { label: "Mais de 3 ano", value: "Mais de 3 ano" },
              { label: "Mais de 4 ano", value: "Mais de 4 ano" },
              { label: "Mais de 5 ano", value: "Mais de 5 ano" },
              { label: "Mais de 8 anos", value: "Mais de 8 anos" },
            ]}
            error={formErrors?.tempoExperiencia}
            required
          />
        </div>
        <div className="col-md-6">
          <InputSelect
            id="escolaridade"
            placeholder="Escolaridade mínima"
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                escolaridade: event.label,
              });
            }}
            value={
              escolaridade ? { value: escolaridade, label: escolaridade } : null
            }
            options={[
              { label: "Sem preferência", value: "Sem preferência" },
              {
                label: "Ensino Médio (2° Grau)",
                value: "Ensino Médio (2° Grau)",
              },
              { label: "Curso Técnico", value: "Curso Técnico" },
              { label: "Ensino Superior", value: "Ensino Superior" },
              {
                label: "Pós-graduação - Especialização/MBA",
                value: "Pós-graduação - Especialização/MBA",
              },
              {
                label: "Pós-graduação - Mestrado",
                value: "Pós-graduação - Mestrado",
              },
              {
                label: "Pós-graduação - Doutorado",
                value: "Pós-graduação - Doutorado",
              },
            ]}
            error={formErrors?.escolaridade}
            required
          />
        </div>
      </div>
      <hr />
      <h6 className="mb-0 font-600 color-secondary">Desejáveis</h6>
      <div className="row">
        <div className="col-12">
          <InputTextArea
            id="diferenciais"
            placeholder="Diga ao candidado o que seria um diferencial para essa vaga"
            type="text"
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                diferenciais: event.target.value,
              });
            }}
            rows="5"
            value={anuncioBasico.diferenciais}
            className="w-100 mt-2"
            error={formErrors?.diferenciais}
            required
          />
        </div>
      </div>
      <hr />
      {proximoPasso || passoAnterior ? (
        <div className="row">
          <div className="col-12">
            <div className="flow__buttons">
              <button
                onClick={passoAnterior}
                className="btn-vagas btn-vagas__ghost__secondary"
              >
                <ArrowLeft className="me-1" /> Voltar
              </button>
              <button
                onClick={validarCampos}
                className="btn-vagas btn-vagas__primary"
              >
                Próximo passo <ArrowRight className="ms-1" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Passo3;
