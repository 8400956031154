import React, { useState, useContext, useEffect } from "react";
import InputText from "../../../../components/InputText";
import InputSelect from "../../../../components/InputSelect";
import InputCheck from "../../../../components/InputCheck";
import { UserContext } from "../../../../context/UserContext";
import { cidadeNomePorID } from "../../../../utils/encontrarCidade";
import { estadoNomePorID } from "../../../../utils/encontrarEstado";
import CreatableSelect from "react-select/creatable";
import { validarState } from "../../../../utils/validarForm";
import { ReactComponent as ArrowRight } from "../../../../img/icons/arrow-right.svg";
import cidadesBrasileiras from "../../../../data/brazilCitiesDataPopResult.json";
import listaEspecialidades from "../../../../data/especialidades.json";
import InputMask from "react-input-mask";
import api from "../../../../api";

const estados = [
  ...new Set(cidadesBrasileiras.map(({ state_name }) => state_name)),
].map((item, index) => ({ value: index + 1, label: item }));

const cidades = (estado) => {
  return cidadesBrasileiras
    .filter(({ state_name }) => state_name == estado)
    .map(({ id, name }) => ({ value: id, label: name }));
};

const Passo1 = ({
  nome,
  sobrenome,
  ddd,
  telefone,
  estadoID,
  cidadeID,
  telemedicina,
  especialidades,
  proximoPasso,
  enviarPai,
}) => {
  const { user, updateContext } = useContext(UserContext);
  const [curriculoBasico, setCurriculoBasico] = useState({
    nome: nome || "",
    sobrenome: sobrenome || "",
    ddd: ddd || "",
    telefone: telefone || "",
    estadoID: estadoID || "",
    cidadeID: cidadeID || "",
    telemedicina: telemedicina || false,
    especialidades: especialidades || [],
  });
  const [formErrors, setFormError] = useState({});

  const validarCampos = () => {
    var obj = validarState(JSON.parse(JSON.stringify(curriculoBasico)));
    if (!obj.status) {
      setFormError(obj.state);
    } else {
      api
        .post(`/curriculo/criar?token=${user.tokenID}`, {
          ...curriculoBasico,
          especialidades: curriculoBasico.especialidades.map(
            ({ value }) => value
          ),
          usuarioID: user.userInfo.id,
          lstEscolaridades: [],
          lstExperiencias: [],
        })
        .then((res) => {
          if (res.status === 200) {
            proximoPasso(curriculoBasico);
          }
        })
        .catch((err) => {
          updateContext({
            toast: {
              title: "Encontramos um erro",
              message: `${
                err.response.data.mensagens.length > 0
                  ? err.response.data.mensagens[0].txt
                  : "Tente novamente em minutos."
              }`,
              status: "Error",
            },
          });
        });
    }
  };

  useEffect(() => {
    if (enviarPai) {
      enviarPai(curriculoBasico);
    }
  }, [curriculoBasico]);

  return (
    <>
      <h6 className="mb-0 font-600 color-secondary">Perfil</h6>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <InputText
            id="nome"
            placeholder="Nome"
            type="text"
            onChange={(event) =>
              setCurriculoBasico({
                ...curriculoBasico,
                nome: event.target.value,
              })
            }
            value={curriculoBasico.nome}
            error={formErrors.nome}
            className="w-100 mt-2"
            required
          />
        </div>
        <div className="col-md-6">
          <InputText
            id="sobrenome"
            placeholder="Sobrenome"
            type="text"
            onChange={(event) =>
              setCurriculoBasico({
                ...curriculoBasico,
                sobrenome: event.target.value,
              })
            }
            value={curriculoBasico.sobrenome}
            error={formErrors.sobrenome}
            className="w-100 mt-2"
            required
          />
        </div>
        <div className="col-md-6">
          <InputSelect
            id="estado"
            placeholder="Em qual região deseja trabalhar?"
            onChange={(event) => {
              setCurriculoBasico({
                ...curriculoBasico,
                estadoID: event.value,
              });
            }}
            value={
              estadoID
                ? { value: estadoID, label: estadoNomePorID(estadoID) }
                : null
            }
            error={formErrors.estadoID}
            options={estados}
            required
          />
        </div>
        <div className="col-md-6">
          <InputSelect
            id="cidade"
            placeholder="E qual cidade?"
            options={
              curriculoBasico.estadoID &&
              cidades(
                estados.find(({ value }) => value === curriculoBasico.estadoID)
                  .label
              )
            }
            onChange={(event) => {
              setCurriculoBasico({
                ...curriculoBasico,
                cidadeID: event.value,
              });
            }}
            value={
              cidadeID
                ? { value: cidadeID, label: cidadeNomePorID(cidadeID) }
                : null
            }
            required
            isDisabled={curriculoBasico.estadoID ? false : true}
            error={formErrors.cidadeID}
          />
        </div>
        <div className="col-12">
          <InputCheck
            id="telemedicina"
            placeholder="Aceito trabalhar em telemedicina"
            defaultChecked={curriculoBasico.telemedicina}
            checked={curriculoBasico.telemedicina}
            onChange={(event) => {
              setCurriculoBasico({
                ...curriculoBasico,
                telemedicina: !curriculoBasico.telemedicina,
              });
            }}
          />
        </div>
      </div>
      <hr />
      <h6 className="mb-0 font-600 color-secondary">Especialidades</h6>
      <div className="row mt-2">
        <div className="col-12">
          <div className="input-float select-float">
            <CreatableSelect
              placeholder={"Selecione ou adicione as suas especialidades"}
              components={{
                IndicatorSeparator: () => null,
                ClearIndicator: () => null,
              }}
              isMulti
              value={
                curriculoBasico.especialidades
                  ? curriculoBasico.especialidades
                  : null
              }
              formatCreateLabel={(text) => `Adicionar ${text}`}
              onChange={(newValue) => {
                setCurriculoBasico({
                  ...curriculoBasico,
                  especialidades: newValue,
                });
              }}
              options={listaEspecialidades}
            />
          </div>
        </div>
      </div>
      <hr />
      <h6 className="mb-0 font-600 color-secondary">Dados para contato</h6>
      <div className="row">
        <div className="col-md-4">
          <InputMask
            mask="99"
            maskPlaceholder={null}
            onChange={(event) =>
              setCurriculoBasico({
                ...curriculoBasico,
                ddd: event.target.value,
              })
            }
            value={curriculoBasico.ddd}
          >
            <InputText
              id="ddd"
              placeholder="(DDD)"
              type="text"
              className="w-100 mt-2"
              error={formErrors.ddd}
              required
            />
          </InputMask>
        </div>
        <div className="col-md-8">
          <InputMask
            mask="99999-9999"
            maskPlaceholder={null}
            onChange={(event) =>
              setCurriculoBasico({
                ...curriculoBasico,
                telefone: event.target.value,
              })
            }
            value={curriculoBasico.telefone}
          >
            <InputText
              id="telefone"
              placeholder="Seu telefone"
              type="text"
              className="w-100 mt-2"
              error={formErrors.telefone}
              required
            />
          </InputMask>
        </div>
      </div>

      <hr className="my-4" />
      {proximoPasso ? (
        <div className="row">
          <div className="col-12">
            <div className="flow__buttons">
              <button
                onClick={validarCampos}
                className="btn-vagas btn-vagas__primary"
              >
                Próximo passo <ArrowRight className="ms-1" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Passo1;
