import React from "react";
import { ReactComponent as Minus } from "../img/icons/minus.svg";
import { ReactComponent as Plus } from "../img/icons/plus.svg";

const TextInput = (props) => {
  return (
    <>
      <div
        className={`input-float
      ${props.error ? "validate-error" : ""}
      ${props.iconLeft || props.iconRight ? "input-icon" : ""}
      ${props.iconLeft ? "left" : ""}
      ${props.iconRight ? "right" : ""}
      ${props.value && !props.preValue != "" ? "active" : ""}
      ${props.preValue ? "active" : ""}`}
      >
        <input
          placeholder={
            props.required && !props.maskRequired
              ? props.placeholder + "*"
              : props.placeholder
          }
          type={props.type ? props.type : "text"}
          name={props.id}
          onChange={props.onChange}
          className={
            props.error && props.error !== ""
              ? `${props.className} validate-error`
              : props.className
          }
          onKeyPress={props.onKeyPress}
          value={props.value}
          disabled={props.disabled}
          required={props.required}
          minLength={props.minLength}
          maxLength={props.maxLength}
          readOnly={props.readOnly}
          pattern={props.pattern}
          autoComplete={props.autocomplete}
          min={props.min}
          max={props.max}
        />
        {props.onVariableNum && (
          <div className="input-float__vary">
            <div
              className="input-float__vary__button  "
              onClick={() => props.onVariableNum("minus")}
            >
              <Minus />
            </div>
            <div
              className="input-float__vary__button  "
              onClick={() => props.onVariableNum("plus")}
            >
              <Plus />
            </div>
          </div>
        )}
        <label htmlFor={props.id}>
          {props.preValue ? props.preValue : props.placeholder}
          {props.required && !props.maskRequired ? (
            <span className="required">*</span>
          ) : (
            ""
          )}
        </label>

        {props.iconLeft && (
          <div
            onClick={props.iconLeft.onClick}
            className={`i left ${props.iconLeft.class} ${
              props.iconLeft.onClick && "c-pointer"
            }`}
          >
            {props.iconLeft.svg}
          </div>
        )}
        {props.iconRight && (
          <div
            onClick={props.iconRight.onClick}
            className={`i right ${props.iconRight.class} ${
              props.iconRight.onClick && "c-pointer"
            }`}
          >
            {props.iconRight.svg}
          </div>
        )}
      </div>
      {props.error !== "" && (
        <span className="error__message">{props.error}</span>
      )}
    </>
  );
};

export default TextInput;
