import React, { useEffect } from "react";
import Menu from "./Menu";
import Footer from "./Footer";
import Seo from "../SEO";
import api from "../../../api";

const Layout = ({
  menuBranco,
  menuBusca,
  seo,
  children,
  seoData,
  noHeader,
  noFooter,
  modalAberto,
}) => {
  return (
    <>
      <Seo seo={seoData} />
      {noHeader ? (
        ""
      ) : (
        <Menu
          modalAberto={modalAberto}
          menuBranco={menuBranco}
          menuBusca={menuBusca}
        />
      )}
      {children}
      {noFooter ? "" : <Footer />}
    </>
  );
};

export default Layout;
