import React, { useState, useEffect, useCallback, useRef } from "react";
import { ReactComponent as Close } from "../img/icons/multiply.svg";
const SidePanel = (props) => {
  const modalRef = useRef();
  const modalWrapperRef = useRef();
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleKeyDown = useCallback((event) => {
    const { key, keyCode } = event;
    console.log("teste", keyCode, key);
    if (keyCode === 27) {
      fadeOut(setTimeout(() => handleRemoveModal(), 250));
    }
  }, []);

  useEffect(() => {
    if (props.show) {
      document.body.classList.add("blocked");
    }
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      document.body.classList.remove("blocked");
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const fadeOut = (cb) => {
    setIsFadingOut(true);
  };
  const handleRemoveModal = () => {
    props.hide();
    setIsFadingOut(false);
  };

  const handleClick = (e) => {
    if (e.target === modalRef.current || e.target === modalWrapperRef.current) {
      fadeOut(setTimeout(() => handleRemoveModal(), 250));
    }
  };

  if (!props.show) {
    return null;
  }
  return (
    <>
      <div className={(isFadingOut ? "fadeout" : "") + " backdrop"}></div>
      <div
        ref={modalRef}
        className="modals sidePainel"
        onClick={(e) => handleClick(e)}
      >
        <div
          ref={modalWrapperRef}
          className={`modals__wrapper ${
            props.size ? `modals__wrapper__${props.size}` : ""
          }${props.align ? `modals__wrapper__${props.align}` : ""}`}
        >
          <div
            className={
              (isFadingOut ? "fadeout" : "") + " modals__wrapper__content "
            }
          >
            <div className={"modals__wrapper__content__title "}>
              {props.title}

              <button
                onClick={() =>
                  fadeOut(setTimeout(() => handleRemoveModal(), 250))
                }
                type="button"
                className="modals__wrapper__content__title__close"
              >
                <span aria-label="close">
                  <Close />
                </span>
              </button>
            </div>
            <div className="modals__wrapper__content__body">{props.body}</div>
            <div className="modals__wrapper__content__footer"></div>
            {props.btnCancelar || props.btnAcao ? (
              <div className="modals__wrapper__content__acoes">
                {props.btnCancelar && props.btnCancelar != "" ? (
                  <button
                    className="btn-vagas  btn-vagas__ghost__secondary"
                    onClick={() =>
                      fadeOut(
                        setTimeout(
                          () => handleRemoveModal(),
                          props.cancel(),
                          250
                        )
                      )
                    }
                  >
                    {props.btnCancelar}
                  </button>
                ) : (
                  ""
                )}

                <button
                  className={`btn-vagas btn-vagas__primary ${
                    !props.btnCancelar ? "w-100" : "ms-3 "
                  }`}
                  onClick={props.acao}
                >
                  {props.btnAcao}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePanel;
