function validateFields(obj) {
  return Object.keys(obj).forEach(function (key) {
    if (typeof obj[key] === "object") {
      Object.keys(obj[key]).forEach(function (nestedKey) {
        if (obj[key][nestedKey] === "") {
          obj[key][nestedKey] = "Campo obrigatório";
        } else {
          delete obj[key][nestedKey];
        }
      });
    } else {
      if (obj[key] === "") {
        obj[key] = "Campo obrigatório";
      } else {
        delete obj[key];
      }
    }
    if (typeof obj[key] === "object" && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  });
}

export const validarState = (state) => {
  var obj = state;
  validateFields(obj);
  if (Object.keys(obj).length === 0) return { status: true, state: null };
  return { status: false, state: obj };
};

export const validarCurriculoArr = (state) => {
  var newState = JSON.parse(JSON.stringify(state));
  if (newState.periodoAte.atualmente) {
    newState.periodoAte.mes = null;
    newState.periodoAte.ano = null;
  }
  var obj = validarState(newState);
  if (!obj.status) {
    return obj;
  } else {
    var newCurriculo = state;
    if (newCurriculo.periodoAte.atualmente) {
      newCurriculo.periodoAte.mes = null;
      newCurriculo.periodoAte.ano = null;
    }
    return { status: true, state: newCurriculo };
  }
};
