export const buscarStyle = () => {
  return {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#1c212d"
          : isFocused
          ? "#f8faff"
          : undefined,
        color: isSelected ? "white" : "#646879",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#01c4cb"
              : "rgba(25,196,203,.25)"
            : undefined,
        },
      };
    },
    control: (styles) => {
      return {
        ...styles,
        height: `100%`,
        border: `none`,
        boxShadow: `none`,
        borderRadius: `50px`,
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        cursor: `text`,
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginTop: `0`,
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        height: `100%`,
        display: `flex`,
        alignItems: `center`,
      };
    },
    /* input: () => ({
      height: `100%`,
    }), */
  };
};

export const buscarStyleMobile = () => {
  return {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        paddingTop: `15px`,
        paddingBottom: `15px`,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#1c212d"
          : isFocused
          ? "#f8faff"
          : undefined,
        color: isSelected ? "white" : "#646879",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#01c4cb"
              : "rgba(25,196,203,.25)"
            : undefined,
        },
      };
    },
    control: (styles) => {
      return {
        ...styles,
        height: `50px`,
        border: `solid 1px #d6d6dc`,
        boxShadow: `none`,
        borderRadius: `6px`,
        backgroundColor: "#f7f7f7",
      };
    },
    valueContainer: (styles) => {
      return {
        ...styles,
        cursor: `text`,
      };
    },
    menu: (styles) => {
      return {
        ...styles,
        marginTop: `0`,
      };
    },
    singleValue: (styles) => {
      return {
        ...styles,
        height: `100%`,
        display: `flex`,
        alignItems: `center`,
      };
    },
    /* input: () => ({
      height: `100%`,
    }), */
  };
};
