import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../../components/estrutura/SEO";
import SidePanel from "../../../../components/SidePanel";
import Loading from "../../../../components/Loading";
import { makeUrlFriendly } from "../../../../utils/makeUrlFriendly";
import Basico from "../../../Principal/AnunciarVaga/Passo1";
import Detalhes from "../../../Principal/AnunciarVaga/Passo2";
import Requisitos from "../../../Principal/AnunciarVaga/Passo3";
import { cidadeIDPorNome } from "../../../../utils/encontrarCidade";
import moment from "moment";
import "moment/locale/pt-br";
import { UserContext } from "../../../../context/UserContext";
import { ReactComponent as Candidato } from "../../../../img/icons/users-alt.svg";
import { ReactComponent as Opcoes } from "../../../../img/icons/ellipsis-h.svg";
import Dropdown from "react-bootstrap/Dropdown";
import api from "../../../../api";

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const [anuncios, setAnuncios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [estaEditando, setEstaEditando] = useState(false);
  const [vagaCompleta, setVagaCompleta] = useState({});
  const [basicoEditado, setBasicoEditado] = useState({});
  const [detalhesEditado, setDetalhesEditado] = useState({});
  const [requisitosEditado, setRequisitosEditado] = useState({});

  useEffect(() => {
    if (user?.anuncios?.length > 0) {
      setLoading(true);
      let promises = [];
      let anunciosComCandidatos = [];
      for (var i = 0; i < user.anuncios.length; i++) {
        promises.push(
          api
            .get(
              `/Curriculo/pesquisarAnunciante/?token=${user.tokenID}&anuncioID=${user.anuncios[i].id}`
            )
            .then((response) => {
              // do something with response
              anunciosComCandidatos.push(response.data.anuncio);
            })
        );
      }
      Promise.all(promises).then(() => {
        setAnuncios(anunciosComCandidatos);
      });
      setLoading(false);
    }
  }, []);

  const salvarAnuncio = () => {
    var novoAnuncio = {
      ...basicoEditado,
      ...detalhesEditado,
      ...requisitosEditado,
    };
    setVagaCompleta(novoAnuncio);
    api
      .put(`/Anuncios/alterar?token=${user.tokenID}`, {
        id: vagaCompleta.id,
        usuarioID: user.userInfo.id,
        ...novoAnuncio,
        dataAtualizacao: new Date().toISOString().replace("Z", ""),
        estadoID: novoAnuncio.estadoID.value,
        cidadeID: cidadeIDPorNome(novoAnuncio.cidadeID.label),
        anuncioID: vagaCompleta.id,
      })
      .then((res) => {
        if (res.status === 200) {
          const newVaga = user?.anuncios.map((obj) => {
            if (obj.id === vagaCompleta.id) {
              return { ...obj, ...novoAnuncio };
            }
            return obj;
          });
          setAnuncios(newVaga);
          setEstaEditando(false);
          updateContext({
            toast: {
              title: "Sucesso",
              message: "Seu anúncio foi atualizado!",
              status: "Success",
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
      });
  };

  const deletarVaga = (id) => {
    api
      .delete(`/Anuncios/desativar?token=${user.tokenID}&anuncioID=${id}`)
      .then((res) => {
        if (res.status === 200) {
          const newVaga = user?.anuncios.map((obj) => {
            if (obj.id === id) {
              return { ...obj, ativo: false };
            }
            return obj;
          });
          setAnuncios(newVaga);
          updateContext({
            toast: {
              title: "Vaga fechada com sucesso!",
              status: "Success",
            },
            anuncios: newVaga,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
      });
  };

  const editarVaga = (vagaId) => {
    setVagaCompleta(user?.anuncios.find(({ id }) => id === vagaId));
    setEstaEditando(true);
  };

  return (
    <>
      <SEO
        seo={{
          title: `Vagas · Área da Empresa`,
          description: ``,
        }}
      />
      <div className="painel">
        <div className="painel__titulo">
          <div className="container">
            <div className="d-flex flex-wrap align-items-center justify-content-between ">
              <div>
                <h3>Suas vagas</h3>
                <div className="lead">
                  Acompanhe as vagas na qual você está contratando.
                </div>
              </div>
              <div>
                {user?.anuncios.length > 0 && (
                  <Link
                    className="btn-vagas btn-vagas__primary"
                    to={`/anunciar-vaga`}
                  >
                    Criar nova vaga
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        <div className="vagaCard">
          {anuncios.length > 0 ? (
            <>
              <table className="vagas">
                <thead>
                  <tr>
                    <th scope="col" id="titulo">
                      Título da Vaga
                    </th>
                    <th scope="col" id="local">
                      Local
                    </th>
                    <th scope="col" id="data">
                      Data
                    </th>
                    <th scope="col" id="inscritos">
                      Inscritos
                    </th>
                    <th scope="col" id="naoIniciado">
                      Não Iniciados
                    </th>
                    <th scope="col" id="emAnalise">
                      Em Análise
                    </th>
                    <th scope="col" id="recusados">
                      Recusados
                    </th>
                    <th scope="col" id="contratados">
                      Aprovados
                    </th>
                    <th scope="col" id="status">
                      Status
                    </th>
                    <th scope="col" id="editar">
                      Opções
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {anuncios.map(
                    (
                      {
                        id,
                        contratante,
                        tituloVaga,
                        bairro,
                        endereco,
                        numero,
                        ativo,
                        lstCurriculos,
                      },
                      index
                    ) => (
                      <React.Fragment key={id}>
                        <tr>
                          <td headers="titulo">
                            <b>{tituloVaga}</b>
                            <span>{contratante}</span>
                          </td>
                          <td headers="local">
                            <p>{bairro}</p>
                            <span>
                              {endereco}, {numero}
                            </span>
                          </td>
                          <td headers="data">
                            {user.anuncios
                              .filter((el) => el.id == id)
                              .map((item) =>
                                moment(item.data).format("DD/MM/YYYY")
                              )}
                          </td>
                          <td headers="inscritos">
                            <div className="d-flex align-items-center">
                              <Link to={"/anunciante/candidatos"}>
                                {lstCurriculos?.length > 0
                                  ? lstCurriculos.length
                                  : 0}
                                <Candidato className="ms-1" />
                              </Link>
                            </div>
                          </td>
                          <td headers="naoIniciado">
                            <div className="status status--default">
                              <span>
                                {lstCurriculos?.length > 0
                                  ? lstCurriculos.filter(
                                      ({ lstCandidaturas }) =>
                                        lstCandidaturas[0].statusID === 1
                                    ).length
                                  : 0}
                              </span>
                            </div>
                          </td>
                          <td headers="emAnalise">
                            <div className="status status--progress">
                              <span>
                                {lstCurriculos?.length > 0
                                  ? lstCurriculos.filter(
                                      ({ lstCandidaturas }) =>
                                        lstCandidaturas[0].statusID === 2
                                    ).length
                                  : 0}
                              </span>
                            </div>
                          </td>
                          <td headers="recusados">
                            <div className="status status--remove">
                              <span>
                                {lstCurriculos?.length > 0
                                  ? lstCurriculos.filter(
                                      ({ lstCandidaturas }) =>
                                        lstCandidaturas[0].statusID === 3
                                    ).length
                                  : 0}
                              </span>
                            </div>
                          </td>
                          <td headers="contratados">
                            <div className="status status--success">
                              <span>
                                {lstCurriculos?.length > 0
                                  ? lstCurriculos.filter(
                                      ({ lstCandidaturas }) =>
                                        lstCandidaturas[0].statusID === 4
                                    ).length
                                  : 0}
                              </span>
                            </div>
                          </td>
                          <td headers="status">
                            <div
                              className={`status ${
                                ativo
                                  ? "status--success"
                                  : "status status--remove"
                              }`}
                            >
                              <span>{ativo ? "Aberta" : "Fechada"}</span>
                            </div>
                          </td>
                          <td headers="editar">
                            {ativo && (
                              <Dropdown
                                align={"end"}
                                className="menu__account__profile__title"
                              >
                                <Dropdown.Toggle size="sm">
                                  <Opcoes />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item as="button">
                                    <Link to="/anunciante/candidatos">
                                      Ver candidatos
                                    </Link>
                                  </Dropdown.Item>
                                  <Dropdown.Item as="button">
                                    <div
                                      className="item"
                                      onClick={() => editarVaga(id)}
                                    >
                                      Editar vaga
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Divider />

                                  <Dropdown.Item as="button">
                                    <div
                                      className="item"
                                      onClick={() => deletarVaga(id)}
                                    >
                                      Fechar vaga
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </td>
                          {/* abre dropdown com opções de deletar(que abre modal pra confirmar), editar e ver candidatos  modal ou uma aba pra editar */}
                        </tr>
                      </React.Fragment>
                    )
                  )}
                </tbody>
              </table>
            </>
          ) : loading ? (
            <Loading />
          ) : (
            <div className="row">
              <div className="painel__vazio">
                <h5>Não encontramos nenhuma vaga</h5>
                <p>Você já anunciou alguma?</p>
                <Link
                  className="btn btn-vagas btn-vagas__primary small"
                  to="/anunciar-vaga"
                >
                  Anunciar vaga
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      {estaEditando && (
        <SidePanel
          align={"top"}
          state={"info"}
          title={"Edite a sua vaga"}
          body={
            <>
              <div className="pt-4 pb-1">
                <Basico
                  contratante={vagaCompleta.contratante}
                  tituloVaga={vagaCompleta.tituloVaga}
                  ddd={vagaCompleta.ddd}
                  telefone={vagaCompleta.telefone}
                  cep={vagaCompleta.cep}
                  endereco={vagaCompleta.endereco}
                  numero={vagaCompleta.numero}
                  bairro={vagaCompleta.bairro}
                  estadoID={vagaCompleta.estadoID}
                  cidadeID={vagaCompleta.cidadeID}
                  telemedicina={vagaCompleta.telemedicina}
                  telemedicina={vagaCompleta.telemedicina}
                  enviarPai={(obj) => setBasicoEditado(obj)}
                />
              </div>
              <div className="py-1">
                <Detalhes
                  tempo={vagaCompleta.tempo}
                  regimeContrato={vagaCompleta.regimeContrato}
                  valeAlimentacao={vagaCompleta.valeAlimentacao}
                  valeRefeicao={vagaCompleta.valeRefeicao}
                  valeTransporte={vagaCompleta.valeTransporte}
                  beneficios={vagaCompleta.beneficios}
                  descricao={vagaCompleta.descricao}
                  faixaSalarialDe={vagaCompleta.faixaSalarialDe}
                  faixaSalarialAte={vagaCompleta.faixaSalarialAte}
                  salarioAcombinar={vagaCompleta.salarioAcombinar}
                  enviarPai={(obj) => setDetalhesEditado(obj)}
                />
              </div>
              <div className="py-1">
                <Requisitos
                  tempoExperiencia={vagaCompleta.tempoExperiencia}
                  escolaridade={vagaCompleta.escolaridade}
                  diferenciais={vagaCompleta.diferenciais}
                  enviarPai={(obj) => setRequisitosEditado(obj)}
                />
              </div>
              <button
                onClick={salvarAnuncio}
                className="btn-vagas btn-vagas__primary w-100"
              >
                Salvar alterações
              </button>
            </>
          }
          show={estaEditando}
          hide={() => {
            setEstaEditando(false);
          }}
        />
      )}
    </>
  );
};

export default IndexPage;
