import React from "react";
import ContentLoader from "react-content-loader";

const containerWidth = (windowWidth) => {
  const table = [
    {
      windowAbove: 1400,
      max: 1320,
    },
    {
      windowAbove: 1200,
      max: 1140,
    },
    {
      windowAbove: 992,
      max: 960,
    },
    {
      windowAbove: 768,
      max: 720,
    },
    {
      windowAbove: 540,
      max: 576,
    },
  ];

  if (windowWidth >= 540) {
    for (var i = 0; i < table.length; i++) {
      if (windowWidth > table[i].windowAbove) {
        return table[i].max;
      }
    }
  } else {
    return windowWidth;
  }
};

const Linha = ({ colunas, row, height, space }) => {
  return new Array(colunas).fill().map((el, index) => (
    <rect
      key={index}
      rx="6"
      ry="6"
      x={`${index * (100 / colunas + 0.55)}%`} //index * (width / colunas - 24) + index * 24
      y={row * (height + 16)}
      width={`${100 / colunas - ((100 / colunas) * space) / 100}%`}
      height={height}
    />
  ));
};

export const PHVagas = (props) => {
  return (
    <ContentLoader
      title="Carregando..."
      width={300}
      height={16}
      viewBox="0 0 300 16"
    >
      <rect x="0" y="0" rx="6" ry="6" width="100%" height="100%" />
    </ContentLoader>
  );
};

export const PHCardsHome = ({ windowWidth }) => {
  return (
    <ContentLoader
      title="Carregando..."
      width={"100%"}
      height={windowWidth > 768 ? 226.7 : 186.42}
      viewBox={`0 0 ${containerWidth(windowWidth)} ${
        windowWidth > 768 ? 226.7 : 186.42
      }`}
    >
      {new Array(1).fill().map((el, index) => (
        <Linha
          key={index}
          colunas={windowWidth > 992 ? 4 : windowWidth > 768 ? 3 : 1}
          height={windowWidth > 768 ? 210.7 : 170.42}
          space={windowWidth > 768 ? 6.45 : 0}
          row={index}
        />
      ))}
    </ContentLoader>
  );
};

export const PHCards = ({ windowWidth }) => {
  return (
    <ContentLoader
      width={"100%"}
      height={windowWidth > 768 ? 210.7 * 3 + 16 * 3 : 170.42 * 3 + 16 * 3}
      viewBox={`0 0 ${containerWidth(windowWidth)} ${
        windowWidth > 768 ? 210.7 * 3 + 16 * 3 : 170.42 * 3 + 16 * 3
      }`}
    >
      {new Array(3).fill().map((el, index) => (
        <Linha
          key={index}
          colunas={windowWidth > 992 ? 4 : windowWidth > 768 ? 3 : 1}
          height={windowWidth > 768 ? 210.7 : 170.42}
          space={windowWidth > 768 ? 6.45 : 0}
          row={index}
        />
      ))}
    </ContentLoader>
  );
};
