import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LocationPin } from "../img/icons/location-point.svg";
import { cidadeNomePorID } from "../utils/encontrarCidade";
import { estadoUFPorID } from "../utils/encontrarEstado";

const VagaCard = ({
  regimeContrato,
  contratante,
  tituloVaga,
  estadoID,
  cidadeID,
  dataAtualizacao,
  descricao,
  className,
  link,
  state,
}) => {
  return (
    <div className={`${className} mb-3`}>
      <Link className="nostyle" state={state} to={link ? link : "#"}>
        <div className="vagaCard">
          <div className="vagaCard__body">
            <div className="vagaCard__body__tipo">
              {regimeContrato ? regimeContrato : "Indefinido"}
            </div>
            <div className="vagaCard__body__cargo">{tituloVaga}</div>
            <div className="vagaCard__body__empresa">{contratante}</div>
            <p className="vagaCard__body__descricao">
              {descricao?.replace(/(<([^>]+)>)/gi, "")}
            </p>
          </div>

          <div className="vagaCard__footer">
            <div className="vagaCard__footer__local">
              <LocationPin />
              <span>
                {`${cidadeNomePorID(cidadeID)}, ${estadoUFPorID(estadoID)}`}
              </span>
            </div>
            <div className="p3">{/* {dataAtualizacao} */}</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default VagaCard;
