import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../img/logo.svg";
import { makeUrlFriendly } from "../../../utils/makeUrlFriendly";

const chunkArr = (arr, size) =>
  arr.reduceRight((r, i, _, s) => (r.push(s.splice(0, size)), r), []);

const Footer = (props) => {
  const arr = [
    {
      cidade: "Belo Horizonte",
      estado: "Minas Gerais",
      uf: "MG",
    },
    {
      cidade: "Brasília",
      estado: "Distrito Federal",
      uf: "DF",
    },
    {
      cidade: "Campinas",
      estado: "São Paulo",
      uf: "SP",
    },
    {
      cidade: "Curitiba",
      estado: "Paraná",
      uf: "PR",
    },
    {
      cidade: "Goiânia",
      estado: "Goiás",
      uf: "GO",
    },
    {
      cidade: "Guarulhos",
      estado: "São Paulo",
      uf: "SP",
    },
    {
      cidade: "João Pessoa",
      estado: "Paraíba",
      uf: "PB",
    },
    {
      cidade: "Natal",
      estado: "Rio Grande do Norte",
      uf: "RN",
    },
    {
      cidade: "Niterói",
      estado: "Rio de Janeiro",
      uf: "RJ",
    },
    {
      cidade: "Porto Alegre",
      estado: "Rio Grande do Sul",
      uf: "RS",
    },
    {
      cidade: "Ribeirão Preto",
      estado: "São Paulo",
      uf: "SP",
    },
    {
      cidade: "Rio de Janeiro",
      estado: "Rio de Janeiro",
      uf: "RJ",
    },
    {
      cidade: "Salvador",
      estado: "Bahia",
      uf: "BA",
    },
    {
      cidade: "Santo André",
      estado: "São Paulo",
      uf: "SP",
    },
    {
      cidade: "Santos",
      estado: "São Paulo",
      uf: "SP",
    },
    {
      cidade: "São Bernardo do Campo",
      estado: "São Paulo",
      uf: "SP",
    },
    {
      cidade: "São Caetano do Sul",
      estado: "São Paulo",
      uf: "SP",
    },
    {
      cidade: "São Paulo",
      estado: "São Paulo",
      uf: "SP",
    },
  ];
  const items = chunkArr(arr, 5);

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer__links">
            <div className="footer__links__cidades">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="lead">Busque por cidades</div>
                </div>
                {items.map((chunk, key) => (
                  <div key={`col_${key}`} className="col-lg-3 col-4 mb-4">
                    <ul>
                      {chunk.map(({ cidade, estado, uf }, key) => (
                        <li key={key}>
                          <Link
                            className="nostyle"
                            to={`/vagas/${makeUrlFriendly(`${cidade}, ${uf}`)}`}
                          >
                            <div>{cidade}</div>
                            <div className="mt-1">{estado}</div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
            {/*    <div className="footer__links__site">
              <div className="row">
                <div className="col-lg-3 col-4">
                  <div className="lead mb-4">Sobre</div>
                  <ul>
                    <li></li>
                  </ul>
                </div>
                <div className="col-lg-3 col-4">
                  <div className="lead mb-4">Sobre</div>
                  <ul>
                    <li></li>
                  </ul>
                </div>
                <div className="col">
                  <div className="lead mb-4">Redes Sociais</div>
                  <ul>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
          <div className="footer__copy">
            <div className="footer__copy__name">
              <Logo />
              <div>© {new Date().getFullYear()} VMED Oportunidades</div>
            </div>
            <div className="footer__copy__terms">
              <Link className="nostyle" to="/">
                Privacidade
              </Link>
              <span>·</span>
              <Link className="nostyle" to="/">
                Termos de Uso
              </Link>
              <span>·</span>
              <Link className="nostyle" to="/">
                Mapa do site
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
