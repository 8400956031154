import React from "react";
import { ReactComponent as Check } from "../img/icons/check-input.svg";

const InputCheck = (props) => {
  return (
    <>
      <div className="input__checkbox mt-1">
        <label htmlFor={props.id}>
          <div>
            <span>
              <input
                type="checkbox"
                defaultChecked={props.defaultChecked}
                onChange={props.onChange}
                name={props.id}
                id={props.id}
              />
              <span className={props.defaultChecked ? "active" : ""}>
                {props.defaultChecked ? <Check /> : ""}
              </span>
            </span>
          </div>
          <div className="ms-2">{props.placeholder}</div>
        </label>
      </div>
      {props.error !== "" && (
        <span className="error__message">{props.error}</span>
      )}
    </>
  );
};

export default InputCheck;
