import React, { useState, useEffect, useContext, useRef } from "react";
import Layout from "../../../components/estrutura/principal/Layout";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import VagaCard from "../../../components/VagaCard";
import {
  PHVagas,
  PHCards,
} from "../../../components/placeholder/PlaceholderLista";
import VagaFull from "../../../components/VagaFull";
import Modal from "../../../components/Modal";
import { Helmet } from "react-helmet";

import FaixaSalarial from "../../../components/filtros/FaixaSalarial";
import DataPublicacao from "../../../components/filtros/DataPublicacao";
import Escolaridade from "../../../components/filtros/Escolaridade";
import TipoContrato from "../../../components/filtros/TipoContrato";

import { UserContext } from "../../../context/UserContext";

import useWindowDimensions from "../../../hooks/useWindowDimensions";
import moment from "moment";
import "moment/locale/pt-br";
import api from "../../../api";
import {
  encontrarCidadesVizinhas,
  encontrarCidadesVizinhasPorNome,
} from "../../../utils/encontrarCidadesVizinhas";
import {
  cidadeNomePorID,
  cidadeIDPorNome,
} from "../../../utils/encontrarCidade";
import {
  estadoNomePorID,
  estadoIDPorNome,
} from "../../../utils/encontrarEstado";
import { makeUrlFriendly } from "../../../utils/makeUrlFriendly";
import { limparQuery } from "../../../utils/limparQuery";
import { ReactComponent as AngleDown } from "../../../img/icons/angle-down.svg";
import { ReactComponent as AngleLeft } from "../../../img/icons/angle-left.svg";
import { ReactComponent as AngleRight } from "../../../img/icons/angle-right.svg";
import { ReactComponent as VagasLista } from "../../../img/icons/vagas-lista.svg";
import { ReactComponent as VagasComp } from "../../../img/icons/vagas-comp.svg";
import NadaEncontrado from "../../../img/sem-resultado.svg";
import { BrowserView } from "react-device-detect";
import qs from "query-string";
import ReactPaginate from "react-paginate";

const FiltrosComp = ({ filtros, onSelect, id }) => {
  switch (id) {
    /*     case 1:
      return <Escolaridade filtros={filtros} onSelect={onSelect} />; */
    case 1:
      var query = filtros.find(({ query }) => query === `FaixaSalarial`);
      if (query.selected) {
        var range = query.selected.split("-");
      }
      return (
        <FaixaSalarial
          defaultMin={range ? parseFloat(range[0]) : null}
          defaultMax={range ? parseFloat(range[1]) : null}
          filtros={filtros}
          onSelect={onSelect}
        />
      );

    case 2:
      return <TipoContrato filtros={filtros} onSelect={onSelect} />;

    case 3:
      return <DataPublicacao filtros={filtros} onSelect={onSelect} />;
    default:
      return "";
  }
};

const makeQuery = (pathname, queryParam, pageCount) => {
  if (pageCount) {
    return `${pathname}?${qs.stringify({
      ...limparQuery({
        ...queryParam,
        Page: pageCount,
      }),
    })}`;
  }
  return `${pathname}?${qs.stringify({
    ...limparQuery({
      ...queryParam,
    }),
  })}`;
};

const IndexPage = (props) => {
  const navigate = useNavigate();
  const { local, posicao } = useParams();
  const { height, width } = useWindowDimensions();
  const { updateContext, user } = useContext(UserContext);
  const { pathname, search, hash, state } = useLocation();
  const queryParam = qs.parse(search);
  const [modoLista, setModoLista] = useState(true);
  const [modal, setModal] = useState(false);
  const [clear, setClear] = useState(false);
  const [pageData, setPageData] = useState([]);
  const [vagasData, setVagasData] = useState([]);
  const [tempFiltro, setTempFiltro] = useState({});
  const [currentFiltro, setCurrentFiltro] = useState({ label: "", index: 1 });
  const [filtros, setFiltros] = useState([
    {
      query: "Page",
      selected: null,
    },
    {
      query: "TituloVaga",
      selected: null,
    },
    /*   {
      label: "Distância",
      query: "distancia",
      selected: null,
    }, */
    /*    {
      label: "Escolaridade",
      query: "Escolaridade",
      selected: null,
    }, */
    {
      label: "Faixa Salarial",
      query: "FaixaSalarial",
      selected: null,
    },
    {
      label: "Tipo de Contrato",
      query: "RegimeContrato",
      selected: null,
    },
    {
      label: "Data de Publicação",
      query: "dataPublicacao",
      selected: null,
    },
  ]);
  var localConvertido = "";

  useEffect(() => {
    const filtragem = filtros.map((el, i) => {
      var index = Object.keys(queryParam).findIndex(
        (item) => item === el.query
      );
      if (index >= 0) {
        return {
          ...el,
          selected:
            el.selected === queryParam[Object.keys(queryParam)[index]]
              ? el.selected
              : queryParam[Object.keys(queryParam)[index]],
        };
      }
      return { ...el };
    });
    setFiltros(filtragem);
    setClear(true);
  }, []);

  useEffect(() => {
    if (local) {
      localConvertido = encontrarCidadesVizinhasPorNome(
        local ? local.replace("-", " ") : ""
      );
      localConvertido = cidadeIDPorNome(localConvertido.cidade);
    }
    if (clear) {
      updateContext({ loading: true });
      api
        .get(`/vagas/pesquisarFiltros`, {
          params: {
            Page: 1,
            RowsPage: 47,
            CidadeID: state?.local?.value
              ? state?.local?.value
              : localConvertido
              ? localConvertido
              : null,
            TituloVaga: state?.posicao?.label
              ? state?.posicao?.label
              : posicao
              ? posicao
              : "",
            ...(filtros.find(({ query }) => query === "FaixaSalarial").selected
              ? {
                  ...queryParam,
                  FaixaSalarialDe: filtros
                    .find(({ query }) => query === "FaixaSalarial")
                    .selected.split("-")[0],
                  FaixaSalarialAte: filtros
                    .find(({ query }) => query === "FaixaSalarial")
                    .selected.split("-")[1],
                }
              : { ...queryParam }),
          },
        })
        .then(({ data }) => {
          window.scrollTo(0, 0);
          setVagasData(data.paginacao);
          updateContext({
            loading: false,
            vagasData: data.paginacao,
          });
        })
        .catch((err) => {
          if (err.response) {
            updateContext({
              toast: {
                title: err.response.data,
                status: "Error",
              },
            });
          }
        })
        .finally(() => {});
    }
  }, [pathname, filtros, clear]);

  useEffect(() => {
    if (hash) {
      setModoLista(false);
      api
        .get(`/Vagas/pesquisar/?anunciosID=${Number(hash.replace("#", ""))}`)
        .then((response) => {
          setPageData(...response.data.lstAnuncios);
        })
        .catch((err) => {
          if (err.response) {
            updateContext({
              toast: {
                title: err.response.data,
                status: "Error",
              },
            });
          }
        });
    }
  }, [hash]);

  const updateFiltro = (qs, valor) => {
    var newFiltro = [...filtros];
    var objIndex = filtros.findIndex(({ query }) => query === qs);
    newFiltro[objIndex].selected = valor.toString();
    setFiltros(newFiltro);
    navigate(
      makeQuery(
        pathname,
        Object.fromEntries(
          filtros
            .filter(({ selected }) => selected)
            .map(({ query, selected }) => [query, selected])
        )
      )
    );
  };

  const abrirFiltro = (e) => {
    setModal(!modal);
  };

  return (
    <Layout
      menuBranco
      seoData={{
        title: `${
          state?.local?.label
            ? `${state?.local?.label} · Vagas`
            : localConvertido
            ? `${localConvertido.cidade} · Vagas`
            : `Em qualquer lugar · Vagas`
        }`,
        description: `Candidate-se a vagas disponíveis agora mesmo${
          state?.local?.label
            ? ` em ${state?.local?.label}`
            : localConvertido
            ? ` em ${localConvertido.cidade}`
            : " em vários lugares"
        }. Melhores vagas na área de saúde. Crie o seu currículo e comece a conversar com recrutadores em minutos!`,
      }}
    >
      <BrowserView>
        <div className="painel">
          <div
            className="painel__titulo"
            style={{ padding: `8vh 0`, margin: "0" }}
          ></div>
        </div>
      </BrowserView>
      <section className="vagas only-b">
        <div className="vagas__filtro">
          <div className="container">
            <ul>
              {filtros
                .filter((e) => e.label)
                .map(({ query, selected, label }, index) => (
                  <li key={query}>
                    <button
                      onClick={() => {
                        abrirFiltro(!modal);
                        setCurrentFiltro({ label, index: index + 1 });
                      }}
                      className={selected ? "active" : ""}
                    >
                      <span>{label}</span>
                      <AngleDown />
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="container">
          <div className="row vagas__header">
            <div className="col-md-6 mb-2">
              <p>
                {user?.loading || vagasData.length === 0 ? (
                  <PHVagas />
                ) : vagasData?.data?.length > 0 ? (
                  `` /* ${vagasData.total} vagas em ${
                    user?.vagasData?.localInfo.length > 0
                      ? user?.vagasData?.localInfo.length > 1
                        ? "vários lugares"
                        : user.vagasData.localInfo[0]?.cidade
                      : "qualquer lugar"
                  } */
                ) : (
                  ""
                )}
              </p>
            </div>
            {vagasData?.data?.length > 0 && (
              <BrowserView className="col-md-6 mb-2">
                <button
                  onClick={() => {
                    modoLista
                      ? navigate(
                          `${pathname}${search}#${vagasData?.data[0].id}`
                        )
                      : navigate(`${pathname}${search}`);
                    setModoLista(!modoLista);
                  }}
                >
                  Alterar modo de visualização para
                  {modoLista ? " PAINEL" : " LISTA"}
                </button>
              </BrowserView>
            )}

            <div className={modoLista ? "col-12" : "col-lg-4"}>
              <div className="row">
                {user?.loading ? (
                  <PHCards windowWidth={width} height={height * 1.5} />
                ) : vagasData?.data?.length === 0 ? (
                  <div className="col-12 pb-5 text-center">
                    <div className="row justify-content-center">
                      <div className="col-md-5">
                        <div className="titulo text-center ">
                          <img
                            src={NadaEncontrado}
                            className="w-100 img-fluid mb-3"
                            style={{ maxWidth: `190px` }}
                          />
                          <h3>Nada foi encontrado!</h3>
                          <p>
                            Infelizmente não encontramos nenhuma vaga no local
                            pesquisado, caso queira, tente procurar em outros
                            lugares!
                          </p>
                        </div>

                        <Link
                          to={makeQuery(pathname, queryParam)}
                          className="btn-vagas btn-vagas__primary mt-3"
                        >
                          Ver outras vagas
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  vagasData?.data?.map(
                    (
                      {
                        id,
                        contratante,
                        tituloVaga,
                        estadoID,
                        cidadeID,
                        dataAtualizacao,
                        regimeContrato,
                        descricao,
                        bairro,
                        endereco,
                        numero,
                      },
                      index
                    ) => (
                      <VagaCard
                        key={id}
                        className={
                          modoLista
                            ? "col-md-4 col-lg-3"
                            : `col-12 ${
                                hash.replace("#", "") == id ? "active" : ""
                              }`
                        }
                        regimeContrato={regimeContrato}
                        contratante={contratante}
                        tituloVaga={tituloVaga}
                        estadoID={estadoID}
                        cidadeID={cidadeID}
                        bairro={bairro}
                        endereco={endereco}
                        numero={numero}
                        regimeContrato={regimeContrato}
                        descricao={descricao}
                        dataAtualizacao={moment(dataAtualizacao).fromNow()}
                        link={
                          modoLista
                            ? `/oportunidade/${id}/${makeUrlFriendly(
                                tituloVaga +
                                  "-" +
                                  cidadeNomePorID(cidadeID) +
                                  "-" +
                                  estadoNomePorID(estadoID)
                              )}`
                            : `${search}#${id}`
                        }
                        state={{
                          vagaID: id,
                          paginaAnterior: pathname + search,
                          listaIDS: vagasData.data.map(
                            ({ id, tituloVaga, estadoID, cidadeID }) => ({
                              id,
                              url: `${makeUrlFriendly(
                                tituloVaga +
                                  "-" +
                                  cidadeNomePorID(cidadeID) +
                                  "-" +
                                  estadoNomePorID(estadoID)
                              )}`,
                            })
                          ),
                          pagina: {
                            next_page: vagasData.next_page,
                            pre_page: vagasData.pre_page,
                          },
                        }}
                      />
                    )
                  )
                )}
              </div>
            </div>
            {modoLista ? (
              ""
            ) : (
              <div className="col-lg-8">
                <div className="vagas__comp">
                  <VagaFull
                    noSideBar
                    id={hash.replace("#", "")}
                    regimeContrato={pageData?.regimeContrato}
                    contratante={pageData?.contratante}
                    tituloVaga={pageData?.tituloVaga}
                    bairro={pageData?.bairro}
                    endereco={pageData?.endereco}
                    numero={pageData?.numero}
                    cidadeID={pageData?.cidadeID}
                    estadoID={pageData?.estadoID}
                    tempoExperiencia={pageData?.tempoExperiencia}
                    escolaridade={pageData?.escolaridade}
                    descricao={pageData?.descricao}
                    status={pageData?.status}
                    tempo={pageData?.tempo}
                    diferenciais={pageData?.diferenciais}
                    salarioAcombinar={pageData?.salarioAcombinar}
                    faixaSalarialAte={pageData?.faixaSalarialAte}
                    faixaSalarialDe={pageData?.faixaSalarialDe}
                    valeAlimentacao={pageData?.valeAlimentacao}
                    valeRefeicao={pageData?.valeRefeicao}
                    valeTransporte={pageData?.valeTransporte}
                    proprio={pageData?.proprio}
                    cep={pageData?.cep}
                    dataAtualizacao={moment(
                      pageData?.dataAtualizacao
                    ).fromNow()}
                  />
                </div>
              </div>
            )}
          </div>
          {user?.loading || vagasData.length === 0 ? (
            ""
          ) : (
            <>
              <hr />
              <Helmet>
                <link
                  rel="prev"
                  href={`${window.location.origin}${makeQuery(
                    pathname,
                    queryParam,
                    vagasData.pre_page
                  )}`}
                />
                <link
                  rel="next"
                  href={`${window.location.origin}${makeQuery(
                    pathname,
                    queryParam,
                    vagasData.next_page
                  )}`}
                />
              </Helmet>
              <div className="vagas__paginacao">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<AngleRight />}
                  onPageChange={(e) => updateFiltro("Page", e.selected + 1)}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={1}
                  forcePage={vagasData.page - 1}
                  pageCount={vagasData.total_pages}
                  previousLabel={<AngleLeft />}
                  renderOnZeroPageCount={null}
                  hrefBuilder={(pageCount) =>
                    makeQuery(pathname, queryParam, pageCount)
                  }
                />
              </div>
            </>
          )}
        </div>
      </section>
      {modal && (
        <Modal
          state={"info"}
          title={currentFiltro.label}
          body={
            <FiltrosComp
              filtros={filtros}
              id={currentFiltro.index}
              onSelect={(tipo, valor) =>
                setTempFiltro({
                  [tipo]: valor,
                })
              }
            />
          }
          show={modal}
          acao={() => {
            if (Object.keys(tempFiltro).length > 0) {
              updateFiltro(
                Object.keys(tempFiltro)[0],
                tempFiltro[Object.keys(tempFiltro)[0]]
              );
            }
            setModal(false);
          }}
          btnAcao={"Ver resultados"}
          hide={() => {
            setModal(false);
            setTempFiltro({});
          }}
        />
      )}
    </Layout>
  );
};

export default IndexPage;
