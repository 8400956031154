import React, { useState, useEffect, useContext } from "react";
import { Navigate, Route, useLocation, Outlet } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import api from "../../api";

const RedirecionarLogado = (props) => {
  const { user } = useContext(UserContext);
  /* if (user?.valido) {
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  } */
  return <Outlet />;
};

export default RedirecionarLogado;
