import lista from "../data/estados.json";

export const estadoNomePorID = (id) => {
  let estado;
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].id === id) {
      estado = lista[i].state;
      break;
    }
  }
  return estado;
};

export const estadoIDPorNome = (nome) => {
  let estado;
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].state === nome) {
      estado = lista[i].id;
      break;
    }
  }
  return estado;
};

export const estadoUFPorID = (id) => {
  let estado;
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].id === id) {
      estado = lista[i].uf;
      break;
    }
  }
  return estado;
};

export const estadoIDPorUF = (uf) => {
  let estado;
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].uf === uf) {
      estado = lista[i].id;
      break;
    }
  }
  return estado;
};

export const estadoNomePorUF = (uf) => {
  let estado;
  for (let i = 0; i < lista.length; i++) {
    if (lista[i].uf === uf) {
      estado = lista[i].state;
      break;
    }
  }
  return estado;
};
