import React, { useState, useEffect } from "react";
import Slider from "../InputSlider";
import { toBRL } from "../../utils/toBRL";

const FaixaSalarial = ({
  defaultMin,
  defaultMax,
  filtros,
  onSelect,
  value,
}) => {
  const [opcaoEscolhida, setOpcaoEscolhida] = useState({
    min: 1000,
    max: 25000,
  });
  const [rangeDefault, setRangeDefault] = useState({
    min: null,
    max: null,
  });

  useEffect(() => {
    var query = filtros.find(({ query }) => query === `FaixaSalarial`);
    if (query.selected) {
      var range = query.selected.split("-");
      setRangeDefault({ min: parseFloat(range[0]), max: parseFloat(range[1]) });
    }
  }, []);

  useEffect(() => {
    onSelect("FaixaSalarial", `${opcaoEscolhida.min}-${opcaoEscolhida.max}`);
  }, [opcaoEscolhida]);

  return (
    <>
      <div className="pt-2 pb-4 mb-5">
        <p className="mb-4 text-center">
          Entre {toBRL(opcaoEscolhida.min)} e {toBRL(opcaoEscolhida.max)}
        </p>
        <Slider
          min={1000}
          max={25000}
          defaultMin={defaultMin}
          defaultMax={defaultMax}
          onChange={setOpcaoEscolhida}
        />
      </div>
    </>
  );
};

export default FaixaSalarial;
