import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import BuscarVaga from "../../../components/BuscarVaga";
import BuscarVagaMobile from "../../../components/BuscarVaga/Mobile";
import Modal from "../../../components/Modal";
import { UserContext } from "../../../context/UserContext";
import {
  isMobile,
  isBrowser,
  MobileView,
  BrowserView,
} from "react-device-detect";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as Logo } from "../../../img/logo.svg";
import { ReactComponent as Search } from "../../../img/icons/search-alt.svg";
import { ReactComponent as Entrar } from "../../../img/icons/user-circle.svg";
import { ReactComponent as Cadastrar } from "../../../img/icons/user-plus.svg";
import { ReactComponent as Curriculo } from "../../../img/icons/clipboard-notes.svg";
import { ReactComponent as Anunciar } from "../../../img/icons/briefcase-alt.svg";
import { ReactComponent as Lista } from "../../../img/icons/list-ul.svg";
import { ReactComponent as Painel } from "../../../img/icons/apps.svg";

const Menu = ({ modalAberto, menuBranco, menuBusca }) => {
  const { user } = useContext(UserContext);
  const [stickyClass, setStickyClass] = useState("");
  const [homeFixed, setHomeFixed] = useState("homeFixed");
  const [navMobile, setNavMobile] = useState(true);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);
  var previousScroll = 0;
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 0 ? setStickyClass("fixed") : setStickyClass("");
      windowHeight > 250 ? setHomeFixed("") : setHomeFixed("homeFixed");
      if (windowHeight > previousScroll) {
        if (windowHeight > (document.body.offsetHeight * 20) / 100) {
          setNavMobile(false);
        }
      } else {
        setNavMobile(true);
      }
      previousScroll = windowHeight;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/vagas/";
  };

  useEffect(() => {
    setModal(modalAberto);
  }, [modalAberto]);

  return (
    <>
      <header
        className={`${menuBranco && stickyClass === "" ? "branco" : ""} ${
          menuBusca === "home" ? `${homeFixed}` : menuBranco ? "" : "generico"
        } ${stickyClass} `}
        id="principal"
      >
        <div className="container">
          <nav className="nav">
            <div className="nav__logo">
              <NavLink to="/">
                <Logo />
              </NavLink>
            </div>
            <div className={`nav__vagas`}>
              <BuscarVaga onMenu card />
              <div className="nav__vagas--mobile">
                <button onClick={() => setModal(true)}>
                  <span>Busque sua vaga</span> <Search />
                </button>
              </div>
            </div>
            <BrowserView>
              <div className="nav__conta">
                {user?.valido ? (
                  <>
                    <Dropdown
                      align={"end"}
                      className="menu__account__profile__title"
                    >
                      <Dropdown.Toggle size="sm">
                        {user?.info?.nome ? user?.info?.nome : "Sua área"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {user?.userInfo?.tipoUsuarioID === 0 ? (
                          <>
                            {" "}
                            {/*  <Dropdown.Item as="button">
                              <Link to="/candidato/">Painel</Link>
                            </Dropdown.Item> */}
                            <Dropdown.Item as="button">
                              <Link to="/candidato/curriculo">Currículo</Link>
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <Link to="/candidato/candidaturas">
                                Candidaturas
                              </Link>
                            </Dropdown.Item>
                            {/* <Dropdown.Item as="button">
                              <Link to="/candidato/perfil">Perfil</Link>
                            </Dropdown.Item> */}
                          </>
                        ) : (
                          <>
                            {" "}
                            {/*   <Dropdown.Item as="button">
                              <Link to="/anunciante/">Painel</Link>
                            </Dropdown.Item> */}
                            <Dropdown.Item as="button">
                              <Link to="/anunciante/vagas">Vagas</Link>
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <Link to="/anunciante/candidatos">
                                Candidatos
                              </Link>
                            </Dropdown.Item>
                            {/*  <Dropdown.Item as="button">
                              <Link to="/anunciante/perfil">Perfil</Link>
                            </Dropdown.Item> */}
                          </>
                        )}
                        <Dropdown.Divider />
                        <Dropdown.Item as="button">
                          <a href="#" onClick={() => logout()}>
                            Sair
                          </a>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                ) : (
                  <>
                    <NavLink to="/entrar">Entrar</NavLink>
                    <NavLink to="/anunciar-vaga">Anunciar vaga</NavLink>
                  </>
                )}
              </div>
            </BrowserView>
          </nav>
        </div>
      </header>

      {menuBusca !== "home" && !menuBranco && (
        <div className="nav--block"></div>
      )}
      <MobileView>
        <Modal
          align={"top"}
          state={"info"}
          title={"Procure por uma vaga"}
          body={<BuscarVagaMobile fecharModal={() => setModal(false)} />}
          show={modal}
          hide={() => {
            setModal(false);
          }}
        />
      </MobileView>
      <MobileView
        className={`nav--mobile ${user?.valido ? "logado " : ""} ${
          navMobile ? "" : "hide"
        }`}
      >
        <ul>
          {user?.valido ? (
            <>
              {user?.userInfo?.tipoUsuarioID === 0 ? (
                <>
                  <li>
                    <NavLink to="/candidato/candidaturas">
                      <Lista />
                      <div>Candidato</div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/candidato/curriculo">
                      <Curriculo />
                      <div>Currículo</div>
                    </NavLink>
                  </li>
                  <li className="active">
                    <NavLink to="/vagas/">
                      <Search />
                      <div>Vagas</div>
                    </NavLink>
                  </li>

                  {/* <li>
                    <NavLink to="/candidato/">
                      <Painel />
                      <div>Painel</div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/candidato/perfil">
                      <Entrar />
                      <div>Perfil</div>
                    </NavLink>
                  </li> */}
                </>
              ) : (
                <>
                  <li>
                    <NavLink to="/anunciante/candidatos">
                      <Lista />
                      <div>Candidatos</div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/anunciante/vagas">
                      <Search />
                      <div>Vagas</div>
                    </NavLink>
                  </li>

                  <li className="active">
                    <NavLink to="/anunciar-vaga">
                      <Anunciar />
                      <div>Anunciar</div>
                    </NavLink>
                  </li>

                  {/*  <li>
                    <NavLink to="/anunciante/">
                      <Painel />
                      <div>Painel</div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/anunciante/perfil">
                      <Entrar />
                      <div>Perfil</div>
                    </NavLink>
                  </li> */}
                </>
              )}
            </>
          ) : (
            <>
              <li>
                <NavLink to="/anunciar-vaga">
                  <Anunciar />
                  <div>Anunciar</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/criar-curriculo">
                  <Curriculo />
                  <div>Currículo</div>
                </NavLink>
              </li>
              <li className="active">
                <NavLink to="/vagas/">
                  <Search />
                  <div>Vagas</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/entrar">
                  <Entrar />
                  <div>Entrar</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/cadastrar">
                  <Cadastrar />
                  <div>Cadastrar</div>
                </NavLink>
              </li>
            </>
          )}
        </ul>
      </MobileView>
    </>
  );
};

export default Menu;
