import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "../api";
import Loading from "../components/Loading";
import axios from "axios";
import { converterEstadoParaUF } from "../utils/converterEstadoParaUF";
import { cidadeIDPorNome } from "../utils/encontrarCidade";

export const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState({ toast: false, loading: false });
  const [buscaLocation, setBuscaLocation] = useState({});
  const token = localStorage.getItem("token");

  const updateContext = (obj, location) => {
    if (location) {
      setBuscaLocation({ ...obj });
    } else {
      setUser({ ...user, ...obj });
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        let latitude = position.coords.latitude;
        let longitude = position.coords.longitude;

        const resp = await axios.get(
          `https://us1.locationiq.com/v1/reverse.php?key=pk.d197a910d757c5d3b5f13599ebf98da7&lat=${latitude}&lon=${longitude}&format=json`
        );
        if (resp.status === 200) {
          setBuscaLocation({
            label: `${resp.data.address.city}, ${converterEstadoParaUF(
              resp.data.address.state
            )}`,
            value: `${cidadeIDPorNome(resp.data.address.city)}`,
          });
        }
      });
    } else {
      console.log("não disponível");
    }
  }, []);

  useEffect(() => {
    if (token) {
      var data = new FormData();
      data.append("token", token);
      api({
        method: "post",
        url: "/Usuario/validar",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(async ({ data }) => {
          if (data.valido) {
            let res;
            let arr;
            if (data.userInfo.tipoUsuarioID === 0) {
              res = await api.get(`/Candidaturas?token=${token}`);
              arr = { candidaturas: res.data.lstCandidaturas };
            } else {
              res = await api.get(`/Anuncios/pesquisarTudo?token=${token}`);
              arr = {
                anuncios: res.data.lstAnuncios,
                candidatos: [],
              };
            }
            setUser({
              ...data,
              ...arr,
            });
          } else {
            localStorage.removeItem("token");
            window.location.href = "/";
          }
        })
        .catch((err) => {
          localStorage.removeItem("token");
          window.location.href = "/";
          if (err.response) {
            updateContext({
              toast: {
                title: err.response.data,
                status: "Error",
              },
            });
          }
        });
    }
  }, []);

  if (!user?.valido && token) {
    return (
      <div className="loading-centerScreen ">
        <Loading />
      </div>
    );
  }

  return (
    <UserContext.Provider value={{ user, updateContext, buscaLocation }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
