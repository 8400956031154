import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../../components/estrutura/SEO";

const IndexPage = (props) => {
  return (
    <>
      <SEO
        seo={{
          title: `Painel · Área da Empresa`,
          description: ``,
        }}
      />
      <div>Painel</div>
    </>
  );
};

export default IndexPage;
