import React, { useState, useContext, useEffect } from "react";
import lista from "../../../data/especialidades.json";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import { buscarStyle, buscarStyleMobile } from "../../../utils/buscarStyle";
import { UserContext } from "../../../context/UserContext";
import qs from "query-string";

const BuscarVagaEspecialidade = ({ mobile, enviarPai }) => {
  const { user, updateContext } = useContext(UserContext);
  const { pathname, search, state } = useLocation();
  const [posicaoOptions, setPosicaoOptions] = useState([]);
  const [inputPosicao, setInputPosicao] = useState("");
  const [toggled, setToggled] = useState(false);

  useEffect(() => {
    if (user?.posicao && !toggled) {
      setInputPosicao({ label: user.posicao, value: user.posicao });
      setToggled(true);
    }
  }, [user?.posicao]);

  useEffect(() => {
    var query = qs.parse(search);
    if (query.TituloVaga) {
      setInputPosicao({ label: query.TituloVaga, value: query.TituloVaga });
      updateContext({
        posicao: query.TituloVaga,
      });
    }
  }, []);

  useEffect(() => {
    if (enviarPai) {
      enviarPai(inputPosicao);
    }
  }, [inputPosicao]);

  const onChangeHandler = async (val) => {
    if (val) {
      var result = [];
      for (let i = 0; i < lista.length; i++) {
        if (
          lista[i].label
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(
              val
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase()
            )
        ) {
          result.push(lista[i]);
        }
      }
      setPosicaoOptions(result.slice(0, 7));
    }
  };

  return (
    <Select
      isClearable={false}
      value={inputPosicao}
      autoFocus
      onInputChange={(inputValue, { prevInputValue, action }) => {
        switch (action) {
          case "input-change":
            onChangeHandler(inputValue);
            setInputPosicao({ label: inputValue, value: inputValue });
            return inputValue;
          default:
            return "";
        }
      }}
      styles={mobile ? buscarStyleMobile() : buscarStyle()}
      placeholder={"Diga um cargo ou palavra-chave"}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        ClearIndicator: () => null,
      }}
      hideSelectedOptions={true}
      noOptionsMessage={() => null}
      options={posicaoOptions}
      onChange={(inputValue, { prevInputValue, action }) => {
        setInputPosicao(inputValue);
      }}
    />
  );
};

export default BuscarVagaEspecialidade;
