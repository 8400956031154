import React from "react";
const TextArea = (props) => {
  return (
    <>
      <div
        className={`input-float
      ${props.error ? "validate-error" : ""}
      ${props.iconLeft || props.iconRight ? "input-icon" : ""}
      ${props.iconLeft ? "left" : ""}
      ${props.iconRight ? "right" : ""}
      ${props.value != "" ? "active" : ""}`}
      >
        <textarea
          placeholder={
            props.required ? props.placeholder + "*" : props.placeholder
          }
          type={props.type ? props.type : "text"}
          name={props.id}
          onChange={props.onChange}
          className={
            props.error && props.error !== ""
              ? `${props.className} validate-error`
              : props.className
          }
          value={props.value}
          required={props.required}
          maxLength={props.maxLength}
          rows={props.rows}
        />
        <label htmlFor={props.id}>
          {props.placeholder}
          {props.required ? <span className="color-primary">*</span> : ""}
        </label>
      </div>
      {props.error !== "" && (
        <span className="error__message">{props.error}</span>
      )}
    </>
  );
};

export default TextArea;
