import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../context/UserContext";
import SEO from "../../../../components/estrutura/SEO";
import SidePanel from "../../../../components/SidePanel";
import Modal from "../../../../components/Modal";
import InputTextArea from "../../../../components/InputTextArea";
import Dropdown from "react-bootstrap/Dropdown";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { ReactComponent as Observacao } from "../../../../img/icons/edit.svg";
import { ReactComponent as Curriculo } from "../../../../img/icons/notes.svg";
import { ReactComponent as ArrowUp } from "../../../../img/icons/angle-up.svg";
import { ReactComponent as ArrowDown } from "../../../../img/icons/angle-down.svg";
import api from "../../../../api";
import qs from "query-string";

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const [vagaSelecionada, setVagaSelecionada] = useState(null);
  const [candidatos, setCandidatos] = useState([]);
  const [curriculos, setCurriculos] = useState([]);
  const [modal, setModal] = useState(false);
  const [vendoCurriculo, setVendoCurriculo] = useState(false);
  const [curriculoCompleto, setCurriculoCompleto] = useState({});
  const [candidatoSelecionado, setCandidatoSelecionado] = useState({});

  useEffect(() => {
    if (user?.anuncios?.length > 0) {
      let promises = [];
      let anunciosComCandidatos = [];
      for (var i = 0; i < user.anuncios.length; i++) {
        promises.push(
          api
            .get(
              `/Curriculo/pesquisarAnunciante/?token=${user.tokenID}&anuncioID=${user.anuncios[i].id}`
            )
            .then((response) => {
              // do something with response
              anunciosComCandidatos.push(response.data.anuncio);
            })
        );
      }
      Promise.all(promises).then(() => setCandidatos(anunciosComCandidatos));
    }
  }, []);

  const atualizarCandidatura = (body) => {
    api
      .put(`/Candidaturas/alterar?token=${user.tokenID}`, {
        ...body,
        ativo: true,
      })
      .then((res) => {
        if (res.status === 200) {
          var newCandidatos = [...candidatos];
          newCandidatos
            .find(({ id }) => id === res.data.candidatura.anuncioID)
            .lstCurriculos.find(
              ({ id }) => id === res.data.candidatura.curriculoID
            ).lstCandidaturas[0].statusID = res.data.candidatura.statusID;
          setCandidatos(newCandidatos);
          updateContext({
            toast: {
              title: "Candidatura atualizada",
              status: "Success",
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
      });
  };

  const atualizarAnotacao = (candidaturaID, texto) => {
    var config;
    var data = new FormData();
    if (candidatoSelecionado.lstAnotacoes.length > 0) {
      config = {
        method: "put",
        url: `/Candidaturas/alterarAnotacao?token=${user.tokenID}`,
        data: {
          id: candidatoSelecionado.lstAnotacoes[0].id,
          candidaturaID,
          texto,
          dataAtualizacao: new Date().toISOString().replace("Z", ""),
        },
      };
    } else {
      data.append("token", user.tokenID);
      config = {
        method: "post",
        url: `/Candidaturas/registrarAnotacao?candidaturaID=${candidaturaID}&texto=${texto}`,
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      };
    }

    api(config)
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          updateContext({
            toast: {
              title: "Anotação atualizada",
              status: "Success",
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
      });
  };

  const verCurriculo = (id) => {
    let curr = curriculos.find(({ user_id }) => user_id === id);
    setCurriculoCompleto(curr);
    setVendoCurriculo(true);
  };

  const verAnotacoes = (obj) => {
    setCandidatoSelecionado(obj);
    setModal(true);
  };

  return (
    <>
      <SEO
        seo={{
          title: `Candidatos · Área da Empresa`,
          description: ``,
        }}
      />

      <div className="painel">
        <div className="painel__titulo">
          <div className="container">
            <h3>Seus candidatos</h3>
            <div className="lead">
              Acompanhe os candidatos para as suas vagas.
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        {candidatos.length > 0 ? (
          <>
            {candidatos.map((el, index) => {
              return el.lstCurriculos.length > 0 ? (
                <div key={el.id} className="mb-2">
                  <div
                    className={`titulo mb-0 c-pointer ${
                      vagaSelecionada === el.id ? "active" : ""
                    }`}
                    onClick={() =>
                      setVagaSelecionada(
                        vagaSelecionada === el.id ? null : el.id
                      )
                    }
                  >
                    <h5>
                      {el.tituloVaga}{" "}
                      <span>
                        ({el.lstCurriculos.length} candidato
                        {el.lstCurriculos.length > 1 ? "s" : ""})
                      </span>
                      {vagaSelecionada === el.id ? <ArrowUp /> : <ArrowDown />}
                    </h5>
                  </div>
                  <div
                    className={`barra-expandida ${
                      vagaSelecionada === el.id ? "active" : ""
                    }`}
                  >
                    <div className="row gx-2">
                      {el.lstCurriculos.map(
                        ({
                          id,
                          lstCandidaturas,
                          email,
                          ddd,
                          telefone,
                          nome,
                          sobrenome,
                        }) => {
                          var status = lstCandidaturas[0].statusID;
                          var curriculoID = lstCandidaturas[0].curriculoID;
                          var candidaturaID = lstCandidaturas[0].id;
                          var anuncio = lstCandidaturas[0].anuncioID;
                          var lstAnotacoes = lstCandidaturas[0].lstAnotacoes;
                          return (
                            <div
                              key={`${candidaturaID}`}
                              className="col-lg-3 col-12 mb-3"
                            >
                              <div className="vagaCard candidatos__box ">
                                <div
                                  className={` my-1 status status--${
                                    status === 1
                                      ? "default"
                                      : status === 2
                                      ? "progress"
                                      : status === 3
                                      ? "remove"
                                      : status === 4
                                      ? "success"
                                      : "default"
                                  }`}
                                >
                                  <span>
                                    {status === 1
                                      ? "Não iniciado"
                                      : status === 2
                                      ? "Em andamento"
                                      : status === 3
                                      ? "Recusado"
                                      : status === 4
                                      ? "Aprovado"
                                      : "Indefinido"}
                                  </span>
                                </div>
                                <b className="my-1">{`${nome} ${sobrenome}`}</b>
                                <div className="d-flex mt-1 mb-2 candidatos__box__links">
                                  <div onClick={() => verCurriculo(id)}>
                                    Currículo
                                  </div>
                                  <div
                                    onClick={() =>
                                      verAnotacoes({
                                        id: candidaturaID,
                                        anuncioID: anuncio,
                                        curriculoID,
                                        lstAnotacoes,
                                      })
                                    }
                                  >
                                    Anotações
                                  </div>
                                </div>
                                <div className="mb-2 text-center candidatos__box__contato">
                                  <div className="mb-2">
                                    {ddd && telefone ? (
                                      <a href={`tel:+55${ddd}${telefone}`}>
                                        ({ddd}) {telefone}
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                  <div>
                                    {email ? (
                                      <a href={`mailto:${email}`}>{email}</a>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                                {status === 1 ? (
                                  <div
                                    className="btn-vagas btn-vagas__primary medium w-100"
                                    onClick={() =>
                                      atualizarCandidatura({
                                        id: candidaturaID,
                                        anuncioID: anuncio,
                                        curriculoID,
                                        statusID: 2,
                                      })
                                    }
                                  >
                                    Iniciar análise
                                  </div>
                                ) : status === 2 ? (
                                  <div className="d-flex justify-content-end">
                                    <div
                                      className="btn-vagas btn-vagas__primary mx-1 w-50 medium"
                                      onClick={() =>
                                        atualizarCandidatura({
                                          id: candidaturaID,
                                          anuncioID: el.id,
                                          curriculoID,
                                          statusID: 4,
                                        })
                                      }
                                    >
                                      Aprovado
                                    </div>
                                    <div
                                      className="btn-vagas btn-vagas__error w-50 mx-1 medium"
                                      onClick={() =>
                                        atualizarCandidatura({
                                          id: candidaturaID,
                                          anuncioID: el.id,
                                          curriculoID,
                                          statusID: 3,
                                        })
                                      }
                                    >
                                      Recusado
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="btn-vagas btn-vagas__ghost__primary w-100 medium"
                                    onClick={() =>
                                      atualizarCandidatura({
                                        id: candidaturaID,
                                        anuncioID: el.id,
                                        curriculoID,
                                        statusID: 2,
                                      })
                                    }
                                  >
                                    Reabrir
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </>
        ) : (
          <div className="vagaCard">
            <div className="row">
              <div className="painel__vazio">
                <h5>Não encontramos candidatos</h5>
                <p>Divulgue suas vagas para encontrar candidatos</p>
                <Link
                  className="btn btn-vagas btn-vagas__primary small"
                  to="/anunciante/vagas"
                >
                  Ver vagas
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        state={"info"}
        title={"Adicione anotações sobre o candidato"}
        body={
          <InputTextArea
            id="desejaveis"
            placeholder="Faça anotações sobre o candidato, isto é privado."
            type="text"
            onChange={(event) => {
              setCandidatoSelecionado({
                ...candidatoSelecionado,
                texto: event.target.value,
              });
            }}
            rows="5"
            value={
              candidatoSelecionado?.lstAnotacoes?.length > 0 &&
              !candidatoSelecionado.texto
                ? candidatoSelecionado.lstAnotacoes[0].texto
                : candidatoSelecionado.texto
            }
            className="w-100 mt-2"
          />
        }
        show={modal}
        acao={() => {
          atualizarAnotacao(
            candidatoSelecionado.id,
            candidatoSelecionado.texto
          );
          setModal(false);
        }}
        btnAcao={"Salvar anotação"}
        hide={() => {
          setModal(false);
        }}
      />
      {vendoCurriculo && (
        <SidePanel
          align={"top"}
          state={"info"}
          title={"Confira o currículo do candidato"}
          body={<></>}
          show={vendoCurriculo}
          acao={() => {
            setVendoCurriculo(false);
          }}
          btnAcao={"Fechar"}
          hide={() => {
            setVendoCurriculo(false);
          }}
        />
      )}
    </>
  );
};

export default IndexPage;
