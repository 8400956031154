import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Logo } from "../../../img/logo.svg";
import { makeUrlFriendly } from "../../../utils/makeUrlFriendly";

const Footer = (props) => {
  return (
    <>
      <footer className="footer">
        <div className="container"></div>
      </footer>
    </>
  );
};

export default Footer;
