import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import SEO from "../../../../components/estrutura/SEO";
import Loading from "../../../../components/Loading";
import { makeUrlFriendly } from "../../../../utils/makeUrlFriendly";
import { cidadeNomePorID } from "../../../../utils/encontrarCidade";
import { estadoNomePorID } from "../../../../utils/encontrarEstado";
import moment from "moment";
import "moment/locale/pt-br";
import { UserContext } from "../../../../context/UserContext";
import api from "../../../../api";
import Dropdown from "react-bootstrap/Dropdown";
import { ReactComponent as Vagas } from "../../../../img/icons/briefcase-alt.svg";
import { ReactComponent as Opcoes } from "../../../../img/icons/ellipsis-h.svg";

const IndexPage = (props) => {
  const { user, updateContext } = useContext(UserContext);
  const [candidaturas, setCandidaturas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activatedTable, setActivatedTable] = useState(null);

  useEffect(() => {
    console.log(user);
    if (user?.candidaturas?.length > 0) {
      api
        .get(
          `/Vagas/pesquisar/?${user?.candidaturas
            .map(({ anuncioID }) => `anunciosID=${anuncioID}&`)
            .join("")}`
        )
        .then((res) => {
          if (res.status === 200) {
            setCandidaturas(res.data.lstAnuncios);
            setLoading(false);
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log(err.response);
            /*  updateContext({
              toast: {
                title: err.response.data.mensagens[0].txt,
                status: "Error",
              },
            }); */
          }
          setLoading(false);
        });
    }
    setLoading(false);
  }, []);

  const removerCandidatura = (id, anuncioID) => {
    var data = new FormData();
    data.append("token", user.tokenID);
    api({
      method: "delete",
      url: `/Candidaturas/desativar/?candidaturaID=${id}`,
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.status === 200) {
          const newCandidaturas = [...candidaturas].filter(
            (item) => item.id !== anuncioID
          );
          setCandidaturas(newCandidaturas);
          updateContext({
            toast: {
              title: "Candidatura removida com sucesso!",
              status: "Success",
            },
            candidaturas: newCandidaturas,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          updateContext({
            toast: {
              title: err.response.data,
              status: "Error",
            },
          });
        }
      });
  };

  return (
    <>
      <SEO
        seo={{
          title: `Candidaturas · Área do Candidato`,
          description: ``,
        }}
      />
      <div className="painel">
        <div className="painel__titulo">
          <div className="container">
            <h3>Suas candidaturas</h3>
            <div className="lead">
              Acompanhe as vagas na qual você se candidatou.
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-3">
        {loading ? (
          <Loading />
        ) : (
          <div className="vagaCard">
            {candidaturas.length > 0 ? (
              <>
                <table className="vagas">
                  <thead>
                    <tr>
                      <th scope="col" id="titulo">
                        Título da Vaga
                      </th>
                      <th scope="col" id="local">
                        Local
                      </th>
                      <th scope="col" id="data">
                        Data
                      </th>
                      <th scope="col" id="status">
                        Status
                      </th>
                      <th scope="col" id="editar">
                        Opções
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {candidaturas.map(
                      (
                        {
                          id,
                          contratante,
                          tituloVaga,
                          bairro,
                          cidadeID,
                          estadoID,
                          endereco,
                          numero,
                          ativo,
                        },
                        index
                      ) => {
                        let status = user?.candidaturas.find(
                          ({ anuncioID }) => anuncioID === id
                        )?.statusID;

                        return (
                          <React.Fragment key={id}>
                            <tr
                              onClick={() =>
                                setActivatedTable(
                                  activatedTable === id ? null : id
                                )
                              }
                              className={`${
                                activatedTable === id ? "active" : ""
                              }`}
                            >
                              <td headers="titulo">
                                <b>{tituloVaga}</b>
                                <span>{contratante}</span>
                              </td>
                              <td headers="local">
                                <p>{bairro}</p>
                                <span>
                                  {endereco}, {numero}
                                </span>
                              </td>
                              <td headers="data">
                                {moment(
                                  user.candidaturas.find(
                                    ({ anuncioID }) => anuncioID === id
                                  ).dataRegistro
                                ).format("DD/MM/YYYY")}
                              </td>
                              <td headers="status">
                                {!ativo ? (
                                  <div className="status status--moved">
                                    <span>encerrada</span>
                                  </div>
                                ) : (
                                  <div
                                    className={`status status--${
                                      status === 1
                                        ? "default"
                                        : status === 2
                                        ? "progress"
                                        : status === 3
                                        ? "remove"
                                        : status === 4
                                        ? "success"
                                        : "default"
                                    }`}
                                  >
                                    <span>
                                      {status === 1
                                        ? "Não iniciado"
                                        : status === 2
                                        ? "Em andamento"
                                        : status === 3
                                        ? "Recusado"
                                        : status === 4
                                        ? "Aprovado"
                                        : "Indefinido"}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td headers="editar">
                                <Dropdown
                                  align={"end"}
                                  className="menu__account__profile__title"
                                >
                                  <Dropdown.Toggle size="sm">
                                    <Opcoes />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {ativo && (
                                      <>
                                        <Dropdown.Item as="button">
                                          <Link
                                            target="_blank"
                                            to={`/oportunidade/${id}/${makeUrlFriendly(
                                              tituloVaga +
                                                "-" +
                                                cidadeNomePorID(cidadeID) +
                                                "-" +
                                                estadoNomePorID(estadoID)
                                            )}`}
                                          >
                                            Ver vaga
                                          </Link>
                                        </Dropdown.Item>

                                        <Dropdown.Divider />
                                      </>
                                    )}
                                    <Dropdown.Item as="button">
                                      <div
                                        className="item"
                                        onClick={() =>
                                          removerCandidatura(
                                            user.candidaturas.find(
                                              ({ anuncioID }) =>
                                                anuncioID === id
                                            ).id,
                                            id
                                          )
                                        }
                                      >
                                        Retirar candidatura
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </>
            ) : (
              <div className="row">
                <div className="painel__vazio">
                  <h5>Não encontramos candidaturas</h5>
                  <p>Você já se candidatou a uma vaga?</p>
                  <Link
                    className="btn btn-vagas btn-vagas__primary small"
                    to="/vagas/"
                  >
                    Achar oportunidades
                  </Link>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default IndexPage;
