import React, { useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
/* import CustomRoute from "../components/Autenticado"; */
import PrecisaAutenticacao from "../components/VerificarUser/PrecisaAutenticacao";
import SomenteEmpresa from "../components/VerificarUser/SomenteEmpresa";
import SomenteCandidato from "../components/VerificarUser/SomenteCandidato";
import RedirecionarLogado from "../components/VerificarUser/RedirecionarLogado";

import Home from "./Principal/Home";
import ListaVagas from "./Principal/ListaVagas";
import Vaga from "./Principal/Vaga";
import Login from "./Principal/Login";
import Registrar from "./Principal/Registrar";

import CriarCurriculo from "./Principal/CriarCurriculo";
import AnunciarVaga from "./Principal/AnunciarVaga";

import DashboardLayout from "../components/estrutura/dashboard/Layout";

import EmpresaPainel from "./Dashboard/Empresa/Painel";
import EmpresaPerfil from "./Dashboard/Empresa/Perfil";
import EmpresaVagas from "./Dashboard/Empresa/Vagas";
import EmpresaCandidatos from "./Dashboard/Empresa/Candidatos";

import CandidatoPainel from "./Dashboard/Candidato/Painel";
import CandidatoPerfil from "./Dashboard/Candidato/Perfil";
import CandidatoCurriculo from "./Dashboard/Candidato/Curriculo";
import CandidatoCandidaturas from "./Dashboard/Candidato/Candidaturas";

const Paths = (props) => {
  let location = useLocation();
  return (
    <Routes basename="/" location={location}>
      <Route exact path="/" element={<Home />} />

      <Route path="/vagas/">
        <Route path=":local" element={<ListaVagas />} />
        <Route path="" element={<ListaVagas />} />
      </Route>

      <Route exact path="/oportunidade/:id/:nome" element={<Vaga />} />
      <Route exact path="/perfil/:id/:nome" element={<Vaga />} />

      <Route element={<RedirecionarLogado />}>
        <Route exact path="/entrar" element={<Login />} />
        <Route exact path="/cadastrar" element={<Registrar />} />
      </Route>

      <Route element={<PrecisaAutenticacao />}>
        <Route element={<SomenteEmpresa />}>
          <Route exact path="/anunciar-vaga" element={<AnunciarVaga />} />
          <Route path="anunciante" element={<DashboardLayout />}>
            <Route index element={<EmpresaPainel />} />
            <Route path={"perfil"} element={<EmpresaPerfil />} />
            <Route path={"vagas"} element={<EmpresaVagas />} />
            <Route path={"candidatos"} element={<EmpresaCandidatos />} />
          </Route>
        </Route>
        <Route element={<SomenteCandidato />}>
          <Route exact path="/criar-curriculo" element={<CriarCurriculo />} />
          <Route path="candidato" element={<DashboardLayout />}>
            <Route index element={<CandidatoPainel />} />
            <Route path={"perfil"} element={<CandidatoPerfil />} />
            <Route path={"curriculo"} element={<CandidatoCurriculo />} />
            <Route path={"candidaturas"} element={<CandidatoCandidaturas />} />
          </Route>
        </Route>
      </Route>
      <Route path={"*"} element={<Navigate to={"/"} />} />
    </Routes>
  );
};

export default Paths;
