export const selectStyle = () => {
  return {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#1c212d"
          : isFocused
          ? "#f8faff"
          : undefined,
        color: isSelected ? "white" : "#646879",
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#01c4cb"
              : "rgba(25,196,203,.25)"
            : undefined,
        },
      };
    },
  };
};
