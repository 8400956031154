import React, { useState, useContext, useEffect } from "react";
import InputText from "../../../../components/InputText";
import InputCheck from "../../../../components/InputCheck";
import InputSelect from "../../../../components/InputSelect";
import { validarState } from "../../../../utils/validarForm";
import cidadesBrasileiras from "../../../../data/brazilCitiesDataPopResult.json";
import {
  cidadeIDPorNome,
  cidadeNomePorID,
} from "../../../../utils/encontrarCidade";
import InputMask from "react-input-mask";
import {
  estadoIDPorUF,
  estadoNomePorUF,
  estadoNomePorID,
} from "../../../../utils/encontrarEstado";
import { ReactComponent as ArrowRight } from "../../../../img/icons/arrow-right.svg";
import axios from "axios";

const estados = [
  ...new Set(cidadesBrasileiras.map(({ state_name }) => state_name)),
].map((item, index) => ({ value: index + 1, label: item }));

const cidades = (estado) => {
  return cidadesBrasileiras
    .filter(({ state_name }) => state_name == estado)
    .map(({ id, name }) => ({ value: id, label: name }));
};

const Passo1 = ({
  contratante,
  tituloVaga,
  cep,
  endereco,
  numero,
  bairro,
  estadoID,
  cidadeID,
  telemedicina,
  ddd,
  telefone,
  proximoPasso,
  enviarPai,
}) => {
  const [anuncioBasico, setAnuncioBasico] = useState({
    contratante: contratante || "",
    tituloVaga: tituloVaga || "",
    ddd: ddd || "",
    telefone: telefone || "",
    cep: cep || "",
    bairro: bairro || "",
    numero: numero || "",
    endereco: endereco || "",
    estadoID: estadoID || "",
    cidadeID: cidadeID || "",
    telemedicina: telemedicina || false,
  });
  const [formErrors, setFormError] = useState({});

  const handleUserInput = (id, value, nested) => {
    setAnuncioBasico({
      ...anuncioBasico,
      [id]: value,
    });
  };

  const handleCEP = (value) => {
    var val = value;
    if (val.includes("-")) {
      var val = val.replace("-", "");
    }
    if (val === "" || /^[0-9\b]+$/.test(val)) {
      setAnuncioBasico({
        ...anuncioBasico,
        cep: val,
      });
    }
    if (val.length > 8) return;
  };

  useEffect(() => {
    if (anuncioBasico.cep.length === 8) {
      axios
        .get(`//viacep.com.br/ws/${anuncioBasico.cep}/json/`)
        .then(({ data }) => {
          if (!data.erro) {
            setAnuncioBasico({
              ...anuncioBasico,
              endereco: data.logradouro,
              bairro: data.bairro,
              estadoID: {
                value: estadoIDPorUF(data.uf),
                label: estadoNomePorUF(data.uf),
              },
              cidadeID: {
                value: cidadeIDPorNome(data.localidade),
                label: data.localidade,
              },
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [anuncioBasico.cep]);

  const validarCampos = () => {
    var campos = Object.keys(anuncioBasico);
    if (anuncioBasico.telemedicina) {
      campos = campos.filter(
        (item) =>
          item !== `cep` &&
          item !== `bairro` &&
          item !== `numero` &&
          item !== `endereco` &&
          item !== "estadoID" &&
          item !== "cidadeID"
      );
    }
    var camposValidar = Object.keys(anuncioBasico)
      .filter((key) => campos.includes(key))
      .reduce((obj, key) => {
        obj[key] = anuncioBasico[key];
        return obj;
      }, {});
    var obj = validarState(JSON.parse(JSON.stringify(camposValidar)));
    if (!obj.status) {
      setFormError(obj.state);
    } else {
      //mandar para servidor para validar?
      proximoPasso(anuncioBasico);
    }
  };

  useEffect(() => {
    if (enviarPai) {
      enviarPai(anuncioBasico);
    }
  }, [anuncioBasico]);

  return (
    <>
      <h6 className="mb-0 font-600 color-secondary">Dados da Vaga</h6>
      <div className="row gx-3 justify-content-center">
        <div className="col-12">
          <InputText
            id="contratante"
            placeholder="Nome da Empresa"
            type="text"
            onChange={(event) =>
              setAnuncioBasico({
                ...anuncioBasico,
                contratante: event.target.value,
              })
            }
            value={anuncioBasico.contratante}
            error={formErrors.contratante}
            className="w-100 mt-2"
            required
          />
        </div>

        <div className="col-12">
          <InputText
            id="tituloVaga"
            placeholder="Título da Vaga"
            type="text"
            onChange={(event) =>
              setAnuncioBasico({
                ...anuncioBasico,
                tituloVaga: event.target.value,
              })
            }
            value={anuncioBasico.tituloVaga}
            error={formErrors.tituloVaga}
            className="w-100 mt-2"
            required
          />
        </div>
        <div className="col-md-4">
          <InputMask
            mask="99"
            maskPlaceholder={null}
            onChange={(event) =>
              setAnuncioBasico({
                ...anuncioBasico,
                ddd: event.target.value,
              })
            }
            value={anuncioBasico.ddd}
          >
            <InputText
              id="ddd"
              placeholder="(DDD)"
              type="text"
              className="w-100 mt-2"
              error={formErrors.ddd}
              required
            />
          </InputMask>
        </div>
        <div className="col-md-8">
          <InputMask
            mask="999999999"
            maskPlaceholder={null}
            onChange={(event) =>
              setAnuncioBasico({
                ...anuncioBasico,
                telefone: event.target.value,
              })
            }
            value={anuncioBasico.telefone}
          >
            <InputText
              id="telefone"
              placeholder="Telefone"
              type="text"
              className="w-100 mt-2"
              error={formErrors.telefone}
              required
            />
          </InputMask>
        </div>
      </div>
      <hr />
      <h6 className="mb-0 font-600 color-secondary">Localização da Vaga</h6>
      <div className="row gx-3">
        <div className="col-12">
          <InputMask
            mask="99999-999"
            maskPlaceholder={null}
            onChange={(event) => handleCEP(event.target.value)}
            value={anuncioBasico.cep}
          >
            <InputText
              id="cep"
              placeholder="CEP"
              autocomplete="off"
              type="text"
              className="w-100 mt-2"
              error={formErrors.cep}
              required
            />
          </InputMask>
        </div>
        <div className="col-md-6">
          <InputText
            id="endereco"
            placeholder="Endereço"
            type="text"
            onChange={(event) =>
              handleUserInput("endereco", event.target.value)
            }
            value={anuncioBasico.endereco}
            disabled={
              anuncioBasico.endereco === "" || anuncioBasico.cep.length < 8
                ? true
                : false
            }
            error={formErrors.endereco}
            className="w-100 mt-2"
            required
          />
        </div>
        <div className="col-md-2">
          <InputText
            id="numero"
            placeholder="Número"
            type="text"
            onChange={(event) => handleUserInput("numero", event.target.value)}
            disabled={anuncioBasico.endereco === "" ? true : false}
            value={anuncioBasico.numero}
            error={formErrors.numero}
            className="w-100 mt-2"
            required
          />
        </div>
        <div className="col-md-4">
          <InputText
            id="bairro"
            placeholder="Bairro"
            type="text"
            onChange={(event) => handleUserInput("bairro", event.target.value)}
            disabled={
              anuncioBasico.bairro === "" || anuncioBasico.cep.length < 8
                ? true
                : false
            }
            value={anuncioBasico.bairro}
            error={formErrors.bairro}
            className="w-100 mt-2"
            required
          />
        </div>
        <div className="col-md-6">
          <InputSelect
            id="estadoID"
            placeholder="Estado"
            onChange={(event) => {
              handleUserInput("estadoID", event.label);
            }}
            value={anuncioBasico.estadoID}
            isDisabled={
              anuncioBasico.estadoID === "" || anuncioBasico.cep.length < 8
                ? true
                : false
            }
            error={formErrors.estadoID}
            options={estados}
            className="w-100 mt-2"
            required
          />
        </div>
        <div className="col-md-6">
          <InputSelect
            id="cidadeID"
            placeholder="E qual cidade?"
            options={
              anuncioBasico.estadoID &&
              cidades(
                estados.find(({ value }) =>
                  value === estadoID ? estadoID : anuncioBasico.estadoID.value
                ).label
              )
            }
            onChange={(event) => {
              handleUserInput("cidadeID", event.value);
            }}
            value={anuncioBasico.cidadeID}
            required
            isDisabled={
              anuncioBasico.estadoID === "" || anuncioBasico.cep.length < 8
                ? true
                : false
            }
            error={formErrors.cidadeID}
          />
        </div>

        <div className="col-12">
          <InputCheck
            id="telemedicina"
            placeholder="Essa vaga é oferecida para telemedicina"
            defaultChecked={anuncioBasico.telemedicina}
            checked={anuncioBasico.telemedicina}
            onChange={(event) => {
              setAnuncioBasico({
                ...anuncioBasico,
                telemedicina: !anuncioBasico.telemedicina,
              });
            }}
          />
        </div>
      </div>
      <hr className="my-4" />
      {proximoPasso ? (
        <div className="row">
          <div className="col-12">
            <div className="flow__buttons">
              <button
                onClick={validarCampos}
                className="btn-vagas btn-vagas__primary"
              >
                Próximo passo <ArrowRight className="ms-1" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Passo1;
